import React, { useEffect, useMemo, useState } from 'react';
import {
  AddressField,
  AddressFieldType,
  Country,
  getAddressRequirementsForCountry,
  getCountriesForRegion,
  getProvincesForCountry,
  Province,
  Region,
  validateAddressInput,
} from '@whoop/i18n';
import { Button } from '../../Button';
import {
  ButtonSizes,
  ButtonThemes,
  ButtonVariants,
} from '../../../types/Buttons';
import { Form } from '../../Form';
import { importTranslations, useTranslation } from '../../../utils/i18n';
import styles from './shippingDetailsForm.module.scss';
import { FormInputType } from '../../../types/FormInputType';
import { Input } from '../../Input';
import { Col, Row } from '../../layout';
import { ShippingInfo } from '../../../types/ShippingInfo';
import { classes } from '../../../utils';
import { DropdownSelect } from '../DropdownSelect/DropdownSelect';
import { BasicComponentProps } from '../../../types';

// todo: abstract logic from component into helper file & write functional tests

importTranslations(
  'shipping-details-form',
  require.context('./languages/', true),
);

export interface ShippingDetailsFormProps extends BasicComponentProps {
  onSubmit: (address: ShippingInfo | undefined) => void;
  address?: ShippingInfo;
  region?: Region;
  formError?: string;
}

export function ShippingDetailsForm({
  onSubmit,
  address,
  region,
  formError,
  className,
  ...props
}: ShippingDetailsFormProps) {
  const { t } = useTranslation('shipping-details-form');

  const [countries, setCountries] = useState<Country[]>([]);
  const [provinces, setProvinces] = useState<Province[]>([]);

  const [firstName, _setFirstName] = useState<string | undefined>(
    address?.first_name,
  );
  const [firstNameError, setFirstNameError] = useState<string | undefined>();

  const [lastName, _setLastName] = useState<string | undefined>(
    address?.last_name,
  );
  const [lastNameError, setLastNameError] = useState<string>();

  const [line1, _setLine1] = useState<string | undefined>(address?.line1);
  const [line1Error, setLine1Error] = useState<string>();

  const [line2, _setLine2] = useState<string | undefined>(address?.line2);
  const [line2Error, setLine2Error] = useState<string>();

  const [country, _setCountry] = useState<string | undefined>(address?.country);

  const [city, _setCity] = useState<string | undefined>(address?.city);
  const [cityError, setCityError] = useState<string>();

  const [province, _setProvince] = useState<string | undefined>(
    address?.province,
  );
  const [provinceError, setProvinceError] = useState<string>();

  const [postalCode, _setPostalCode] = useState<string | undefined>(
    address?.postal_code,
  );
  const [postalCodeError, setPostalCodeError] = useState<string>();

  const [phone, _setPhone] = useState<string | undefined>(address?.phone);
  const [phoneError, setPhoneError] = useState<string>();

  const [shippingInfo, setShippingInfo] = useState<ShippingInfo | undefined>(
    address,
  );

  const [addressRequirements, setAddressRequirements] =
    useState<AddressField[]>();

  const showPostalCode = region !== 'AE';

  useEffect(() => {
    if (region) {
      const _countries = getCountriesForRegion(region);
      setCountries(_countries);

      const defaultCountry = _countries[0].alpha2;
      // Only default select a country if there is only one option, or if in the UK region
      // For other regions with multiple countries, don't automatically select a country, but use the first country in the list to initialize the form's fields
      if (_countries.length === 1 || region === 'UK') {
        _setCountry(defaultCountry);
      } else {
        const addressRequirements =
          getAddressRequirementsForCountry(defaultCountry);
        setAddressRequirements(addressRequirements);
      }
    }
  }, [region]);

  const setProvince = (text: string) => {
    setProvinceError(undefined);
    _setProvince(text);
  };

  useEffect(() => {
    if (country) {
      const _provinces = getProvincesForCountry(country);
      setProvinces(_provinces);

      if (_provinces.length === 1) {
        setProvince(_provinces[0].code);
      }

      const addressRequirements = getAddressRequirementsForCountry(country);
      setAddressRequirements(addressRequirements);
    }
  }, [country]);

  const line1Label = useMemo(
    () => t(region === 'AE' ? 'line1AE' : 'line1'),
    [region],
  );

  const line2Label = useMemo(
    () => t(region === 'AE' ? 'line2AE' : 'line2'),
    [region],
  );

  const cityLabel = useMemo(
    () => t(region === 'AE' ? 'emirateCity' : 'city'),
    [region],
  );

  const provinceLabel = useMemo(
    () =>
      t(
        region === 'AE'
          ? 'areaDistrict'
          : ['EU', 'UK'].includes(region || '')
          ? 'stateProvinceRegion'
          : ['CA', 'NZ'].includes(region || '')
          ? 'province'
          : 'state',
      ),
    [region],
  );

  const handleProvinceSelection = (code: string) => {
    setProvince(code);
  };

  const setCountry = (text: string) => {
    _setCountry(text);
  };

  const handleCountrySelection = (alpha2: string) => {
    setCountry(alpha2);
  };

  const postalCodeLabel = useMemo(
    () =>
      t(['AE', 'EU', 'UK'].includes(region || '') ? 'postalCode' : 'zipCode'),
    [region],
  );

  const hasFieldLevelError =
    !!firstNameError ||
    !!lastNameError ||
    !!line1Error ||
    !!line2Error ||
    !!cityError ||
    !!provinceError ||
    !!postalCodeError ||
    !!phoneError;

  const isSubmitDisabled =
    !firstName ||
    !lastName ||
    !line1 ||
    !country ||
    !city ||
    !province ||
    (showPostalCode && !postalCode) ||
    !phone ||
    hasFieldLevelError;

  const validateField = (
    value: string | undefined,
    fieldType: AddressFieldType,
    label: string,
    errorSetter: React.Dispatch<React.SetStateAction<string | undefined>>,
  ) => {
    const field = addressRequirements?.find((req) => req.type === fieldType);
    if (!field) return;
    const { isValid, errorType } = validateAddressInput(field, value);
    if (isValid) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      errorSetter(undefined);
    } else {
      let error;
      if (errorType === 'pattern' && fieldType === 'phone') {
        error = 'invalidPhoneAndCountryCodeError';
      } else {
        error = errorType === 'maxLength' ? 'lengthError' : 'requiredError';
      }
      errorSetter(
        t(error, {
          field: label,
          maximum: field.maxLength,
          interpolation: { escapeValue: false },
        }),
      );
    }
  };

  const getDisplayCountries = (countries: Country[]) => {
    return countries.map((country) => {
      return { label: country.name, value: country.alpha2 };
    });
  };

  const getDisplayProvinces = (provinces: Province[]) => {
    return provinces.map((province) => {
      return { label: province.name, value: province.code };
    });
  };

  const setFirstName = (text: string) => {
    setFirstNameError(undefined);
    _setFirstName(text);
  };

  const setLastName = (text: string) => {
    setLastNameError(undefined);
    _setLastName(text);
  };

  const setLine1 = (text: string) => {
    setLine1Error(undefined);
    _setLine1(text);
  };

  const setLine2 = (text: string) => {
    setLine2Error(undefined);
    _setLine2(text);
  };

  const setCity = (text: string) => {
    setCityError(undefined);
    _setCity(text);
  };

  const setPostalCode = (text: string) => {
    setPostalCodeError(undefined);
    _setPostalCode(text);
  };

  const setPhone = (text: string) => {
    setPhoneError(undefined);
    _setPhone(text);
  };

  const handleOnClick = () => {
    const address = {
      first_name: firstName,
      last_name: lastName,
      postal_code: postalCode,
      province: province,
      country: country,
      city: city,
      phone: phone,
      line1: line1,
      line2: line2,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setShippingInfo(address);
  };

  const preventScrollOnNumInput = (e: Event) => {
    // blur instead of scrolling
    (e.target as HTMLElement).blur();
    // set focus again immediately to allow user to scroll on the page itself
    setTimeout(() => {
      (e.target as HTMLElement).focus();
    }, 0);
  };

  const showProvinceDropdown = useMemo(() => {
    return provinces.length > 0;
  }, [provinces]);

  const row1Errors = [firstNameError, lastNameError];
  const showRow1Errors = row1Errors.some((error) => !!error);

  const row2Errors = [cityError, postalCodeError];
  const showRow2Errors = row2Errors.some((error) => !!error);

  return (
    <Form
      onSubmit={() => onSubmit(shippingInfo)}
      {...props}
      className={className}
    >
      <div className={!showRow1Errors ? styles.sharedRow : ''}>
        <Row>
          <Col xs={6} className={styles.column}>
            <Input
              type={FormInputType.TEXT}
              name='first_name'
              placeholder={t('firstName')}
              required={true}
              value={firstName || ''}
              onChange={setFirstName}
              error={!!firstNameError}
              onBlur={() =>
                validateField(
                  firstName,
                  'first_name',
                  t('firstName'),
                  setFirstNameError,
                )
              }
              className={styles.input}
              autoComplete='given-name'
            />
          </Col>
          <Col xs={6} className={styles.column}>
            <Input
              type={FormInputType.TEXT}
              name='last_name'
              placeholder={t('lastName')}
              required={true}
              value={lastName || ''}
              onChange={setLastName}
              error={!!lastNameError}
              onBlur={() =>
                validateField(
                  lastName,
                  'last_name',
                  t('lastName'),
                  setLastNameError,
                )
              }
              className={styles.input}
              autoComplete='family-name'
            />
          </Col>
        </Row>
        {showRow1Errors && (
          <div className={styles.rowError}>
            {row1Errors.map((error) => {
              return (
                error && <span className={styles.rowErrorLine}> {error} </span>
              );
            })}
          </div>
        )}
      </div>
      <Input
        type={FormInputType.TEXT}
        name='line1'
        placeholder={line1Label}
        required={true}
        value={line1 || ''}
        onChange={setLine1}
        subText={line1Error}
        error={!!line1Error}
        onBlur={() => validateField(line1, 'line1', line1Label, setLine1Error)}
        className={styles.input}
        autoComplete='address-line1'
      />
      <Input
        type={FormInputType.TEXT}
        name='line2'
        placeholder={line2Label}
        required={false}
        value={line2 || ''}
        onChange={setLine2}
        subText={line2Error}
        error={!!line2Error}
        onBlur={() => validateField(line2, 'line2', line2Label, setLine2Error)}
        className={styles.input}
        autoComplete='address-line2'
      />
      <DropdownSelect
        options={getDisplayCountries(countries)}
        onOptionSelected={(option: string) => handleCountrySelection(option)}
        selectedOption={country}
        label={countries.length > 1 ? t('country') : undefined}
        className={styles.dropdown}
        round={false}
        autoComplete={'country-name'}
      />
      <div
        className={
          !showRow2Errors && showProvinceDropdown ? styles.sharedRow : ''
        }
      >
        <Row>
          <Col
            xs={showProvinceDropdown ? 4 : 12}
            className={classes(
              styles.column,
              !showProvinceDropdown ? styles.row : '',
            )}
          >
            <Input
              type={FormInputType.TEXT}
              name='city'
              placeholder={cityLabel}
              required={true}
              value={city || ''}
              onChange={setCity}
              subText={!showProvinceDropdown ? cityError : undefined}
              error={!!cityError}
              onBlur={() =>
                validateField(city, 'city', cityLabel, setCityError)
              }
              className={styles.input}
              autoComplete='address-level2'
            />
          </Col>
          <Col
            xs={showProvinceDropdown ? 4 : 12}
            className={classes(
              styles.column,
              !showProvinceDropdown ? styles.row : '',
            )}
          >
            {showProvinceDropdown ? (
              <DropdownSelect
                data-testid='province-dropdown'
                options={getDisplayProvinces(provinces)}
                onOptionSelected={(option: string) =>
                  handleProvinceSelection(option)
                }
                selectedOption={province}
                label={provinces.length > 1 ? provinceLabel : undefined}
                className={styles.dropdown}
                round={false}
                autoComplete={'address-level1'}
              />
            ) : (
              <Input
                data-testid='province-input'
                type={FormInputType.TEXT}
                name='province'
                placeholder={provinceLabel}
                required={true}
                value={province || ''}
                onChange={setProvince}
                subText={!showProvinceDropdown && provinceError}
                error={!!provinceError}
                onBlur={() =>
                  validateField(
                    province,
                    'province',
                    provinceLabel,
                    setProvinceError,
                  )
                }
                className={styles.input}
                autoComplete='address-level1'
              />
            )}
          </Col>
          {showPostalCode && (
            <Col
              xs={showProvinceDropdown ? 4 : 12}
              className={classes(
                styles.column,
                !showProvinceDropdown ? styles.row : '',
              )}
            >
              <Input
                data-testid='postal-code-input'
                type={
                  region === 'US' ? FormInputType.NUMERIC : FormInputType.TEXT
                }
                name='postal_code'
                placeholder={postalCodeLabel}
                required={true}
                value={postalCode || ''}
                onChange={setPostalCode}
                subText={!showProvinceDropdown ? postalCodeError : undefined}
                error={!!postalCodeError}
                onBlur={() =>
                  validateField(
                    postalCode,
                    'postal_code',
                    postalCodeLabel,
                    setPostalCodeError,
                  )
                }
                className={styles.input}
                autoComplete='postal-code'
                onWheel={preventScrollOnNumInput}
              />
            </Col>
          )}
        </Row>
        {showProvinceDropdown && showRow2Errors && (
          <div className={styles.rowError}>
            {[cityError, postalCodeError].map((error) => {
              return (
                error && <span className={styles.rowErrorLine}> {error} </span>
              );
            })}
          </div>
        )}
      </div>
      <Input
        type={FormInputType.TEL}
        name='phone'
        placeholder={t('phone')}
        required={true}
        value={phone || ''}
        onChange={setPhone}
        subText={phoneError}
        error={!!phoneError}
        onBlur={() => validateField(phone, 'phone', t('phone'), setPhoneError)}
        className={styles.input}
      />
      {!hasFieldLevelError && formError && (
        <span
          className={styles.formError}
          data-testid='shipping-details-form-error'
        >
          {formError}
        </span>
      )}
      <Button
        className={styles.nextButton}
        type='submit'
        label={t('nextButton')}
        variant={ButtonVariants.PRIMARY}
        size={ButtonSizes.LARGE}
        theme={ButtonThemes.JOIN_FLOW}
        disabled={isSubmitDisabled}
        onClick={handleOnClick}
      />
    </Form>
  );
}
