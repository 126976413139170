/*
  How do engraving previews work.
  Previews will have an svg overlaid over the static image.
  The svg will need to be transformed using `matrix(...)`
  in order to fit the engraving within the template image.

  The function's below provide utilities for taking these transformations
  and embedding them into the image names so each image can encode it's
  transformations in it's file name.
*/

import {
  ENGRAVING_TYPES,
  EngravingField,
  EngravingType,
} from './engravingUtils';

export type EngravingTransformParam =
  | 'scaleX'
  | 'skewX'
  | 'skewY'
  | 'scaleY'
  | 'translateX'
  | 'translateY';
export const ENGRAVING_TRANSFORM_PARAMS: EngravingTransformParam[] = [
  'scaleX',
  'scaleY',
  'skewX',
  'skewY',
  'translateX',
  'translateY',
];
export type EngravingPreviewTransform = {
  scaleX: number;
  scaleY: number;
  skewX: number;
  skewY: number;
  translateX: number;
  translateY: number;
};

export function defaultTransform(): EngravingPreviewTransform {
  return {
    scaleX: 1,
    scaleY: 1,
    skewX: 0,
    skewY: 0,
    translateX: 0,
    translateY: 0,
  };
}

export function transformToImagePrefix(transform: EngravingPreviewTransform) {
  return `engraving_preview_${transform.scaleX}_${transform.skewX}_${transform.skewY}_${transform.scaleY}_${transform.translateX}_${transform.translateY}`;
}

export function transformToCss(transform: EngravingPreviewTransform) {
  return `matrix(${transform.scaleX}, ${transform.skewX}, ${transform.skewY}, ${
    transform.scaleY
  }, 0, 0) translate(${transform.translateX * 100}%, ${
    transform.translateY * 100
  }%)`;
}

const TRANSFORM_REGEX =
  /engraving_preview_([-0-9.]+)_([-0-9.]+)_([-0-9.]+)_([-0-9.]+)_([-0-9.]+)_([-0-9.]+)/;
export function matchEngravingPreviewImage(url: string) {
  return TRANSFORM_REGEX.exec(url);
}

export function engravingImageToTransform(
  url: string,
): EngravingPreviewTransform | undefined {
  const match = matchEngravingPreviewImage(url);
  if (!match) {
    return undefined;
  }
  return {
    scaleX: parseFloat(match[1]),
    skewX: parseFloat(match[2]),
    skewY: parseFloat(match[3]),
    scaleY: parseFloat(match[4]),
    translateX: parseFloat(match[5]),
    translateY: parseFloat(match[6]),
  };
}

export function isEngravingImageForType(
  url: string,
  engravingType: EngravingType,
) {
  if (ENGRAVING_TYPES.some((type) => url.includes(type))) {
    return url.includes(engravingType);
  }
  return true;
}

const PREVIEW_HEIGHT = {
  gen4_strap_short: 6,
  gen4_strap_long: 5,
  gen4_id_long: 5,
};
export const getFieldHeight = (field: EngravingField, index?: number) => {
  if (field.type === 'text') {
    if (field.scoreType === 'gen4_strap_long' && index === 2) {
      return 20; // small exception for gen4 engraving, adds gap between liens 1-2 & 3-4
    }
    return PREVIEW_HEIGHT[field.scoreType];
  }
  return 30; // icon height
};
export const sumFieldHeights = (fields: EngravingField[]): number =>
  fields?.reduce((acc, field, i) => acc + getFieldHeight(field, i), 0);

export const PREVIEW_GAP = 2;
