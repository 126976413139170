import Filter from 'bad-words';
import { TFunction } from 'react-i18next';

export type EngravingType =
  | 'gen4_strap_text'
  | 'gen4_strap_icon'
  | 'gen4_id_text'
  | 'gen4_id_icon';
export const ENGRAVING_TYPES: EngravingType[] = [
  'gen4_strap_text',
  'gen4_strap_icon',
  'gen4_id_text',
  'gen4_id_icon',
];
export type EngravingValue = { value: string; error?: string; label?: string };
export type EngravingData = { [key: string]: EngravingValue };
export type EngravingInfo = {
  type: EngravingType;
  data: EngravingData;
};

export type EngravingScoreType =
  | 'gen4_strap_short'
  | 'gen4_strap_long'
  | 'gen4_id_long';
export interface EngravingTextField {
  type: 'text';
  name: string;
  display: string;
  label: string;
  scoreType: EngravingScoreType;
  engravingType: EngravingType;
}
export interface EngravingIconField {
  type: 'icon';
  name: string;
  display: string;
}
export type EngravingField = EngravingTextField | EngravingIconField;

export const getEngravingFields = (
  engravingType: EngravingType,
  t: TFunction,
): EngravingField[] => {
  if (engravingType === 'gen4_strap_text') {
    return [
      {
        type: 'text',
        name: 'line1',
        display: t('line-display', { number: 1 }),
        label: t('line-label-large', { number: 1 }),
        scoreType: 'gen4_strap_short',
        engravingType: engravingType,
      },
      {
        type: 'text',
        name: 'line2',
        display: t('line-display', { number: 2 }),
        label: t('line-label-large', { number: 2 }),
        scoreType: 'gen4_strap_short',
        engravingType: engravingType,
      },
      {
        type: 'text',
        name: 'line3',
        display: t('line-display', { number: 3 }),
        label: t('line-label-small', { number: 3 }),
        scoreType: 'gen4_strap_long',
        engravingType: engravingType,
      },
      {
        type: 'text',
        name: 'line4',
        display: t('line-display', { number: 4 }),
        label: t('line-label-small', { number: 4 }),
        scoreType: 'gen4_strap_long',
        engravingType: engravingType,
      },
    ];
  } else if (engravingType === 'gen4_id_text') {
    return [
      {
        type: 'text',
        name: 'line1',
        display: t('line-display', { number: 1 }),
        label: t('line-label-example-green', { number: 1 }),
        scoreType: 'gen4_id_long',
        engravingType: engravingType,
      },
      {
        type: 'text',
        name: 'line2',
        display: t('line-display', { number: 2 }),
        label: t('line-label-example-club', { number: 2 }),
        scoreType: 'gen4_id_long',
        engravingType: engravingType,
      },
      {
        type: 'text',
        name: 'line3',
        display: t('line-display', { number: 3 }),
        label: t('line-label', { number: 3 }),
        scoreType: 'gen4_id_long',
        engravingType: engravingType,
      },
      {
        type: 'text',
        name: 'line4',
        display: t('line-display', { number: 4 }),
        label: t('line-label', { number: 4 }),
        scoreType: 'gen4_id_long',
        engravingType: engravingType,
      },
      {
        type: 'text',
        name: 'line5',
        display: t('line-display', { number: 5 }),
        label: t('line-label', { number: 5 }),
        scoreType: 'gen4_id_long',
        engravingType: engravingType,
      },
      {
        type: 'text',
        name: 'line6',
        display: t('line-display', { number: 6 }),
        label: t('line-label', { number: 6 }),
        scoreType: 'gen4_id_long',
        engravingType: engravingType,
      },
    ];
  } else if (engravingType === 'gen4_strap_icon') {
    return [
      { type: 'icon', name: 'icon', display: t('icon-display') },
      {
        type: 'text',
        name: 'line1',
        display: t('line-display', { number: 1 }),
        label: t('line-label-large', { number: 1 }),
        scoreType: 'gen4_strap_short',
        engravingType: engravingType,
      },
    ];
  } else if (engravingType === 'gen4_id_icon') {
    return [
      { type: 'icon', name: 'icon', display: t('icon-display') },
      {
        type: 'text',
        name: 'line1',
        display: t('line-display', { number: 1 }),
        label: t('line-label-example-green', { number: 1 }),
        scoreType: 'gen4_id_long',
        engravingType: engravingType,
      },
    ];
  }
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  console.error(new Error(`Unsupported engraving type: ${engravingType}`));
  return [];
};

export const getEngravingError = (
  engravingInfo: EngravingInfo | undefined,
  t: TFunction,
): string => {
  if (engravingInfo?.data) {
    const values = Object.values(engravingInfo.data) || [];
    if (values.length === 0 || !values.find((item) => item.value)) {
      return t('error.cannot-be-empty');
    } else if (
      engravingInfo?.type === 'gen4_strap_icon' &&
      !engravingInfo?.data?.icon?.value
    ) {
      return t('error.select-icon');
    } else if (values.find((item) => item.error)) {
      return t('error.fix-all-errors');
    } else {
      return t('error.cannot-be-empty');
    }
  } else {
    return t('error.cannot-be-empty');
  }
};

/**
 * Given a partial or complete engraving, completes it
 * @param type: EngravingType
 * @param data: EngravingData
 * @param fields: all engraving fields
 */
export const completeEngraving = (
  type: EngravingType,
  data: EngravingData,
  fields: EngravingField[],
): EngravingData | undefined => {
  if (fields) {
    const completed: EngravingData = {};
    fields.forEach((field) => {
      const engravingValue = data?.[field.name];
      completed[field.name] = {
        value: engravingValue?.value || '',
        error: engravingValue?.error,
        label: field.display,
      };
    });
    return completed;
  }
};

const MAX_SCORES = {
  gen4_strap_short: 29,
  gen4_strap_long: 40,
  gen4_id_long: 40,
};
type EngravingScores = Record<string, Record<EngravingScoreType, number>>;
const SENSOR_ENGRAVING_KEYS: EngravingScores = [
  {
    key: `A`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 2.53,
    gen4_id_long: 2.53,
  },
  {
    key: `B`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 2.53,
    gen4_id_long: 2.53,
  },
  {
    key: `C`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 2.53,
    gen4_id_long: 2.53,
  },
  {
    key: `D`,
    gen4_strap_short: 2.8,
    gen4_strap_long: 2.71,
    gen4_id_long: 2.71,
  },
  {
    key: `E`,
    gen4_strap_short: 2.15,
    gen4_strap_long: 2.11,
    gen4_id_long: 2.11,
  },
  {
    key: `F`,
    gen4_strap_short: 2.15,
    gen4_strap_long: 2.11,
    gen4_id_long: 2.11,
  },
  {
    key: `G`,
    gen4_strap_short: 2.8,
    gen4_strap_long: 2.71,
    gen4_id_long: 2.71,
  },
  {
    key: `H`,
    gen4_strap_short: 2.8,
    gen4_strap_long: 2.71,
    gen4_id_long: 2.71,
  },
  { key: `I`, gen4_strap_short: 1.0, gen4_strap_long: 1.0, gen4_id_long: 1.0 },
  {
    key: `J`,
    gen4_strap_short: 1.87,
    gen4_strap_long: 1.81,
    gen4_id_long: 1.81,
  },
  {
    key: `K`,
    gen4_strap_short: 2.0,
    gen4_strap_long: 2.38,
    gen4_id_long: 2.38,
  },
  { key: `L`, gen4_strap_short: 2.0, gen4_strap_long: 1.9, gen4_id_long: 1.9 },
  {
    key: `M`,
    gen4_strap_short: 3.11,
    gen4_strap_long: 3.17,
    gen4_id_long: 3.17,
  },
  {
    key: `N`,
    gen4_strap_short: 2.8,
    gen4_strap_long: 3.17,
    gen4_id_long: 3.17,
  },
  {
    key: `O`,
    gen4_strap_short: 2.8,
    gen4_strap_long: 2.92,
    gen4_id_long: 2.92,
  },
  {
    key: `P`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.38,
    gen4_id_long: 2.38,
  },
  {
    key: `Q`,
    gen4_strap_short: 2.8,
    gen4_strap_long: 2.92,
    gen4_id_long: 2.92,
  },
  {
    key: `R`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 2.38,
    gen4_id_long: 2.38,
  },
  {
    key: `S`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  {
    key: `T`,
    gen4_strap_short: 2.15,
    gen4_strap_long: 2.11,
    gen4_id_long: 2.11,
  },
  {
    key: `U`,
    gen4_strap_short: 2.8,
    gen4_strap_long: 2.71,
    gen4_id_long: 2.71,
  },
  {
    key: `V`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 2.53,
    gen4_id_long: 2.53,
  },
  {
    key: `W`,
    gen4_strap_short: 3.5,
    gen4_strap_long: 3.45,
    gen4_id_long: 3.45,
  },
  {
    key: `X`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 2.53,
    gen4_id_long: 2.53,
  },
  {
    key: `Y`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 2.38,
    gen4_id_long: 2.38,
  },
  {
    key: `Z`,
    gen4_strap_short: 2.15,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  {
    key: `-`,
    gen4_strap_short: 1.12,
    gen4_strap_long: 1.09,
    gen4_id_long: 1.09,
  },
  {
    key: `_`,
    gen4_strap_short: 2.15,
    gen4_strap_long: 2.11,
    gen4_id_long: 2.11,
  },
  { key: `+`, gen4_strap_short: 1.87, gen4_strap_long: 1.9, gen4_id_long: 1.9 },
  {
    key: `(`,
    gen4_strap_short: 1.08,
    gen4_strap_long: 1.09,
    gen4_id_long: 1.09,
  },
  {
    key: `)`,
    gen4_strap_short: 1.08,
    gen4_strap_long: 1.09,
    gen4_id_long: 1.09,
  },
  {
    key: `.`,
    gen4_strap_short: 0.93,
    gen4_strap_long: 0.93,
    gen4_id_long: 0.93,
  },
  {
    key: `0`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  {
    key: `1`,
    gen4_strap_short: 1.56,
    gen4_strap_long: 1.52,
    gen4_id_long: 1.52,
  },
  {
    key: `2`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  {
    key: `3`,
    gen4_strap_short: 2.15,
    gen4_strap_long: 2.11,
    gen4_id_long: 2.11,
  },
  {
    key: `4`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  {
    key: `5`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  {
    key: `6`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  { key: `7`, gen4_strap_short: 2.0, gen4_strap_long: 2.0, gen4_id_long: 2.0 },
  {
    key: `8`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  {
    key: `9`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  { key: `~`, gen4_strap_short: 1.87, gen4_strap_long: 1.9, gen4_id_long: 1.9 },
  {
    key: `!`,
    gen4_strap_short: 0.93,
    gen4_strap_long: 0.93,
    gen4_id_long: 0.93,
  },
  {
    key: `@`,
    gen4_strap_short: 3.11,
    gen4_strap_long: 2.92,
    gen4_id_long: 2.92,
  },
  {
    key: `#`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  {
    key: `$`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  {
    key: `%`,
    gen4_strap_short: 2.8,
    gen4_strap_long: 2.92,
    gen4_id_long: 2.92,
  },
  {
    key: `^`,
    gen4_strap_short: 1.65,
    gen4_strap_long: 1.65,
    gen4_id_long: 1.65,
  },
  {
    key: `&`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 2.38,
    gen4_id_long: 2.38,
  },
  {
    key: `*`,
    gen4_strap_short: 1.27,
    gen4_strap_long: 1.27,
    gen4_id_long: 1.27,
  },
  { key: `+`, gen4_strap_short: 1.87, gen4_strap_long: 1.9, gen4_id_long: 1.9 },
  {
    key: `/`,
    gen4_strap_short: 1.22,
    gen4_strap_long: 1.23,
    gen4_id_long: 1.23,
  },
  {
    key: `\\`,
    gen4_strap_short: 1.22,
    gen4_strap_long: 1.23,
    gen4_id_long: 1.23,
  },
  {
    key: `|`,
    gen4_strap_short: 0.8,
    gen4_strap_long: 0.78,
    gen4_id_long: 0.78,
  },
  {
    key: `?`,
    gen4_strap_short: 1.75,
    gen4_strap_long: 1.73,
    gen4_id_long: 1.73,
  },
  { key: `<`, gen4_strap_short: 1.87, gen4_strap_long: 1.9, gen4_id_long: 1.9 },
  { key: `>`, gen4_strap_short: 1.87, gen4_strap_long: 1.9, gen4_id_long: 1.9 },
  { key: `:`, gen4_strap_short: 0.9, gen4_strap_long: 0.9, gen4_id_long: 0.9 },
  {
    key: `;`,
    gen4_strap_short: 0.93,
    gen4_strap_long: 0.93,
    gen4_id_long: 0.93,
  },
  {
    key: `"`,
    gen4_strap_short: 1.56,
    gen4_strap_long: 1.52,
    gen4_id_long: 1.52,
  },
  {
    key: `'`,
    gen4_strap_short: 0.85,
    gen4_strap_long: 0.83,
    gen4_id_long: 0.83,
  },
  {
    key: `{`,
    gen4_strap_short: 1.08,
    gen4_strap_long: 1.06,
    gen4_id_long: 1.06,
  },
  {
    key: `}`,
    gen4_strap_short: 1.08,
    gen4_strap_long: 1.06,
    gen4_id_long: 1.06,
  },
  {
    key: `[`,
    gen4_strap_short: 1.04,
    gen4_strap_long: 1.03,
    gen4_id_long: 1.03,
  },
  {
    key: `]`,
    gen4_strap_short: 1.04,
    gen4_strap_long: 1.03,
    gen4_id_long: 1.03,
  },
  { key: ` `, gen4_strap_short: 1.0, gen4_strap_long: 1.0, gen4_id_long: 1.0 },
  {
    key: `Ç`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.38,
    gen4_id_long: 2.38,
  },
  {
    key: `ß`,
    gen4_strap_short: 2.8,
    gen4_strap_long: 2.71,
    gen4_id_long: 2.71,
  },
  {
    key: `Å`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 1.65,
    gen4_id_long: 1.65,
  },
  {
    key: `Ä`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 1.65,
    gen4_id_long: 1.65,
  },
  {
    key: `Â`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  {
    key: `À`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 1.58,
    gen4_id_long: 1.58,
  },
  {
    key: `Á`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 1.58,
    gen4_id_long: 1.58,
  },
  {
    key: `Ë`,
    gen4_strap_short: 2.0,
    gen4_strap_long: 1.36,
    gen4_id_long: 1.36,
  },
  { key: `Ê`, gen4_strap_short: 2.0, gen4_strap_long: 1.9, gen4_id_long: 1.9 },
  {
    key: `È`,
    gen4_strap_short: 2.0,
    gen4_strap_long: 1.73,
    gen4_id_long: 1.73,
  },
  { key: `É`, gen4_strap_short: 2.0, gen4_strap_long: 1.9, gen4_id_long: 1.9 },
  {
    key: `Ï`,
    gen4_strap_short: 0.97,
    gen4_strap_long: 0.67,
    gen4_id_long: 0.67,
  },
  {
    key: `Î`,
    gen4_strap_short: 0.93,
    gen4_strap_long: 0.93,
    gen4_id_long: 0.93,
  },
  {
    key: `Ì`,
    gen4_strap_short: 0.93,
    gen4_strap_long: 0.93,
    gen4_id_long: 0.93,
  },
  {
    key: `Í`,
    gen4_strap_short: 0.93,
    gen4_strap_long: 0.93,
    gen4_id_long: 0.93,
  },
  {
    key: `Ö`,
    gen4_strap_short: 2.8,
    gen4_strap_long: 2.38,
    gen4_id_long: 2.38,
  },
  {
    key: `Ô`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 2.53,
    gen4_id_long: 2.53,
  },
  {
    key: `Ø`,
    gen4_strap_short: 2.8,
    gen4_strap_long: 2.92,
    gen4_id_long: 2.92,
  },
  {
    key: `Ò`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 2.53,
    gen4_id_long: 2.53,
  },
  {
    key: `Ó`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 2.53,
    gen4_id_long: 2.53,
  },
  {
    key: `Û`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  {
    key: `Ü`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 2.53,
    gen4_id_long: 2.53,
  },
  {
    key: `Ù`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 2.53,
    gen4_id_long: 2.53,
  },
  {
    key: `Ú`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 2.53,
    gen4_id_long: 2.53,
  },
  {
    key: `Œ`,
    gen4_strap_short: 5.6,
    gen4_strap_long: 5.43,
    gen4_id_long: 5.43,
  },
  {
    key: `Æ`,
    gen4_strap_short: 5.6,
    gen4_strap_long: 5.43,
    gen4_id_long: 5.43,
  },
  {
    key: `Ñ`,
    gen4_strap_short: 2.8,
    gen4_strap_long: 3.17,
    gen4_id_long: 3.17,
  },
].reduce((acc: EngravingScores, { key, ...vals }) => {
  acc[key] = vals;
  return acc;
}, {});

const WHOOP_ID_ENGRAVING_KEYS: EngravingScores = [
  {
    key: `A`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 2.53,
    gen4_id_long: 2.53,
  },
  {
    key: `B`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 2.53,
    gen4_id_long: 2.53,
  },
  {
    key: `C`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 2.53,
    gen4_id_long: 2.53,
  },
  {
    key: `D`,
    gen4_strap_short: 2.8,
    gen4_strap_long: 2.71,
    gen4_id_long: 2.71,
  },
  {
    key: `E`,
    gen4_strap_short: 2.15,
    gen4_strap_long: 2.11,
    gen4_id_long: 2.11,
  },
  {
    key: `F`,
    gen4_strap_short: 2.15,
    gen4_strap_long: 2.11,
    gen4_id_long: 2.11,
  },
  {
    key: `G`,
    gen4_strap_short: 2.8,
    gen4_strap_long: 2.71,
    gen4_id_long: 2.71,
  },
  {
    key: `H`,
    gen4_strap_short: 2.8,
    gen4_strap_long: 2.71,
    gen4_id_long: 2.71,
  },
  { key: `I`, gen4_strap_short: 1.0, gen4_strap_long: 1.0, gen4_id_long: 1.0 },
  {
    key: `J`,
    gen4_strap_short: 1.87,
    gen4_strap_long: 1.81,
    gen4_id_long: 1.81,
  },
  {
    key: `K`,
    gen4_strap_short: 2.0,
    gen4_strap_long: 2.38,
    gen4_id_long: 2.38,
  },
  { key: `L`, gen4_strap_short: 2.0, gen4_strap_long: 1.9, gen4_id_long: 1.9 },
  {
    key: `M`,
    gen4_strap_short: 3.11,
    gen4_strap_long: 3.17,
    gen4_id_long: 3.17,
  },
  {
    key: `N`,
    gen4_strap_short: 2.8,
    gen4_strap_long: 3.17,
    gen4_id_long: 3.17,
  },
  {
    key: `O`,
    gen4_strap_short: 2.8,
    gen4_strap_long: 2.92,
    gen4_id_long: 2.92,
  },
  {
    key: `P`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.38,
    gen4_id_long: 2.38,
  },
  {
    key: `Q`,
    gen4_strap_short: 2.8,
    gen4_strap_long: 2.92,
    gen4_id_long: 2.92,
  },
  {
    key: `R`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 2.38,
    gen4_id_long: 2.38,
  },
  {
    key: `S`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  {
    key: `T`,
    gen4_strap_short: 2.15,
    gen4_strap_long: 2.11,
    gen4_id_long: 2.11,
  },
  {
    key: `U`,
    gen4_strap_short: 2.8,
    gen4_strap_long: 2.71,
    gen4_id_long: 2.71,
  },
  {
    key: `V`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 2.53,
    gen4_id_long: 2.53,
  },
  {
    key: `W`,
    gen4_strap_short: 3.5,
    gen4_strap_long: 3.45,
    gen4_id_long: 3.45,
  },
  {
    key: `X`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 2.53,
    gen4_id_long: 2.53,
  },
  {
    key: `Y`,
    gen4_strap_short: 2.55,
    gen4_strap_long: 2.38,
    gen4_id_long: 2.38,
  },
  {
    key: `Z`,
    gen4_strap_short: 2.15,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  {
    key: `-`,
    gen4_strap_short: 1.12,
    gen4_strap_long: 1.09,
    gen4_id_long: 1.09,
  },
  {
    key: `_`,
    gen4_strap_short: 2.15,
    gen4_strap_long: 2.11,
    gen4_id_long: 2.11,
  },
  { key: `+`, gen4_strap_short: 1.87, gen4_strap_long: 1.9, gen4_id_long: 1.9 },
  {
    key: `(`,
    gen4_strap_short: 1.08,
    gen4_strap_long: 1.09,
    gen4_id_long: 1.09,
  },
  {
    key: `)`,
    gen4_strap_short: 1.08,
    gen4_strap_long: 1.09,
    gen4_id_long: 1.09,
  },
  {
    key: `.`,
    gen4_strap_short: 0.93,
    gen4_strap_long: 0.93,
    gen4_id_long: 0.93,
  },
  {
    key: `0`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  {
    key: `1`,
    gen4_strap_short: 1.56,
    gen4_strap_long: 1.52,
    gen4_id_long: 1.52,
  },
  {
    key: `2`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  {
    key: `3`,
    gen4_strap_short: 2.15,
    gen4_strap_long: 2.11,
    gen4_id_long: 2.11,
  },
  {
    key: `4`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  {
    key: `5`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  {
    key: `6`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  { key: `7`, gen4_strap_short: 2.0, gen4_strap_long: 2.0, gen4_id_long: 2.0 },
  {
    key: `8`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  {
    key: `9`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  { key: `~`, gen4_strap_short: 1.87, gen4_strap_long: 1.9, gen4_id_long: 1.9 },
  {
    key: `!`,
    gen4_strap_short: 0.93,
    gen4_strap_long: 0.93,
    gen4_id_long: 0.93,
  },
  {
    key: `@`,
    gen4_strap_short: 3.11,
    gen4_strap_long: 2.92,
    gen4_id_long: 2.92,
  },
  {
    key: `#`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  {
    key: `$`,
    gen4_strap_short: 2.33,
    gen4_strap_long: 2.24,
    gen4_id_long: 2.24,
  },
  {
    key: `%`,
    gen4_strap_short: 2.8,
    gen4_strap_long: 2.92,
    gen4_id_long: 2.92,
  },
  { key: `+`, gen4_strap_short: 1.87, gen4_strap_long: 1.9, gen4_id_long: 1.9 },
  {
    key: `/`,
    gen4_strap_short: 1.22,
    gen4_strap_long: 1.23,
    gen4_id_long: 1.23,
  },
  {
    key: `\\`,
    gen4_strap_short: 1.22,
    gen4_strap_long: 1.23,
    gen4_id_long: 1.23,
  },
  {
    key: `|`,
    gen4_strap_short: 0.8,
    gen4_strap_long: 0.78,
    gen4_id_long: 0.78,
  },
  {
    key: `?`,
    gen4_strap_short: 1.75,
    gen4_strap_long: 1.73,
    gen4_id_long: 1.73,
  },
  {
    key: `'`,
    gen4_strap_short: 0.85,
    gen4_strap_long: 0.83,
    gen4_id_long: 0.83,
  },
  {
    key: `{`,
    gen4_strap_short: 1.08,
    gen4_strap_long: 1.06,
    gen4_id_long: 1.06,
  },
  {
    key: `}`,
    gen4_strap_short: 1.08,
    gen4_strap_long: 1.06,
    gen4_id_long: 1.06,
  },
  {
    key: `[`,
    gen4_strap_short: 1.04,
    gen4_strap_long: 1.03,
    gen4_id_long: 1.03,
  },
  {
    key: `]`,
    gen4_strap_short: 1.04,
    gen4_strap_long: 1.03,
    gen4_id_long: 1.03,
  },
  { key: ` `, gen4_strap_short: 1.0, gen4_strap_long: 1.0, gen4_id_long: 1.0 },
].reduce((acc: EngravingScores, { key, ...vals }) => {
  acc[key] = vals;
  return acc;
}, {});

// Does not validate text!
function getEngravingScore(
  text: string,
  scoreType: EngravingScoreType,
  engravingKeys: EngravingScores,
): number {
  return text
    ?.toUpperCase()
    .split('')
    .reduce(
      (acc, letter) => acc + (engravingKeys?.[letter]?.[scoreType] || 0),
      0,
    );
}

// TODO: some engraving types may not support some characters so those should be undefined
function getInvalidEngravingCharacters(
  text: string,
  scoreType: EngravingScoreType,
  engravingKeys: EngravingScores,
) {
  const badChars = text
    ?.toUpperCase()
    .split('')
    .filter((letter) => !engravingKeys?.[letter]?.[scoreType]);
  if (badChars) {
    return [...new Set(badChars)]; // unique
  }
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const ProfanityFilter = new Filter({ emptyList: true });
const BLOCKED_WORDS = [
  'fuck',
  'shit',
  'bitch',
  'fag',
  'nigger',
  'nigga',
  'gook',
  'coon',
  'cunt',
  'pussy',
];
// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
ProfanityFilter.addWords(...BLOCKED_WORDS);

/**
 * Get the error for an engraving line
 * @param text text of the line
 * @param scoreType scoring type
 * @param engravingType engraving type
 * @param t translation function
 */
export function getTextEngravingError(
  text: string,
  scoreType: EngravingScoreType,
  engravingType: EngravingType,
  t: TFunction,
) {
  const engravingKeys: EngravingScores =
    engravingType === 'gen4_strap_text' || engravingType === 'gen4_strap_icon'
      ? SENSOR_ENGRAVING_KEYS
      : WHOOP_ID_ENGRAVING_KEYS;
  const invalidChars = getInvalidEngravingCharacters(
    text,
    scoreType,
    engravingKeys,
  );
  if (invalidChars && invalidChars.length > 0) {
    return t('error.characters-cannot-be-engraved', {
      characters: invalidChars.join(''),
    });
  }
  const score = getEngravingScore(text, scoreType, engravingKeys);
  if (score) {
    const max = MAX_SCORES[scoreType];
    if (score >= max) {
      return t('error.does-not-fit');
    }
  }
  if (ProfanityFilter.isProfane(text)) {
    return t('error.no-profanity');
  }
}

export const ENGRAVING_ACTIVITY_ICONS = [
  'baseball',
  'basketball',
  'boxing',
  'caddying',
  'climber',
  'coaching',
  'commuting',
  'cricket',
  'cross_country_skiing',
  'cycling',
  'dance',
  'diving',
  'duathlon',
  'elliptical',
  'fencing',
  'field_hockey',
  'football',
  'gaming',
  'golf',
  'gymnastic',
  'hiit',
  'hiking_rucking',
  'horsebackriding',
  'ice_bathing',
  'ice_hockey',
  'jump_roping',
  'kayaking',
  'lacrosse',
  'martialarts',
  'meditation',
  'motocross',
  'motor_racing',
  'mountainbiking',
  'obstacleracing',
  'other',
  'paddleboard',
  'pilates',
  'powerlifting',
  'rockclimbing',
  'rowing',
  'rugby',
  'running',
  'sailing',
  'skateboard',
  'skiing',
  'soccer',
  'softball',
  'spin',
  'squash',
  'stairmaster',
  'surfing',
  'swimming',
  'tennis',
  'track_and_field',
  'triathlon',
  'ultimate',
  'volleyball',
  'walking',
  'waterpolo',
  'weightlifting',
  'work',
  'wrestling',
  'yoga',
  'functional_fitness',
  'pickleball',
  'rollerblading',
];
export const WHOOP_ID_ENGRAVING_ACTIVITY_ICONS = [
  'medical_alert',
  'whoop_logo',
  ...ENGRAVING_ACTIVITY_ICONS,
];
export const getLabelFromIconName = function (iconName: string) {
  if (iconName) {
    return iconName
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
};
