import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  useBaseProDiscount,
  useHasProBenefits,
  useIsEmployee,
  useShowAsFree,
} from '../redux/hooks';
import { Optional } from '@whoop/web-components/dist/types';
import { formatPrice as _formatPrice } from '@whoop/web-components/dist/utils';
import { ShopifyProductVariant } from '../types/generated';
import { ShopifyVariant } from '../hooks/useAllProducts';
import { isMarkedDown } from './index';
import { SHOP_CURRENCY_CODE } from './regions';
import {
  isGiftCard,
  isMemberExtension,
  isProductItemExclusive,
  ProductItemKey,
} from './productUtils';
import useWhoopYourWayPriceRange from '../hooks/useWhoopYourWayPriceRange';
import { Purchasable } from './purchasableUtils';

export const EMPLOYEE_DISCOUNT_TAG = 'employeediscount';
const DISCOUNT_TAG_PREFIX = '__discount:';
const WP_DISCOUNT_TAG_PREFIX = '__wp_discount:';

/**
 * Contains the necessary information to display a price
 */
export interface PriceTag {
  price: string;
  originalPrice?: string;
  isProDiscount?: boolean;
  whoopProPrice?: string;
}

export interface TagDiscount {
  discount: number;
  proDiscount: number;
}

/**
 * Formats a price according to currency
 * @param price a valid number
 * @param currency currency code
 * @param locale currency locale
 * @param language
 */
export const formatPrice = function (
  price?: string | number | null | undefined,
  currency = SHOP_CURRENCY_CODE,
  language = process.env.SHOP_LANGUAGE,
): string {
  const float = parseFloat(<string>(price || 0));
  if (isNaN(float)) {
    return <string>price || ''; // we don't format strings or other NaNs
  }

  return _formatPrice(float, currency, language);
};

/**
 * If this product should be allowed to have an employee discount
 * @param tags shopify tags for this product
 */
export function isEmployeeDiscountEligible(tags?: string[]): boolean {
  return !!tags?.find((tag) => tag === EMPLOYEE_DISCOUNT_TAG);
}

/**
 * Extracts the discount from a products tags
 * @param tags
 */
export function getDiscountFromTags(tags?: string[]): TagDiscount {
  const discountTag = tags?.find((tag) => tag?.startsWith(DISCOUNT_TAG_PREFIX));
  const discount = discountTag
    ? parseInt(discountTag.slice(DISCOUNT_TAG_PREFIX.length))
    : 0;

  const wpDiscountTag = tags?.find((tag) =>
    tag?.startsWith(WP_DISCOUNT_TAG_PREFIX),
  );
  const wpDiscount = wpDiscountTag
    ? parseInt(wpDiscountTag.slice(WP_DISCOUNT_TAG_PREFIX.length))
    : 0;

  return {
    discount: isNaN(discount) ? 0 : discount,
    proDiscount: isNaN(wpDiscount) ? 0 : wpDiscount,
  };
}

/**
 * Calculates pro price based on discount percent
 *
 * @param discount percent
 * @param price
 * @param originalPrice
 * @param sku
 */
export function getProPrice(
  discount: number, // Discount as a whole-number percentage
  price?: string | number | null,
  originalPrice?: string | number | null,
  sku?: string | null,
): number | undefined {
  const isOnSale = isMarkedDown(originalPrice, price);
  const isExcludedFromPro = isMemberExtension(sku) || isGiftCard(sku);

  const whoopProPrice = Math.ceil(
    parseFloat((price as string) || '0') -
      parseFloat((price as string) || '0') * (discount / 100.0),
  );

  if (!isOnSale && !isExcludedFromPro) {
    return whoopProPrice;
  }
}

/**
 * Takes all of the potential prices and generates a price tag
 *
 * @param basePrice
 * @param compareToPrice
 * @param tagDiscountPrice
 * @param whoopProPrice
 * @param isWhoopPro
 * @param freeText text to use if the price is "FREE"
 */
export function generatePriceTag(
  basePrice?: string | number | null,
  compareToPrice?: string | number | null,
  tagDiscountPrice?: number | null,
  whoopProPrice?: number | null,
  isWhoopPro?: boolean,
  freeText?: string,
): PriceTag {
  const isOnSale = isMarkedDown(compareToPrice, basePrice);
  const isProDiscount = !!(
    isWhoopPro &&
    !isNaN(whoopProPrice as number) &&
    !isOnSale
  );

  const finalPrice = isProDiscount
    ? whoopProPrice
    : tagDiscountPrice && !isNaN(tagDiscountPrice)
    ? tagDiscountPrice
    : basePrice;
  const finalOriginalPrice =
    compareToPrice ||
    (isProDiscount && basePrice) ||
    (tagDiscountPrice !== null && basePrice) ||
    0;

  const isFree = !finalPrice || parseFloat(finalPrice as string) === 0;
  const isNull = basePrice == null;

  return {
    price: isNull
      ? '--'
      : isFree
      ? freeText?.toUpperCase() || 'FREE'
      : formatPrice(finalPrice),
    originalPrice:
      isMarkedDown(finalOriginalPrice, finalPrice) && !isNull
        ? formatPrice(finalOriginalPrice)
        : undefined,
    isProDiscount,
    whoopProPrice: whoopProPrice?.toString() || undefined,
  };
}

/**
 * Gets the automatic discount percent based on the product tags
 * @param tags
 */
export function useAutomaticDiscount(tags: string[] = []): number {
  const baseProDiscount = useBaseProDiscount();
  const isWhoopPro = useHasProBenefits();
  const isEmployee = useIsEmployee();
  const employeeDiscountEligible = isEmployeeDiscountEligible(tags);
  const shouldApplyProDiscount =
    isWhoopPro && (employeeDiscountEligible || !isEmployee);
  const { discount, proDiscount } = getDiscountFromTags(tags);
  const finalProDiscount = Math.max(
    baseProDiscount,
    discount,
    shouldApplyProDiscount ? proDiscount : 0,
  );

  return shouldApplyProDiscount ? finalProDiscount : discount || 0;
}

/**
 * Generates a price tag based on the Shopify product tags
 */
export const usePriceTag = (
  tags: string[] = [],
  variant: Optional<ShopifyProductVariant> | Optional<ShopifyVariant>,
  purchasable: Optional<Purchasable>,
): PriceTag => {
  const { t } = useTranslation('price');
  const isWhoopPro = useHasProBenefits();
  const showAsFree = useShowAsFree(
    tags,
    parseFloat(String(variant?.price) || '0'),
  );
  const isEmployee = useIsEmployee();
  const employeeDiscountEligible = isEmployeeDiscountEligible(tags);
  const isProDiscountExcludedEligible = isProductItemExclusive(
    purchasable,
    ProductItemKey.pro_exclude_discount,
  );
  const shouldApplyProDiscount =
    (employeeDiscountEligible || !isEmployee) && !isProDiscountExcludedEligible;
  const baseProDiscount = useBaseProDiscount();
  const { discount, proDiscount } = getDiscountFromTags(tags);
  const tagDiscountPrice =
    discount && variant?.price
      ? Math.ceil(parseFloat(String(variant.price)) * (1 - discount / 100))
      : null;

  if (isEmployee && !employeeDiscountEligible) {
    return generatePriceTag(
      variant?.price,
      variant?.compareAtPrice,
      tagDiscountPrice,
      undefined,
      false,
    );
  } else {
    const whoopProPrice = isProDiscountExcludedEligible
      ? undefined
      : getProPrice(
          Math.max(
            baseProDiscount,
            discount,
            shouldApplyProDiscount ? proDiscount : 0,
          ),
          variant?.price,
          variant?.compareAtPrice,
          variant?.sku,
        );

    const priceOverride: string | undefined = tags
      ?.find((tag) => tag && tag.includes(`__price:`))
      ?.replace(`__price:`, '');

    return priceOverride
      ? { price: priceOverride }
      : generatePriceTag(
          variant?.price,
          variant?.compareAtPrice,
          tagDiscountPrice,
          showAsFree ? 0 : whoopProPrice,
          isWhoopPro && shouldApplyProDiscount,
          t('free'),
        );
  }
};

export function useWhoopYourWayPriceTag(tags: string[] = []): PriceTag {
  const isWhoopPro = useHasProBenefits();
  const baseProDiscount = useBaseProDiscount();
  const { min } = useWhoopYourWayPriceRange();
  const { discount, proDiscount } = getDiscountFromTags(tags);
  const price = formatPrice(min) + '+';

  if (!isWhoopPro) {
    const discountedPrice =
      formatPrice(Math.ceil((min || 0) * (1 - discount / 100))) + '+';
    const maxProDiscount = Math.max(baseProDiscount, proDiscount);
    const proDiscountedPrice =
      formatPrice(Math.ceil((min || 0) * (1 - maxProDiscount / 100))) + '+';

    return {
      price: discount ? discountedPrice : price,
      originalPrice: discount ? price : undefined,
      whoopProPrice:
        (discount || 0) >= (maxProDiscount || 0)
          ? undefined
          : proDiscountedPrice,
    };
  } else {
    const maxDiscount = Math.max(baseProDiscount, discount, proDiscount);
    const maxDiscountedPrice =
      formatPrice(Math.ceil((min || 0) * (1 - maxDiscount / 100))) + '+';
    return {
      price: maxDiscountedPrice,
      originalPrice: price,
      isProDiscount:
        (discount || 0) <= (proDiscount || 0) ||
        (discount || 0) <= (baseProDiscount || 0),
    };
  }
}

/**
 * Determines if a variant/tags are on sale as a function, where whether the
 * user has WP benefits and what the base WP discount is are explicitly passed
 * in as parameters.
 *
 * @param variant variant to check
 * @param tags product tags
 * @param hasProBenefits whether or not the user has WP benefits
 * @param baseProDiscount what the base WP discount percentage is
 */
export function isDiscounted(
  variant: Optional<ShopifyProductVariant>,
  tags: string[],
  hasProBenefits: boolean,
  baseProDiscount: number,
): boolean {
  const { discount, proDiscount } = getDiscountFromTags(tags);
  const isAutoDiscount = hasProBenefits
    ? !!(proDiscount || discount) &&
      Math.max(discount, proDiscount) > baseProDiscount
    : !!discount;
  const isStrikeThrough = isMarkedDown(variant?.compareAtPrice, variant?.price);
  return isAutoDiscount || isStrikeThrough;
}

/**
 * Determines if a variant/tags are on sale, via a hook that calculates whether
 * the user has WP benefits and what the base WP discount is.
 *
 * @param variant variant to check
 * @param tags product tags
 */
export function useIsDiscounted(
  variant: Optional<ShopifyProductVariant>,
  tags: string[],
): boolean {
  const hasProBenefits = useHasProBenefits();
  const baseProDiscount = useBaseProDiscount();
  return isDiscounted(variant, tags, hasProBenefits, baseProDiscount);
}

export function useDiscountMessage(
  variant: Optional<ShopifyProductVariant>,
  tags: string[] = [],
): string | undefined {
  const { t } = useTranslation('product');
  const isWhoopPro = useHasProBenefits();
  const isEmployee = useIsEmployee();
  const employeeDiscountEligible = isEmployeeDiscountEligible(tags);
  const shouldApplyProDiscount =
    isWhoopPro && (employeeDiscountEligible || !isEmployee);
  const { discount, proDiscount } = getDiscountFromTags(tags);
  const isOnSale = useIsDiscounted(variant, tags);
  const finalProDiscount = Math.max(
    discount,
    shouldApplyProDiscount ? proDiscount : 0,
  );

  // There is a corner case where isOnSale === true, but the discount is 0%:
  // a product using a compare-at price for a discount. To make sure we do not
  // show the 'savePercent' text in that case, include the discount amount in
  // the check:
  if (isOnSale && (discount || proDiscount)) {
    return t('packBadge.savePercent', {
      percent: shouldApplyProDiscount ? finalProDiscount : discount,
    });
  }
}
