import React, { ChangeEvent, useEffect, useState } from 'react';
import { c, handleize } from '../../../utils';
import { RadioInputProps } from '../../../types/RadioInputs';

import styles from './radio-swatches.module.scss';
import { capitalize } from '../../../../../utils';

export type RadioSwatchesProps = RadioInputProps &
  React.ReactNode & {
    showToolTips?: boolean;
  };

export const RadioSwatches = ({
  className,
  name,
  onChange,
  onHover,
  options,
  showToolTips,
  size,
  useExternalState,
  allowNone = false,
  value,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Rest types may only be created from object types.
  ...props
}: RadioSwatchesProps): JSX.Element | null => {
  const [selectedValue, setSelectedValue] = useState<
    string | number | undefined
  >(value);

  // set value when parent updates value
  useEffect(() => {
    if (value != null) {
      setSelectedValue(value);
    } else if (!allowNone) {
      setSelectedValue(options?.[0]?.value);
    }
  }, [value]);

  if (!options.length) return null;

  const changeValue = (value: string | number) => {
    if (value === selectedValue) return;
    onChange && onChange(value);
    setSelectedValue(value);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    changeValue(value);
  };

  const preventFutureClickEvents = (
    event: React.MouseEvent<HTMLInputElement>,
  ) => {
    event.stopPropagation();
  };

  const actualValue = useExternalState ? value : selectedValue;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const swatchStyle = styles[`swatch${capitalize(size) || 'Medium'}`];
  return (
    <span
      aria-labelledby={name}
      className={c(styles.swatchContainer, swatchStyle, className)}
      role='radiogroup'
      {...props}
    >
      {options?.map(({ crossOut, disabled, label, style, value }, index) => {
        return (
          <div
            key={value}
            className={c(
              styles.swatch,
              !style?.background && styles.textSwatch,
              !style?.background &&
                label &&
                label.length > 2 &&
                styles.longText,
            )}
            onMouseEnter={() => onHover && onHover(value)}
          >
            <input
              id={`swatch-option-${name}-${handleize(String(value))}-${index}`}
              type='radio'
              name={name}
              value={value}
              className={c((disabled || crossOut) && styles.disabled)}
              ref={React.createRef()}
              onChange={handleOnChange}
              onClick={preventFutureClickEvents}
              checked={value === actualValue}
              aria-label={label}
              disabled={disabled}
            />
            <label
              htmlFor={`swatch-option-${name}-${handleize(
                String(value),
              )}-${index}`}
              style={style}
              data-tooltip={showToolTips && label ? label : undefined}
            >
              {!style?.background && label}
              {
                (disabled || crossOut) && (
                  <div data-test-id='radio-swatch-strikethrough' />
                ) /* this div is needed for a cross-out line */
              }
            </label>
          </div>
        );
      })}
    </span>
  );
};
