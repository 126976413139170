import React, { useRef } from 'react';
import '@whoop/web-components/dist/index.css';
import styles from './homepage.module.scss';
import { classes } from '../../utils';
import { shopifyCdnImage } from '../../utils/shopify';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Link } from 'gatsby';
import { Button, ButtonVariants } from '../../libs/web-components';

const EVENT_NAME = 'Clicked Main Categories';
const EVENT_SOURCE_IMG = 'image';
const EVENT_SOURCE_BTN = 'button';

export default function CollectionList(): JSX.Element {
  const { t } = useTranslation('collection');
  const $button1 = useRef<HTMLButtonElement>(null);
  const $button2 = useRef<HTMLButtonElement>(null);
  const $button3 = useRef<HTMLButtonElement>(null);

  return (
    <section role='contentinfo' aria-label={t('aria.highlightedCategories')}>
      <div
        className={classes(
          styles.collectionListContainer,
          styles.collectionListContainerSpaced,
          styles.collectionListContainerGrid,
        )}
      >
        <Link
          to='/collections/band'
          rel='noopener noreferrer'
          className={classes(
            styles.collectionItem,
            styles.collectionItemExpand,
          )}
          onMouseEnter={() => {
            $button1.current?.focus({ preventScroll: true });
          }}
          onMouseLeave={() => {
            $button1.current?.blur();
          }}
          data-event={EVENT_NAME}
          data-event-landing_page='/collections/band'
          data-event-source={EVENT_SOURCE_IMG}
          data-impress='Viewed Main Categories'
        >
          <div
            className={styles.collectionItemWrapper}
            style={{
              backgroundImage: `url(${shopifyCdnImage(
                'GEN4_WHOOP-Shopify-1120x1200_BANDS-Homepage-3Columns1_1x1.png',
              )})`,
            }}
          >
            <div className={styles.collectionItemImageWrapper}>
              <div
                className={classes(
                  styles.collectionItemImage,
                  styles.imageContrast,
                )}
                style={{
                  backgroundPosition: 'center center',
                  backgroundImage: `url(${shopifyCdnImage(
                    'GEN4_WHOOP-Shopify-1120x1200_BANDS-Homepage-3Columns1_750x960_crop_center.png',
                  )})`,
                }}
                data-optimumx='1.4'
                data-expand='-150'
                data-bgset={`${shopifyCdnImage(
                  'GEN4_WHOOP-Shopify-1120x1200_BANDS-Homepage-3Columns1_750x960_crop_center.png',
                )} 750w, ${shopifyCdnImage(
                  'GEN4_WHOOP-Shopify-1120x1200_BANDS-Homepage-3Columns1_1000x.png',
                )} 1000w, ${shopifyCdnImage(
                  'GEN4_WHOOP-Shopify-1120x1200_BANDS-Homepage-3Columns1_1500x.png',
                )} 1500w`}
              >
                <picture style={{ display: 'none' }}>
                  <source
                    data-srcset={`${shopifyCdnImage(
                      'GEN4_WHOOP-Shopify-1120x1200_BANDS-Homepage-3Columns1_750x960_crop_center.png',
                    )} 750w, ${shopifyCdnImage(
                      'GEN4_WHOOP-Shopify-1120x1200_BANDS-Homepage-3Columns1_1000x.png',
                    )} 1000w, ${shopifyCdnImage(
                      'GEN4_WHOOP-Shopify-1120x1200_BANDS-Homepage-3Columns1_1500x.png',
                    )} 1500w`}
                    sizes='598px'
                    srcSet={`${shopifyCdnImage(
                      'GEN4_WHOOP-Shopify-1120x1200_BANDS-Homepage-3Columns1_750x960_crop_center.png',
                    )} 750w, ${shopifyCdnImage(
                      'GEN4_WHOOP-Shopify-1120x1200_BANDS-Homepage-3Columns1_1000x.png',
                    )} 1000w, ${shopifyCdnImage(
                      'GEN4_WHOOP-Shopify-1120x1200_BANDS-Homepage-3Columns1_1500x.png',
                    )} 1500w`}
                  />
                  <img
                    alt=''
                    className='lazyautosizes Image--lazyLoaded'
                    data-sizes='auto'
                    data-optimumx='1.4'
                    data-parent-fit='cover'
                    sizes='598px'
                  />
                </picture>
              </div>
              <noscript>
                <div
                  className={styles.collectionItemImage}
                  style={{
                    backgroundPosition: 'center center',
                    backgroundImage: `url(${shopifyCdnImage(
                      'GEN4_WHOOP-Shopify-1120x1200_BANDS-Homepage-3Columns1_1000x.png',
                    )})`,
                  }}
                ></div>
              </noscript>
            </div>

            <div className={styles.collectionItemContent}>
              <header>
                <h2>{t('bands')}</h2>

                <div>
                  <Button
                    label={t('shopBands')}
                    variant={ButtonVariants.NORMAL}
                    ref={$button1}
                    data-event={EVENT_NAME}
                    data-event-landing_page='/collections/band'
                    data-event-source={EVENT_SOURCE_BTN}
                  />
                </div>
              </header>
            </div>
          </div>
        </Link>
        <Link
          to='/collections/whoopbody'
          rel='noopener noreferrer'
          className={classes(
            styles.collectionItem,
            styles.collectionItemExpand,
          )}
          onMouseEnter={() => {
            $button2.current?.focus({ preventScroll: true });
          }}
          onMouseLeave={() => {
            $button2.current?.blur();
          }}
          data-event={EVENT_NAME}
          data-event-landing_page='/collections/whoopbody'
          data-event-source={EVENT_SOURCE_IMG}
          data-impress='Viewed Main Categories'
        >
          <div
            className={styles.collectionItemWrapper}
            style={{
              backgroundImage: `url(${shopifyCdnImage(
                'GEN4_WHOOP-Shopify-1120x1200_APPAREL-Homepage-3Columns0_1x1.png',
              )})`,
            }}
          >
            <div className={styles.collectionItemImageWrapper}>
              <div
                className={styles.collectionItemImage}
                style={{
                  backgroundPosition: 'center center',
                  backgroundImage: `url(${shopifyCdnImage(
                    'GEN4_WHOOP-Shopify-1120x1200_APPAREL-Homepage-3Columns0_750x960_crop_center.png',
                  )})`,
                }}
                data-optimumx='1.4'
                data-expand='-150'
                data-bgset={`${shopifyCdnImage(
                  'GEN4_WHOOP-Shopify-1120x1200_APPAREL-Homepage-3Columns0_750x960_crop_center.png',
                )} 750w, ${shopifyCdnImage(
                  'GEN4_WHOOP-Shopify-1120x1200_APPAREL-Homepage-3Columns0_1000x.png',
                )} 1000w, ${shopifyCdnImage(
                  'GEN4_WHOOP-Shopify-1120x1200_APPAREL-Homepage-3Columns0_1500x.png',
                )} 1500w`}
              >
                <picture style={{ display: 'none' }}>
                  <source
                    data-srcset={`${shopifyCdnImage(
                      'GEN4_WHOOP-Shopify-1120x1200_APPAREL-Homepage-3Columns0_750x960_crop_center.png',
                    )} 750w, ${shopifyCdnImage(
                      'GEN4_WHOOP-Shopify-1120x1200_APPAREL-Homepage-3Columns0_1000x.png',
                    )} 1000w, ${shopifyCdnImage(
                      'GEN4_WHOOP-Shopify-1120x1200_APPAREL-Homepage-3Columns0_1500x.png',
                    )} 1500w`}
                    sizes='598px'
                    srcSet={`${shopifyCdnImage(
                      'GEN4_WHOOP-Shopify-1120x1200_APPAREL-Homepage-3Columns0_750x960_crop_center.png',
                    )} 750w, ${shopifyCdnImage(
                      'GEN4_WHOOP-Shopify-1120x1200_APPAREL-Homepage-3Columns0_1000x.png',
                    )} 1000w, ${shopifyCdnImage(
                      'GEN4_WHOOP-Shopify-1120x1200_APPAREL-Homepage-3Columns0_1500x.png',
                    )} 1500w`}
                  />
                  <img
                    alt=''
                    className='lazyautosizes Image--lazyLoaded'
                    data-sizes='auto'
                    data-optimumx='1.4'
                    data-parent-fit='cover'
                    sizes='598px'
                  />
                </picture>
              </div>
              <noscript>
                <div
                  className={styles.collectionItemImage}
                  style={{
                    backgroundPosition: 'center center',
                    backgroundImage: `url(${shopifyCdnImage(
                      'GEN4_WHOOP-Shopify-1120x1200_APPAREL-Homepage-3Columns0_1000x.png',
                    )})`,
                  }}
                ></div>
              </noscript>
            </div>

            <div className={styles.collectionItemContent}>
              <header>
                <h2>{t('body')}</h2>

                <div>
                  <Button
                    label={t('shopBody')}
                    variant={ButtonVariants.NORMAL}
                    ref={$button2}
                    data-event={EVENT_NAME}
                    data-event-landing_page='/collections/whoopbody'
                    data-event-source={EVENT_SOURCE_BTN}
                  />
                </div>
              </header>
            </div>
          </div>
        </Link>
        <Link
          to='/collections/batteries'
          rel='noopener noreferrer'
          className={classes(
            styles.collectionItem,
            styles.collectionItemExpand,
          )}
          onMouseEnter={() => {
            $button3.current?.focus({ preventScroll: true });
          }}
          onMouseLeave={() => {
            $button3.current?.blur();
          }}
          data-event={EVENT_NAME}
          data-event-landing_page='/collections/batteries'
          data-event-source={EVENT_SOURCE_IMG}
          data-impress='Viewed Main Categories'
        >
          <div
            className={styles.collectionItemWrapper}
            style={{
              backgroundImage: `url(${shopifyCdnImage(
                'GEN4_WHOOP-Shopify-1120x1200_BEST-SELLERS-Homepage-3Columns2.png',
              )})`,
            }}
          >
            <div className={styles.collectionItemImageWrapper}>
              <div
                className={classes(
                  styles.collectionItemImage,
                  styles.imageContrast,
                )}
                style={{
                  backgroundPosition: 'center center',
                  backgroundImage: `url(${shopifyCdnImage(
                    'GEN4_WHOOP-Shopify-1120x1200_BEST-SELLERS-Homepage-3Columns2.png',
                  )})`,
                }}
                data-optimumx='1.4'
                data-expand='-150'
                data-bgset={`${shopifyCdnImage(
                  'GEN4_WHOOP-Shopify-1120x1200_BANDS-Homepage-3Columns5_750x960_crop_center.png',
                )} 750w, ${shopifyCdnImage(
                  'GEN4_WHOOP-Shopify-1120x1200_BANDS-Homepage-3Columns5_1000x.png',
                )} 1000w, ${shopifyCdnImage(
                  'GEN4_WHOOP-Shopify-1120x1200_BANDS-Homepage-3Columns5_1500x.png',
                )} 1500w`}
              />
              <picture style={{ display: 'none' }}>
                <source
                  data-srcset={`${shopifyCdnImage(
                    'GEN4_WHOOP-Shopify-1120x1200_BANDS-Homepage-3Columns5_750x960_crop_center.png',
                  )} 750w, ${shopifyCdnImage(
                    'GEN4_WHOOP-Shopify-1120x1200_BANDS-Homepage-3Columns5_1000x.png',
                  )} 1000w, ${shopifyCdnImage(
                    'GEN4_WHOOP-Shopify-1120x1200_BANDS-Homepage-3Columns5_1500x.png',
                  )} 1500w`}
                  sizes='1053px'
                  srcSet={`${shopifyCdnImage(
                    'GEN4_WHOOP-Shopify-1120x1200_BANDS-Homepage-3Columns5_750x960_crop_center.png',
                  )} 750w, ${shopifyCdnImage(
                    'GEN4_WHOOP-Shopify-1120x1200_BANDS-Homepage-3Columns5_1000x.png',
                  )} 1000w, ${shopifyCdnImage(
                    'GEN4_WHOOP-Shopify-1120x1200_BANDS-Homepage-3Columns5_1500x.png',
                  )} 1500w`}
                />
                <img
                  alt=''
                  className='lazyautosizes Image--lazyLoaded'
                  data-sizes='auto'
                  data-optimumx='1.4'
                  data-parent-fit='cover'
                  sizes='1053px'
                />
              </picture>
            </div>
            <noscript>
              <div
                className={classes(
                  styles.collectionItemImage,
                  styles.imageContrast,
                )}
                style={{
                  backgroundPosition: 'center center',
                  backgroundImage: `url(${shopifyCdnImage(
                    'GEN4_WHOOP-Shopify-1120x1200_BEST-SELLERS-Homepage-3Columns2.png',
                  )})`,
                }}
              />
            </noscript>

            <div className={styles.collectionItemContent}>
              <header>
                <h2>{t('battery')}</h2>

                <div>
                  <Button
                    label={t('shopBatteries')}
                    variant={ButtonVariants.NORMAL}
                    ref={$button3}
                    data-event={EVENT_NAME}
                    data-event-landing_page='/collections/batteries'
                    data-event-source={EVENT_SOURCE_BTN}
                  />
                </div>
              </header>
            </div>
          </div>
        </Link>
      </div>
    </section>
  );
}
