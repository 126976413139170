import React from 'react';
import '../../index.module.scss';
import styles from './status-tag.module.scss';
import { c } from '../../utils';
import { Statuses } from '../../types/Statuses';
import { StatusTagThemes } from '../../types/StatusTagThemes';
import { BasicComponentProps } from '../../types';
import { capitalize } from '../../../../utils';

export type StatusTagProps = {
  color: Statuses;
  theme?: StatusTagThemes;
} & BasicComponentProps;

const defaultTheme = StatusTagThemes.DEFAULT;

export const StatusTag = ({
  color,
  children,
  theme = defaultTheme,
  className,
  ...props
}: StatusTagProps) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const backgroundColorStyle = styles[`backgroundColor${capitalize(color)}`];
  return (
    <div
      className={c(
        styles.statusTag,
        backgroundColorStyle,
        `theme-${theme}`,
        className,
      )}
      {...props}
    >
      <div className={c(styles.textFormatting, `theme-${theme}`)}>
        {children}
      </div>
    </div>
  );
};
