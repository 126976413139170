import React from 'react';
import PropTypes from 'prop-types';
import { RadioButtons } from '../../RadioSelectors/RadioButtons/RadioButtons';

/**
 * @deprecated Use RadioButtons component instead
 */
export const ButtonGroup = ({
  value,
  options,
  size = 'medium',
  onChange,
  className,
  selectedClassName,
  groupClassName,
  ...props
}) => {
  return (
    <RadioButtons
      className={className}
      onChange={onChange}
      options={options}
      size={size}
      value={value}
      {...props}
    />
  );
};

ButtonGroup.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  selectedClassName: PropTypes.string,
  groupClassName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
