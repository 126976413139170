import Filter from 'bad-words';

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

// Alpha-numeric, at least 8 characters
export const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/i;

/**
 * Validates that an email is valid
 * @param {string} email string of the email to validate
 */
export const validateEmail = (email) => {
  return emailRegex.test(String(email).toLowerCase());
};

const ProfanityFilter = new Filter({
  exclude: ['cox', 'damn', 'dick', 'fanny', 'god', 'hell', 'wang', 'willy'],
});

/**
 * Determines if the provided text contains profanity
 * @param {string} text
 * @returns
 */
export const hasProfanity = (text) => {
  return ProfanityFilter.isProfane(text);
};
