import styles from './buy-box.module.scss';
import {
  getMediaFromSelection,
  NumberInput,
  ProductNode,
} from '@whoop/web-components';
import { noop } from 'lodash';
import { isApplePay } from '../../utils/device-detection';
import IconButton from '../IconButton';
import React, { useState } from 'react';
import {
  useCheckout,
  useHasProBenefits,
  useIsCartMutating,
  useIsEligibleForProCheckout,
  useIsProStatusLoaded,
  useProCtaButtonContent,
  useProMonthlyPrice,
  useUser,
} from '../../redux/hooks';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { goToCheckout } from '../../utils/shopify';
import { CHECKOUT_FROM_BUY_IT_NOW } from '../../utils/analytics';
import {
  useAddProToCart,
  useJoinWhoopPro,
  useToggleCart,
  useToggleProModal,
  useToggleUpsellModal,
} from '../../redux/action-hooks';
import { navigate } from 'gatsby';
import EmailMeWhen from '../EmailMeWhen';
import {
  Purchasable,
  useAddToCart,
  useItemSwap,
  usePurchasableMaxQuantity,
} from '../../utils/purchasableUtils';
import { Optional } from '@whoop/web-components/dist/types';
import useShopifyCheckoutItemMap from '../../hooks/useShopifyCheckoutItemMap';
import {
  isProductItemExclusive,
  ProductItemKey,
} from '../../utils/productUtils';
import { useShopifyProductData } from '../../utils/shopifyProductUtils';
import { usePriceTag } from '../../utils/priceUtils';
import { Button, ButtonSizes, ButtonVariants } from '../../libs/web-components';

export interface BuyBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  purchasable?: Optional<Purchasable>;
  showEmailMeWhen?: boolean;
  includeBuyItNow?: boolean;
  withItemSwap?: boolean;
  node?: ProductNode;
}

export default function BuyBox({
  purchasable,
  showEmailMeWhen,
  includeBuyItNow = true,
  withItemSwap = false,
  node,
  ...props
}: BuyBoxProps): JSX.Element {
  const { t } = useTranslation('product');
  const [quantity, setQuantity] = useState(1);
  const isMutating = useIsCartMutating();
  const isWhoopPro = useHasProBenefits();
  const item = purchasable?.item;
  const maxQuantity = usePurchasableMaxQuantity(purchasable);
  const isMemberOnlyProduct = isProductItemExclusive(
    purchasable,
    ProductItemKey.member_only,
  );
  const isGen4Strap = isProductItemExclusive(purchasable, ProductItemKey.gen4);
  const isGen3Strap = isProductItemExclusive(purchasable, ProductItemKey.gen3);
  const isProStatusIndeterminate = !useIsProStatusLoaded();
  const isQuantityIndeterminate = maxQuantity === undefined;
  const user = useUser();
  const proCtaButtonContent = useProCtaButtonContent();
  const proMonthlyPrice = useProMonthlyPrice();
  const isExclusive = isProductItemExclusive(
    purchasable,
    ProductItemKey.pro_exclusive,
  );
  const notInStock = !isQuantityIndeterminate && (maxQuantity || 0) <= 0; // negate it so the default is NOT sold out
  const toggleModal = useToggleProModal('exclusive-product');
  const doAddToCart = useAddToCart(purchasable, quantity);
  const doItemSwap = useItemSwap(purchasable, quantity);
  const addToCart =
    // Only use the item swap when the property is true AND the purchasable item is a pack
    withItemSwap && purchasable && 'packItems' in purchasable
      ? doItemSwap
      : doAddToCart;
  const addProToCart = useAddProToCart();
  const joinWhoopPro = useJoinWhoopPro();
  const isEligibleForProCheckout = useIsEligibleForProCheckout();
  const checkout = useCheckout();
  const cartItemsBySku = useShopifyCheckoutItemMap(checkout);
  const toggleCart = useToggleCart();
  const toggleUpsellModal = useToggleUpsellModal();
  const isValidEngraving =
    purchasable?.type !== 'engraved' || purchasable?.engraving;
  const sameGenDevice =
    isMemberOnlyProduct && user // disable the buttons if the logged in user does not have the same strap as the PDP device gen
      ? (isGen4Strap && user?.strap === 'harvard') ||
        (isGen3Strap && user?.strap === 'copley')
      : true;
  const canBuy = // disabled the buttons if the state isn't ready
    !isProStatusIndeterminate &&
    !isQuantityIndeterminate &&
    !isMutating &&
    quantity <= (maxQuantity || 0) &&
    quantity > 0 &&
    isValidEngraving &&
    sameGenDevice;
  const { variant, tags } = useShopifyProductData(item?.sku);
  const priceTag = usePriceTag(tags, variant, purchasable);
  const media = getMediaFromSelection(item!, node!);
  const isUserLoggedInWithMemberOnlyProd = isMemberOnlyProduct && user;
  const handleBuyItNow = () =>
    addToCart()
      .then(() =>
        goToCheckout(checkout, cartItemsBySku, CHECKOUT_FROM_BUY_IT_NOW),
      )
      .catch(noop);

  const handleAddToCart = () => {
    addToCart().then(toggleCart).catch(noop);
    if (withItemSwap) {
      toggleUpsellModal();
    }
  };
  const goToLogin = () => {
    if (isMemberOnlyProduct) {
      navigate(
        `/account/login?alert=replacement-device&redirect=${window.location.href}`,
      );
      return;
    }
    if (withItemSwap) {
      toggleUpsellModal();
    }
    navigate(`/account/login?alert=whoop-pro&redirect=${window.location.href}`);
  };
  const addWithWhoopPro = () =>
    addProToCart().then(handleAddToCart).catch(noop);

  let proButtonProps;
  if (user) {
    if (isEligibleForProCheckout) {
      proButtonProps = {
        label: t('addWithWhoopPro'),
        onClick: addWithWhoopPro,
        'data-event': 'Add Pro to Cart',
        'data-event-source': 'exclusive-product',
      };
    } else {
      proButtonProps = {
        label: proCtaButtonContent,
        onClick: () => joinWhoopPro('exclusive-product'),
      };
    }
  } else {
    proButtonProps = {
      label: t('signIn'),
      onClick: goToLogin,
      'data-event': 'Sent to Login',
      'data-event-reason': 'exclusive-product',
    };
  }
  let learnMoreContainer;
  if (isMemberOnlyProduct && !user) {
    learnMoreContainer = (
      <div className={styles.learnMoreContainer}>
        <a className={styles.learnMoreAnchor} onClick={goToLogin}>
          {t('signInForReplacementDevice')}
        </a>
      </div>
    );
  } else {
    learnMoreContainer = (
      <div className={styles.learnMoreContainer}>
        <a className={styles.learnMoreAnchor} onClick={toggleModal}>
          {t('learnAboutJoiningWP', {
            price: proMonthlyPrice,
          })}
        </a>
      </div>
    );
  }

  let strapInfoContainer;
  if (!sameGenDevice && isUserLoggedInWithMemberOnlyProd) {
    if (isGen3Strap) {
      strapInfoContainer = (
        <div
          className={styles.strapInfoContainer}
          dangerouslySetInnerHTML={{ __html: t('gen3Strap') }}
        ></div>
      );
    } else if (isGen4Strap) {
      strapInfoContainer = (
        <div className={styles.strapInfoContainer}>{t('gen4Strap')}</div>
      );
    }
  }

  return (
    <div {...props}>
      {!notInStock && (
        <>
          <div className={styles.quantityTitle}>{t('quantity')}</div>
          <NumberInput
            className={styles.quantitySelector}
            min={!notInStock ? 1 : 0}
            max={maxQuantity}
            disabled={isQuantityIndeterminate}
            value={quantity}
            onChange={setQuantity}
            data-test-id='quantity-select'
          />
        </>
      )}
      <div className={styles.formButtonContainer}>
        {!notInStock && (
          <>
            {(((isProStatusIndeterminate || !isExclusive || isWhoopPro) &&
              !isMemberOnlyProduct) ||
              isUserLoggedInWithMemberOnlyProd) && (
              <>
                <Button
                  label={withItemSwap ? t('swapItem') : t('addToCart')}
                  variant={ButtonVariants.PRIMARY}
                  disabled={!canBuy}
                  onClick={handleAddToCart}
                  size={ButtonSizes.MEDIUM}
                  type='submit'
                  data-test-id={withItemSwap ? 'swap-cart' : 'add-to-cart'}
                />
                {includeBuyItNow && (
                  <Button
                    label={t('buyItNow')}
                    variant={ButtonVariants.NORMAL}
                    disabled={!canBuy}
                    onClick={handleBuyItNow}
                    data-test-id='buy-now'
                  />
                )}
                {includeBuyItNow && (
                  <div className={styles.buyButtons}>
                    {isApplePay && (
                      <IconButton
                        icon='apple_pay'
                        aria-label={t('aria.applePay')}
                        disabled={!canBuy}
                        onClick={handleBuyItNow}
                      />
                    )}
                    <IconButton
                      icon='shop_pay'
                      aria-label={t('aria.shopPay')}
                      disabled={!canBuy}
                      onClick={handleBuyItNow}
                    />
                  </div>
                )}
                {strapInfoContainer}
              </>
            )}
            {((!isProStatusIndeterminate && isExclusive && !isWhoopPro) ||
              (isMemberOnlyProduct && !user)) && (
              <>
                <Button
                  variant={ButtonVariants.PRIMARY}
                  disabled={!canBuy}
                  size={ButtonSizes.MEDIUM}
                  type='submit'
                  className={styles.proButton}
                  data-test-id={withItemSwap ? 'swap-cart' : 'add-to-cart'}
                  {...proButtonProps}
                />
                {learnMoreContainer}
              </>
            )}
          </>
        )}
        {!isProStatusIndeterminate && notInStock && !showEmailMeWhen && (
          <Button
            label={t('soldOut')}
            variant={ButtonVariants.NORMAL}
            disabled={true}
            data-test-id={withItemSwap ? 'swap-cart' : 'add-to-cart'}
          />
        )}
        {!isProStatusIndeterminate && notInStock && showEmailMeWhen && (
          <EmailMeWhen
            outOfStock={notInStock}
            isComingSoon={!!item?.coming_soon}
            productTitle={node?.product_info.title || 'unknown'}
            itemTitle={item?.title}
            sku={item?.sku}
            price={priceTag}
            imageUrl={media?.[0]?.url}
            handle={node?.product_info?.handle}
            data-test-id='email-me-when'
          />
        )}
      </div>
    </div>
  );
}
