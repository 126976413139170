import React, { RefObject } from 'react';
import { WhoopYourWaySelection } from '../types/Products';
import {
  Currency,
  formatPrice as _formatPrice,
  Language,
  DEFAULT_LANGUAGE,
  DEFAULT_CURRENCY,
} from '@whoop/i18n';

// source: https://stackoverflow.com/questions/32673760/how-can-i-know-if-a-given-string-is-hex-rgb-rgba-or-hsl-color-using-javascript
const COLOR_REGEX =
  /^(?:#(?:[A-Fa-f0-9]{3}){1,2}|(?:rgb[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*(?:,(?![)])|(?=[)]))){3}|hsl[(]\s*0*(?:[12]?\d{1,2}|3(?:[0-5]\d|60))\s*(?:\s*,\s*0*(?:\d\d?(?:\.\d+)?\s*%|\.\d+\s*%|100(?:\.0*)?\s*%)){2}\s*|(?:rgba[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*,){3}|hsla[(]\s*0*(?:[12]?\d{1,2}|3(?:[0-5]\d|60))\s*(?:\s*,\s*0*(?:\d\d?(?:\.\d+)?\s*%|\.\d+\s*%|100(?:\.0*)?\s*%)){2}\s*,)\s*0*(?:\.\d+|1(?:\.0*)?)\s*)[)])$/;

/**
 * Uses regex to validate if string is a valid css color string
 * @param color any string
 */
export const isValidColorString = function (color: string) {
  return COLOR_REGEX.exec(color);
};

/**
 * Converts a camel case string into a different format given an insert string.
 * @param text to convert
 * @param insert string to insert in between words. Default is '-'
 */
export const convertFromCamelCase = function (text: string, insert = '-') {
  return text.replace(/([a-z])([A-Z0-9])/g, `$1${insert}$2`);
};

/**
 * Formats a string to a handleized string
 * @param {} value
 * @returns
 */
export const handleize = function (value: string) {
  return value
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/-$/, '')
    .replace(/^-/, '');
};

/**
 * Generates a class string based on an object or an array.
 * If an object is given the keys will be the class names if
 *    the values are truthy.
 * If an array is given it will filter out falsy values
 *    and join the class names.
 *
 * @param arr array or object
 */
export const classes = (...arr: Array<string | undefined | false>) =>
  arr.filter((c) => c).join(' ');
export const c = classes;

/**
 * Formats a price according to currency
 * @param price a valid number
 * @param currency currency code
 * @param language
 * @param removeDecimals whether to remove decimals from the currency
 */
export const formatPrice = (
  price: number | string, // Some instances pass in a string unfortunately
  currency: string = DEFAULT_CURRENCY,
  language: string = DEFAULT_LANGUAGE,
  removeDecimals = true,
): string => {
  currency = currency.toLowerCase();
  const float = parseFloat(String(price) || '0');
  if (isNaN(float)) {
    return (price || '') as string; // we don't format strings or other NaNs
  }
  if (isNaN(Number(price))) {
    return '';
  }

  return _formatPrice(float, currency as Currency, {
    language: language as Language,
    inCents: false,
    showCents: !removeDecimals,
  });
};

export const getDateFormatForRegion = (region: string) => {
  if (region === 'ca') return 'y/MM/dd';
  if (region === 'domestic') return 'MM/dd/y';
  return 'dd/MM/y';
};

/* eslint-disable no-undef */

/**
 * Preload an image given a URL
 */
export function preloadImage(url: string) {
  if (Image && url && typeof url === 'string') {
    new Image().src = url;
  }
}

/* eslint-enable no-undef */

/**
 * Pick a random value from an array
 */
export function pickRandomItem(array: Array<any>) {
  if (array && array.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return array[Math.floor(Math.random() * array.length)];
  }
}

/**
 * Builds a wyw composite SKU
 * @param wywSelection
 */

export function buildWywCompositeSku(wywSelection: WhoopYourWaySelection) {
  const BBB = wywSelection?.band?.code;
  const HHH = wywSelection?.hook?.code;
  const CCC = wywSelection?.clasp?.code;
  const SSS = wywSelection?.slider?.code;
  return `956-${BBB}-${HHH}-${CCC}-${SSS}`;
}

/**
 * takes in n refs and returns a single callback ref
 * @param refs
 * @returns {(function(...[*]=))|null|*}
 */
type ReactRefFn = (ref: RefObject<unknown>) => void;
type ReactRefObj = { current: React.ReactNode };
type IReactRef = ReactRefFn | ReactRefObj;

export const mergeRefs = (...refs: IReactRef[]) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return (inst: RefObject<unknown>) => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};

/**
 * returns a copy of the passed-in object that's composed of the object properties the predicate returns true for
 * @param object
 * @params predicate
 */
interface IPickBy {
  [key: string]: any;
}
export const pickBy = (
  object: IPickBy,
  predicate: (value: any, key: string) => boolean,
): Partial<IPickBy> => {
  const obj: IPickBy = {};
  const entries = Object.entries(object);
  for (const [key, value] of entries) {
    if (object[key] && predicate(value, key)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      obj[key] = object[key];
    }
  }
  return obj;
};

/**
 * returns an object with the following date values:
 * - minDate: the start of today plus the given addedDaysToMinDate input
 * - maxDate: the start of the day one year from now
 * both outputs are based on the current timezone.
 * @param addedDaysToMinDate
 */
export const getTodayAndYearFromToday = (addedDaysToMinDate: number) => {
  const dateObj = new Date();
  dateObj.setHours(0, 0, 0, 0);
  dateObj.setDate(dateObj.getDate() + addedDaysToMinDate);
  const minDate = dateObj.toISOString();
  dateObj.setFullYear(dateObj.getFullYear() + 1);
  dateObj.setDate(dateObj.getDate() - 1);
  const maxDate = dateObj.toISOString();
  return { minDate, maxDate };
};

export * from './urlUtils';
