/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect, useState } from 'react';
import styles from './banner.module.scss';
import { useShowFreeShipping, useUsersBillingRegion } from '../../redux/hooks';
import { Icon } from '@whoop/web-components';
import { classes } from '../../utils';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import useCountryCode from '../../hooks/useCountryCode';
import { useIsOnForAll } from '../../utils/feature-flags';
import { useQueryParam } from '../../utils/queryParamUtils';
import { Optional } from '@whoop/web-components/dist/types';
import { FREE_SHIPPING_THRESHOLD, WHOOP_URL } from '../../utils/regions';
import { formatPrice } from '../../utils/priceUtils';

function hasEligibleCountry({
  country,
  excludes,
}: {
  country: string;
  excludes: string[];
}) {
  if (country === '') {
    return false;
  }
  return !excludes.includes(country);
}

function useIsInApp() {
  const [isInApp, setIsInApp] = useState(false);
  const [utmCampaign] = useQueryParam('utm_campaign', '');

  // must be behind a useEffect to avoid hydration issues
  useEffect(() => {
    setIsInApp(utmCampaign === 'ios_link');
  }, [utmCampaign]);

  return isInApp;
}

function BannerWithBackButton({
  children,
  className,
}: {
  children: React.ReactNode;
  className: string;
}) {
  const { t } = useTranslation('banner');
  const isInApp = useIsInApp();

  if (isInApp) {
    return <div className={classes(styles.banner, 'no-color')}>{children}</div>;
  }
  return (
    <div
      className={classes(
        styles.banner,
        styles.additionalInfo,
        className,
        'no-color',
      )}
    >
      <a
        className={classes(styles.banner, styles.leftBannerElement, 'no-color')}
        href={'https://whoop.com/'}
        data-event='Clicked on "<- WHOOP.COM"'
        aria-label={t('aria.backToWhoop')}
        data-event-source='banner'
      >
        <Icon name='arrow_left' />
        {t('whoopCom')}
      </a>
      {children}
      <div className={styles.rightBannerSpacer}>
        <Icon name='arrow_left' />
        <span>{t('whoopCom')}</span>
      </div>
    </div>
  );
}

export function BannerLayout({
  theme = 'default',
  children,
}: {
  theme?: 'cyberSale' | 'default';
  children: React.ReactNode;
}): JSX.Element {
  const wasReferredFromWhoopCom = (() => {
    if (typeof document !== 'undefined' && document?.referrer) {
      const referrer = new URL(document.referrer);
      return referrer.hostname === 'www.whoop.com';
    }
    return false;
  })();

  const [hasClientLoaded, setHasClientLoaded] = useState(false);
  useEffect(() => {
    setHasClientLoaded(true);
  }, []);

  let classNames = styles.banner;
  if (theme === 'cyberSale') {
    classNames = classes(classNames, styles.cyberSale2024);
  }

  if (hasClientLoaded && wasReferredFromWhoopCom) {
    return (
      <BannerWithBackButton className={classNames}>
        {children}
      </BannerWithBackButton>
    );
  }
  return <div className={classNames}>{children}</div>;
}

function NZBanner(): JSX.Element {
  const { t } = useTranslation('banner');
  return (
    <BannerLayout>
      <span
        className={classes(styles.banner, 'no-color')}
        data-test-id='nz-banner-combo'
        aria-label={t('aria.allPricesInAUD')}
      >
        {t('allPricesInAUD')}
      </span>
    </BannerLayout>
  );
}

function CyberSale2024Banner(): JSX.Element {
  const { t } = useTranslation('banner');
  return (
    <BannerLayout theme='cyberSale'>
      <span className={classes(styles.banner)}>
        {t('cyberSale2024')}
        <a className='no-color' href='/collections/sale'>
          {t('shopNow')}
        </a>
      </span>
    </BannerLayout>
  );
}

function FreeShippingBanner(): JSX.Element {
  const { t } = useTranslation('banner');
  const price = formatPrice(FREE_SHIPPING_THRESHOLD);
  return (
    <BannerLayout>
      <span
        className={classes(styles.banner, 'no-color')}
        data-test-id='free-shipping'
        aria-label={t('aria.freeShipping', { price: price })}
      >
        {t('freeShipping', { price: price })}
      </span>
    </BannerLayout>
  );
}

function RedirectFromCABanner(): JSX.Element {
  const { t } = useTranslation('banner');
  const ctaLink = WHOOP_URL;
  return (
    <BannerLayout>
      <a
        className={classes(styles.banner, 'no-color')}
        href={ctaLink}
        data-event='Clicked on Back to WHOOP.COM'
        data-event-source='banner'
      >
        {t('redirectFromCA')}
      </a>
    </BannerLayout>
  );
}

export function ShippingDelayBanner(): JSX.Element {
  const { t } = useTranslation('banner');
  const isErpShippingDelayOn = useIsOnForAll('storefront-erp-shipping-delays');
  return isErpShippingDelayOn ? (
    <BannerLayout>
      <span
        className={classes(styles.banner, styles.shippingDelay, 'no-color')}
        data-test-id='shipping-delay'
        aria-label={t('aria.erpShippingDelay')}
      >
        {t('erpShippingDelay')}
      </span>
    </BannerLayout>
  ) : (
    <></>
  );
}

const WithingsScaleBanner = () => {
  const { t } = useTranslation('banner');
  return (
    <BannerLayout>
      <span
        className={classes(styles.banner, 'no-color')}
        data-test-id='withings-scale-banner'
        dangerouslySetInnerHTML={{ __html: t('withingsScalePromo') }}
      />
    </BannerLayout>
  );
};

const SwapComponents = ({
  mode = 'rotate',
  swapDelay,
  firstComponent,
  secondComponent,
}: {
  mode: 'rotate' | 'once';
  swapDelay: number;
  firstComponent: React.FC;
  secondComponent: React.FC;
}): React.ReactElement => {
  const setTimerFn = mode === 'rotate' ? setInterval : setTimeout;
  const clearTimerFn = mode === 'rotate' ? clearInterval : clearTimeout;
  const [hasSwapped, setHasSwapped] = useState(false);
  useEffect(() => {
    const timeout = setTimerFn(() => {
      setHasSwapped((prev) => !prev);
    }, swapDelay);
    return () => clearTimerFn(timeout);
  }, [swapDelay]);
  const Component = hasSwapped ? secondComponent : firstComponent;
  return <Component />;
};

const DefaultBanner = () => {
  return (
    <BannerLayout>
      <span className={classes(styles.banner, 'no-color')}></span>
    </BannerLayout>
  );
};

export default function Banner(): Optional<JSX.Element> {
  const billingRegion = useUsersBillingRegion();
  const userCountry = useCountryCode();
  const billingNZShippingAU =
    (userCountry === 'NZ' || billingRegion === 'nz') &&
    (process.env.SHOP_REGION === 'AU' || process.env.ENV === 'DEV');
  const showFreeShipping = useShowFreeShipping();

  // For CA redirect - CA Warehouse Shutdown
  // Add feature flag to show banner
  const [redirectFrom] = useQueryParam('redirectFrom', '');
  const wasRedirectedFromCA = redirectFrom === 'ca';
  const showRedirectFromCA = useIsOnForAll('storefront-redirect-ca-to-us');
  const showWithingsScaleBanner =
    useIsOnForAll('storefront-show-withings-scale-banner') &&
    hasEligibleCountry({
      country: userCountry || '',
      excludes: ['HK', 'AE', 'IN', 'AU', 'JP'],
    });

  const showCyberSale2024 = useIsOnForAll('storefront-show-cyber-sale-2024');

  if (showRedirectFromCA && wasRedirectedFromCA) {
    return <RedirectFromCABanner />;
  }

  if (showCyberSale2024) {
    return <CyberSale2024Banner />;
  }

  if (showWithingsScaleBanner) {
    return (
      <>
        <SwapComponents
          mode='rotate'
          swapDelay={7000}
          firstComponent={FreeShippingBanner}
          secondComponent={WithingsScaleBanner}
        />
      </>
    );
  }

  if (billingNZShippingAU) {
    return <NZBanner />;
  }

  if (showCyberSale2024) {
    return <CyberSale2024Banner />;
  }
  if (showFreeShipping) {
    return <FreeShippingBanner />;
  }

  return <DefaultBanner />;
}
