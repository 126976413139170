import React from 'react';
import PropTypes from 'prop-types';
import '../../index.module.scss';
import styles from './date-picker.module.scss';
import ReactDatePicker from 'react-date-picker';
import { c, getDateFormatForRegion } from '../../utils';
import AnnualPaIcon from '../../icons/Navigation/ic_annual_pa.svg';

export const DatePicker = ({
  className,
  minDate,
  maxDate,
  region = 'domestic',
  value,
  onChange,
  ...props
}) => {
  const handleDateChange = (dateValue) => {
    const jsonDate = dateValue ? dateValue.toJSON() : null;
    onChange(jsonDate);
  };

  return (
    <div className={c(styles.reactDatePicker, className)} {...props}>
      <ReactDatePicker
        calendarIcon={<AnnualPaIcon />}
        clearIcon={null}
        format={getDateFormatForRegion(region)}
        minDate={minDate ? new Date(minDate) : null}
        minDetail='month'
        maxDate={maxDate ? new Date(maxDate) : null}
        maxDetail='month'
        value={value ? new Date(value) : null}
        onChange={handleDateChange}
      />
    </div>
  );
};

DatePicker.propTypes = {
  className: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  region: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
