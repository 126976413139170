import React from 'react';
import styles from '../headerV2.module.scss';
import { classes, clickNavigate, openJoin } from '../../../utils';
import PropTypes from 'prop-types';
import SignInForm from '../../SignInForm';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { getSeperatedGroups } from '../../../utils/navigationUtils';
import { Button, ButtonVariants } from '../../../libs/web-components';

/**
 * Sign in hint that shows temporarily at start.
 * @param show whether to show this menu or not
 * @param onSignInClick callback when sign in button is clicked
 * @param props additional props passed to parent div
 */
export const SignInHint = ({ show, onSignInClick, ...props }) => {
  const { t } = useTranslation('header');
  return (
    <div className={classes(styles.signInHint, show && styles.show)} {...props}>
      <div>
        <Button
          label={t('signIn')}
          variant={ButtonVariants.PRIMARY}
          size='small'
          onClick={onSignInClick}
        />
        <Button
          label={t('joinWhoop')}
          variant='secondary'
          size='small'
          onClick={openJoin}
        />
      </div>
    </div>
  );
};
SignInHint.propTypes = {
  show: PropTypes.bool,
  onSignInClick: PropTypes.func,
};

/**
 * Sign in menu
 * @param show whether to show this menu or not
 */
export const SignInMenu = ({ show, ...props }) => {
  return (
    <div
      className={classes(
        styles.signInPanel,
        styles.dropdownPanel,
        styles.clickToOpen,
        show && styles.show,
      )}
      {...props}
    >
      {show && <SignInForm />}
    </div>
  );
};
SignInMenu.propTypes = {
  show: PropTypes.bool,
};

export const UserMenu = ({ show, onLogoutClicked, ...props }) => {
  const { t } = useTranslation('header');
  return (
    <div
      className={classes(
        styles.signOutPanel,
        styles.dropdownPanel,
        styles.anchorRight,
        styles.clickToOpen,
        show && styles.show,
      )}
    >
      <Button
        label={t('signOut')}
        variant='secondary'
        size='small'
        onClick={onLogoutClicked}
      />
    </div>
  );
};
SignInMenu.propTypes = {
  show: PropTypes.bool,
  onLogoutClicked: PropTypes.func,
};

const Menu = ({ show, items, ...props }) => {
  const itemGroups = getSeperatedGroups(items);
  return (
    <div
      className={classes(
        styles.dropdownPanelNav,
        styles.dropdownPanel,
        styles.menu,
      )}
    >
      {itemGroups.map((item, i) => {
        return (
          <div
            key={i}
            className={classes(styles.subPanel, styles.navigationBorderItems)}
          >
            {item.map((subItem, index) => {
              return (
                <a
                  aria-label={subItem.label}
                  href={subItem.link}
                  key={`${subItem.label.trim()}-${index}`}
                  className={classes(
                    styles.item,
                    'no-color',
                    index === 0 && styles.subPanelHeader,
                  )}
                  onClick={(event) => {
                    event.preventDefault();
                    subItem.link && clickNavigate(subItem.link);
                  }}
                  data-event='Clicked Navigation'
                  data-event-name={subItem.label.trim()}
                  data-event-link={subItem.link}
                  data-event-navigation-level={index === 0 ? 2 : 3}
                  data-event-type='menu-item'
                  data-event-index={index === 0 ? i + 1 : index}
                  data-event-navigation-type={'Desktop'}
                >
                  {subItem.label.trim()}
                </a>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
Menu.propTypes = {
  show: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string,
    }),
  ),
};

export default Menu;
