// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { useSelector } from 'react-redux';
import LineItem from '../LineItem';
import styles from './cart.module.scss';
import { classes } from '../../utils';
import IconButton from '../IconButton';
import { goToCheckout } from '../../utils/shopify';
import { useReduxDispatch } from '../../redux/create-store';
import FreeRewardToggleV2 from '../FreeRewardToggleV2';
import { Store } from 'src/types';
import CartSubtotal from '../CartSubtotal';
import { toggleCart } from '../../redux/reducers/viewReducer';
import {
  removeLineItem,
  updateLineItem,
} from '../../redux/reducers/cartReducer';
import { useIsCartMutating, useLineItems } from '../../redux/hooks';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import CartUpsellV2 from '../CartUpsellV2';

import useShopifyCheckoutItemMap from '../../hooks/useShopifyCheckoutItemMap';
import { Button } from '../../libs/web-components';

const Cart = (): JSX.Element => {
  const { t } = useTranslation('cart');

  const dispatch = useReduxDispatch();
  const showCart = useSelector((state: Store) => state.showCart);
  const checkout = useSelector((state: Store) => state.checkout);
  const cartItemsBySku = useShopifyCheckoutItemMap(checkout);
  const dispatchToggleCart = () => dispatch(toggleCart());
  const isMutating = useIsCartMutating();
  const giftCards: string[] = [];
  const lineItems = useLineItems()?.map(
    ({
      item,
      isWyw,
      isExclusive,
      isProSubscription,
      alertLines,
      lineItemMessage,
    }) => {
      const onQuantityChange = (quantity: number) => {
        dispatch(updateLineItem({ lineItemId: item.id, quantity }));
      };
      const onRemove = () => {
        dispatch(removeLineItem({ lineItemId: item.id }));
      };

      return (
        <LineItem
          key={item.id}
          onQuantityChange={onQuantityChange}
          onRemove={onRemove}
          slim={true}
          disabled={isMutating}
          item={item}
          isWyw={isWyw}
          isExclusive={isExclusive}
          hideQuantity={isProSubscription}
          alertLines={alertLines}
          message={lineItemMessage}
        />
      );
    },
  );

  const isEmpty = checkout?.lineItems.length === 0;

  return (
    <div className={classes(styles.cart, showCart && styles.show)}>
      <div
        className='whoop-focus-background'
        data-showing={showCart}
        onClick={dispatchToggleCart}
      />
      <div
        className={styles.panel}
        role='region'
        aria-label={t('yourCart')}
        data-test-id='cart'
      >
        <div className={styles.header}>
          <h2>{t('yourCart')}</h2>
          <IconButton
            icon='cross'
            onClick={dispatchToggleCart}
            data-test-id='close'
          />
        </div>
        <div className={styles.itemsContainer} data-test-id='line-items'>
          {lineItems}
        </div>

        <div className={styles.footerV2}>
          <div className={styles.upsell}>
            <CartUpsellV2
              data-test-id='upsell'
              lineItems={checkout?.lineItems}
            />
          </div>
          <FreeRewardToggleV2
            data-test-id='free-reward'
            className={styles.upsell}
          />

          <div className={styles.subFooter}>
            <CartSubtotal data-test-id='subtotal' />
            <Button
              style={{ width: '100%' }}
              variant={isEmpty ? 'normal' : 'primary'}
              label={t('checkout')}
              disabled={isEmpty || isMutating}
              onClick={() => goToCheckout(checkout, cartItemsBySku, giftCards)}
              data-test-id='checkout'
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Cart;
