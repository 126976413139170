/* eslint-disable react/no-children-prop */

import React from 'react';
import Modal from '../../Modal/modal';
import styles from './FAQModal.module.scss';
import { c } from '../../../utils';
import { ModalThemes } from '../../../types/Modal';
import { Accordion } from '../../Accordion';
import { AccordionThemes } from '../../../types/Accordions';

export interface FAQ {
  title: string;
  content: React.ReactNode;
}
export interface FAQModalProps extends React.HTMLAttributes<HTMLDivElement> {
  show: boolean;
  title: string;
  FAQs: FAQ[];
  onClose: () => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function FAQModal({
  show,
  title,
  FAQs,
  onClose,
  className,
  ...props
}: FAQModalProps) {
  return (
    <Modal
      show={show}
      onClose={onClose}
      className={c(className)}
      theme={ModalThemes.JOIN_FLOW}
      children={
        <div className={styles.contentWrapper}>
          <h1 className={styles.title}>{title}</h1>
          <Accordion
            sections={FAQs.map((FAQ) => ({
              id: FAQ.title,
              title: FAQ.title,
              content: FAQ.content,
            }))}
            theme={AccordionThemes.JOIN_FLOW}
          />
        </div>
      }
      {...props}
    />
  );
}
