import styles from '../components/Growth/CheckoutSection/checkout.module.scss';
import PencilIcon from '../icons/Actions/ic_edit.svg';
import CheckIcon from '../icons/Actions/ic_check.svg';
import React from 'react';
import { classes } from './index';

interface CheckoutSectionStatus {
  isOpen?: boolean;
  isCompleted?: boolean;
  hovering?: boolean;
  disabled?: boolean;
}

const iconDim = 18;

export const getShowIcon = ({
  isOpen = false,
  isCompleted = false,
}: CheckoutSectionStatus): boolean => {
  return isCompleted && !isOpen;
};

export const getIconClasses = ({
  isOpen = false,
  isCompleted = false,
}: CheckoutSectionStatus): string => {
  return classes(
    styles.status,
    getShowIcon({ isOpen, isCompleted }) ? styles.showIcon : '',
  );
};

export const getIcon = ({
  isOpen = false,
  isCompleted = false,
  hovering = false,
  disabled = false,
}: CheckoutSectionStatus) => {
  if (getShowIcon({ isOpen, isCompleted })) {
    if (isCompleted && hovering && !disabled) {
      return (
        <PencilIcon
          data-testid='pencil-icon'
          width={iconDim}
          height={iconDim}
          className={styles.icon}
        />
      );
    }

    if (isCompleted && (!hovering || disabled)) {
      return (
        <CheckIcon
          data-testid='check-icon'
          width={iconDim}
          height={iconDim}
          className={styles.icon}
        />
      );
    }
  }

  return <></>;
};
