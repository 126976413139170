/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';
import { ProductNode } from '@whoop/web-components';
import { StaticProductNode } from '../types';
import useShopifyVariantsBySku, {
  ShopifyVariantsBySku,
} from './useShopifyVariantsBySku';
import { shopifyCdnImage } from '../utils/shopify';
import { SKU_SUFFIX } from '../utils/regions';
import { Optional } from '@whoop/web-components/dist/types';
import { useProductServiceNodes } from '../utils/product-service';
import { nonNull, singuralizeHook } from '../utils';
import useShopifyProductsByVariant, {
  ProductsByVariantId,
} from './useShopifyProductsByVariant';
import { useBaseProDiscount, useHasProBenefits } from '../redux/hooks';
import { isDiscounted } from '../utils/priceUtils';

const AllProductNodesQuery = graphql`
  query AllProductNodes {
    allWhoopProduct {
      nodes {
        active
        category_label
        colors {
          handle
          label
        }
        description
        featured_media {
          id
          type
          url
        }
        gen3
        gen4
        handle
        id
        inseams {
          label
          handle
        }
        items {
          color {
            handle
            label
          }
          employee_discount
          gen3
          gen4
          inseam {
            handle
            label
          }
          join_flow
          media {
            id
            type
            url
          }
          member_only
          klaviyo_event
          email_when_oos
          new
          pro_exclusive
          pro_reward
          pro_exclude_discount
          product_highlights
          size {
            handle
            label
          }
          sku
          storefront
          title
        }
        join_flow
        media {
          url
          type
          id
        }
        mens
        message
        multi_product_label
        pack_details {
          discount
          pack_items
          pro_discount
        }
        product_highlights
        product_pages
        sizes {
          label
          handle
        }
        sizing_guide
        storefront
        title
        unisex
        womens
        varying_pattern
        children_handles
        parent_handle
        root_handle
      }
    }
  }
`;

export interface StaticProductNodeMap {
  [handle: string]: StaticProductNode;
}

export function useStaticProductMap(): StaticProductNodeMap {
  const queryResult = useStaticQuery<{
    allWhoopProduct: {
      nodes: StaticProductNode[];
    };
  }>(AllProductNodesQuery);
  return useMemo<StaticProductNodeMap>(() => {
    const productNodeMap: StaticProductNodeMap = {};
    queryResult?.allWhoopProduct?.nodes?.forEach((node) => {
      if (node?.handle) {
        productNodeMap[node?.handle] = node;
      }
    });
    return productNodeMap;
  }, [queryResult]);
}

/**
 * When products are configured with a single variant, Shopify automatically
 * gives the variant a title of 'Default Title', with no means provided to change
 * it to null or an empty string. We do not want to display this text in the
 * UI; instead want to leave the variant (item) title blank.
 *
 * Note that we have to check the string in every supported language in order to
 * filter it out.
 *
 * @param title
 */
const isDefaultTitle = (title: string) => {
  const lowerTitle = title.toLowerCase();
  if (lowerTitle === 'default title') {
    return true;
  } else if (lowerTitle === 'titre par défaut') {
    return true;
  } else if (lowerTitle === 'standardbezeichnung') {
    return true;
  }

  return false;
};

const wrapNode = (
  node: ProductNode,
  variants: ShopifyVariantsBySku,
  products: ProductsByVariantId,
  hasProBenefits: boolean,
  baseProDiscount: number,
): ProductNode =>
  ({
    product_info: {
      ...node?.product_info,
      colors: node?.product_info?.colors?.map((color) => ({
        ...color,
        swatch: `url(${shopifyCdnImage(`${color.handle}_64x64.png`)})`,
      })),
      items: node?.product_info?.items?.map((item) => {
        const variant = variants?.[item?.sku + SKU_SUFFIX];
        const comingSoon =
          variant?.metafields?.find(
            (meta) => meta && meta.key === 'coming_soon',
          )?.value === 'true';
        const onSale = variant?.shopifyId
          ? isDiscounted(
              variant,
              products[variant.shopifyId]?.tags || [],
              hasProBenefits,
              baseProDiscount,
            )
          : false;

        return {
          ...item,
          title: isDefaultTitle(item.title) ? '' : item.title,
          sku: item?.sku + SKU_SUFFIX,
          quantity: variant?.inventoryQuantity,
          coming_soon: comingSoon,
          on_sale: onSale,
        };
      }),
    },
    children: node?.children?.map((n) =>
      wrapNode(n, variants, products, hasProBenefits, baseProDiscount),
    ),
  } as ProductNode);

function generateProductNode(
  {
    children_handles,
    parent_handle,
    root_handle,
    ...product_info
  }: StaticProductNode,
  staticNodeMap: StaticProductNodeMap,
): Optional<ProductNode> {
  if (product_info) {
    return {
      product_info,
      children: children_handles
        ?.map(
          (handle) =>
            generateProductNode(
              staticNodeMap[handle] || ({} as StaticProductNode),
              staticNodeMap,
            ) as ProductNode,
        ) // need to trick TS that this won't be undefined
        .filter((_) => !!_), // actually filter undefined here
    };
  }
}

// function that gets a product node
export const useGenerateStaticProductNode = () => {
  const staticNodeMap = useStaticProductMap();
  const variants = useShopifyVariantsBySku();
  const products = useShopifyProductsByVariant();
  const hasProBenefits = useHasProBenefits();
  const baseProDiscount = useBaseProDiscount();
  return useMemo(
    () =>
      (handle: string): Optional<ProductNode> => {
        const root = staticNodeMap[handle];
        const node = root && generateProductNode(root, staticNodeMap);
        return (
          node &&
          wrapNode(node, variants, products, hasProBenefits, baseProDiscount)
        );
      },
    [staticNodeMap, variants, products, hasProBenefits],
  );
};

export function useGetStaticRootHandle(): (handle: string) => string {
  const staticNodeMap = useStaticProductMap();
  return useMemo(
    () => (handle: string) => {
      const rootStaticNode = staticNodeMap[handle];
      return rootStaticNode?.root_handle || handle;
    },
    [staticNodeMap],
  );
}

/**
 * Returns the root handle for a particular product child node. Could be itself.
 * This uses static node data so it should be treated as eventually consistent
 * @param handle child node handle
 */
export function useStaticRootHandle(handle: string): string {
  const getStaticRootHandle = useGetStaticRootHandle();
  return getStaticRootHandle(handle);
}

// returns a memoized wrapped node
function useWrappedNodes(
  nodes: Optional<ProductNode>[],
): Optional<ProductNode>[] {
  const variants = useShopifyVariantsBySku();
  const products = useShopifyProductsByVariant();
  const hasProBenefits = useHasProBenefits();
  const baseProDiscount = useBaseProDiscount();
  return useMemo(
    () =>
      nodes.map(
        (node) =>
          node &&
          wrapNode(node, variants, products, hasProBenefits, baseProDiscount),
      ),
    [nodes, variants],
  );
}

/**
 * Returns static product nodes that can be used for server side rendering
 * @param handles list of node handles
 */
export function useStaticProductNodes(handles: string[]): ProductNode[] {
  const staticNodeMap = useStaticProductMap();
  const rootStaticNodes = handles?.map((handle) => staticNodeMap[handle]);
  const rootNodes = useMemo(
    () =>
      rootStaticNodes &&
      rootStaticNodes
        .filter(nonNull)
        .map((rootStaticNode) =>
          generateProductNode(rootStaticNode, staticNodeMap),
        ),
    [rootStaticNodes, staticNodeMap],
  );

  // @ts-ignore - this node will always be available because it's static
  return useWrappedNodes(rootNodes);
}

/**
 * First returns the static product node but will refresh it from the product service
 * @param handles list of node handles
 */
export function useProductNodes(handles: string[]): ProductNode[] {
  const staticNodes = useStaticProductNodes(handles);
  const freshNodes = useProductServiceNodes(handles);
  const wrappedFreshNodes = useWrappedNodes(freshNodes);

  return wrappedFreshNodes.map((node, i) => node || staticNodes[i]);
}

/**
 * Returns the static product node that can be used for server side rendering
 * @param handle node handle
 */
export const useStaticProductNode = singuralizeHook(useStaticProductNodes);

/**
 * First returns the static product node but will refresh it from the product service
 * @param handle node handle
 */
export const useProductNode = singuralizeHook(useProductNodes);
