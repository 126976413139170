import React, { useState } from 'react';
import { importTranslations, useTranslation } from '../../../utils/i18n';
import styles from './newReferAFriend.module.scss';
import { Button } from '../../Button';
import {
  ButtonSizes,
  ButtonThemes,
  ButtonVariants,
} from '../../../types/Buttons';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import referAFriendImage from '../../../assets/refer-a-friend-image.webp';
import { c } from '../../../utils';

importTranslations('newReferAFriend', require.context('./languages/', true));

export interface NewReferAFriendProps
  extends React.HTMLAttributes<HTMLDivElement> {
  referralMessage: string;
  onShareClick: (referralMessage: string) => void;
}

export function NewReferAFriend({
  referralMessage,
  onShareClick,
  className,
  ...props
}: NewReferAFriendProps) {
  const { t } = useTranslation('newReferAFriend');

  const [buttonClicked, setButtonClicked] = useState(false);

  const handleShare = () => {
    if (!buttonClicked) {
      setButtonClicked(true);
    }

    onShareClick(referralMessage);
  };

  return (
    <div className={c(styles.wrapper, className)} {...props}>
      <img
        className={styles.image}
        src={
          /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
          referAFriendImage
        }
        alt={t('altText')}
      />
      <div className={styles.textContainer}>
        <h3 className={styles.title}>{t('title')}</h3>
        <p className={styles.subtitle}>{t('subtitle')}</p>
      </div>
      <Button
        className={styles.shareButton}
        variant={
          buttonClicked ? ButtonVariants.PRIMARY : ButtonVariants.SECONDARY
        }
        theme={ButtonThemes.JOIN_FLOW}
        size={ButtonSizes.LARGE}
        label={buttonClicked ? t('linkCopied') : t('shareButtonText')}
        onClick={handleShare}
      />
    </div>
  );
}
