import React, { MouseEventHandler } from 'react';
import { AutoCompleteResult, OnSelect } from '.';
import { BasicComponentProps } from '../../types';
import styles from './auto-complete-input.module.scss';
import { AutoCompleteResultItem } from './AutoCompleteResultItem';

export type AutoCompleteResultsContainerProps = {
  results?: AutoCompleteResult[];
  onSelect: OnSelect;
  onMouseEnter?: MouseEventHandler<any>;
  onMouseLeave?: MouseEventHandler<any>;
} & BasicComponentProps;

export const AutoCompleteResultsContainer = ({
  results = [],
  onSelect,
  onMouseEnter,
  onMouseLeave,
}: AutoCompleteResultsContainerProps) => {
  return (
    <div
      className={styles.resultContainer}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {results.map((result) => (
        <AutoCompleteResultItem
          key={result.key}
          onClick={() => onSelect(result)}
        >
          {result.element}
        </AutoCompleteResultItem>
      ))}
    </div>
  );
};
