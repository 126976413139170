import React, { useEffect, useState } from 'react';
import styles from './headerV2.module.scss';
import { classes, clickNavigate, openJoin } from '../../utils';
import Menu, { SignInHint, SignInMenu, UserMenu } from './Menus';
import { SignInHintShown } from '../../utils/storage';
import { Icon } from '@whoop/web-components';
import { Link, navigate } from 'gatsby';
import SideNavV2 from '../SideNavV2';
import * as PropTypes from 'prop-types';
import Search from '../Search';
import ApplicationError from '../ApplicationError';
import useNavigationTree from '../../hooks/useNavigationTree';
import HeaderLogo from '../HeaderLogo';
import {
  useCartItemCount,
  useIsPro,
  useIsUserLoaded,
  useLoginError,
  useUser,
} from '../../redux/hooks';
import { useToggleCart, useToggleSideNav } from '../../redux/action-hooks';
import { useReduxDispatch } from '../../redux/create-store';
import Banner, { ShippingDelayBanner } from '../Banner';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { logoutUser } from '../../redux/reducers/userReducer';
import { newCart } from '../../redux/reducers/cartReducer';
import { useWindowSize } from '../../utils/device-detection';
import { trackWhoopAnalyticsEvent } from '../../utils/analytics';
import { Button, ButtonVariants } from '../../libs/web-components';

const SIGN_IN_HINT_DURATION_MS = 5000; // five second

const HeaderV2 = ({ transparent, disableCart = false, ...props }) => {
  const { t } = useTranslation('header');
  const navigationTree = useNavigationTree();
  const toggleCart = useToggleCart();
  const toggleSideNav = useToggleSideNav();
  const dispatch = useReduxDispatch();
  const dispatchToggleCart = () => {
    hideAllMenus();
    toggleCart();
  };
  const dispatchToggleSideNav = () => {
    hideAllMenus();
    toggleSideNav();
  };
  const user = useUser();
  const cartItemCount = useCartItemCount();
  const quantity = typeof cartItemCount === 'undefined' ? '--' : cartItemCount;
  const isWhoopPro = useIsPro();
  const [isTransparent, setIsTransparent] = useState(true);
  const [showingSignInHint, setShowSignInHint] = useState();
  const [showingSignInMenu, setShowSignInMenu] = useState();
  const [showingSearch, setShowSearch] = useState(false);
  const isUserLoaded = useIsUserLoaded();
  const loginError = useLoginError();

  const showOnlyOrToggle = (setFunc = () => {}) => {
    setShowSignInHint !== setFunc && setShowSignInHint(false);
    setShowSignInMenu !== setFunc && setShowSignInMenu(false);
    setShowSearch !== setFunc && setShowSearch(false);

    return setFunc((prev) => !prev);
  };
  const hideAllMenus = () => {
    showOnlyOrToggle();
  };

  const goToProductPage = (handle) => {
    hideAllMenus();
    navigate(`/products/${handle}/`);
  };

  const isLoggedIn = (user) => {
    return user && user.isLoggedIn;
  };

  const [signInHintTimeout, setSignInHintTimeout] = useState();
  const showSignInOrUserPanel = () => showOnlyOrToggle(setShowSignInMenu);
  const handleLogoutClick = async () => {
    await dispatch(logoutUser());
    await dispatch(newCart());
  };

  useEffect(() => {
    // Scroll hook
    const onScroll = function () {
      const newIsTransparent = window.pageYOffset === 0;
      if (isTransparent !== newIsTransparent) {
        // only set if new
        setIsTransparent(newIsTransparent);
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });

  const cancelSignInTimeout = () =>
    signInHintTimeout && clearTimeout(signInHintTimeout);
  const startSignInTimeout = () =>
    setSignInHintTimeout(
      setTimeout(() => setShowSignInHint(false), SIGN_IN_HINT_DURATION_MS),
    );
  useEffect(() => {
    // if hasn't been shown & user is not signed in
    if (
      isUserLoaded &&
      !signInHintTimeout &&
      !SignInHintShown.get() &&
      !isLoggedIn(user)
    ) {
      setShowSignInHint(true);
      startSignInTimeout();
      SignInHintShown.set(true);
    }
  }, [isUserLoaded, signInHintTimeout]);

  useEffect(() => {
    // once the user is loaded we don't need to show any sign in stuff
    if (isUserLoaded && !loginError) {
      setShowSignInMenu(false);
    }
  }, [isUserLoaded, loginError]);

  const shouldShowFocusBackground = showingSignInMenu || showingSearch;

  const [width] = useWindowSize();
  return (
    <>
      <div
        className='whoop-focus-background'
        data-showing={shouldShowFocusBackground}
        onClick={hideAllMenus}
      />
      <Banner />
      <ShippingDelayBanner />
      <div className={classes(styles.stickyHeader, 'sticky-header')}>
        <ApplicationError />
        <div
          className={classes(
            styles.header,
            isTransparent &&
              transparent &&
              !shouldShowFocusBackground &&
              styles.transparent,
          )}
        >
          <div
            className={classes(styles.group, styles.left)}
            role='menubar'
            aria-label={t('aria.productMenu')}
          >
            <div
              className={classes(styles.action, styles.hamburger)}
              onClick={dispatchToggleSideNav}
              aria-label={t('aria.navigationMenu')}
              data-event='Open navigation menu'
              data-event-source='hamburger'
            >
              <Icon className={styles.icon} name='menu' />
            </div>

            {navigationTree.map((group, i) => (
              <div
                key={i}
                className={classes(styles.action, styles.navigationBorderItems)}
                onMouseOver={hideAllMenus}
              >
                <a
                  href={group.link}
                  aria-label={group.label}
                  className={classes(
                    styles.label,
                    (showingSearch || showingSignInMenu) && styles.blurred,
                    'no-color',
                  )}
                  onClick={(event) => {
                    event.preventDefault();
                    group.link && clickNavigate(group.link);
                  }}
                  data-event='Clicked Navigation'
                  data-event-name={group.label}
                  data-event-link={group.link}
                  data-event-type='navigation-group'
                  data-event-index={i + 1}
                  data-event-navigation-level={'1'}
                  data-event-navigation-type={'Desktop'}
                  onMouseOver={() => {
                    trackWhoopAnalyticsEvent('Hovered Navigation', {
                      name: group.label,
                      link: group.link,
                      type: 'navigation-group',
                      index: i + 1,
                      'navigation-level': 1,
                      'navigation-type': 'Desktop',
                    });
                  }}
                >
                  {group.label}
                </a>
                {group.children && <Menu items={group.children} />}
              </div>
            ))}
          </div>

          <Link
            to='/'
            data-event='Clicked Navigation'
            data-event-type='home'
            className='no-color'
          >
            <div>
              <HeaderLogo whoopPro={isWhoopPro} />
            </div>
          </Link>

          <div className={classes(styles.group, styles.right)}>
            {!user && (
              <div
                className={classes(
                  styles.action,
                  styles.joinButton,
                  styles.topLevelNavigation,
                )}
              >
                <Button
                  variant={ButtonVariants.PRIMARY}
                  label={t('joinWhoop')}
                  size='small'
                  aria-label={t('joinWhoop')}
                  onClick={openJoin}
                  data-event='Clicked Navigation'
                  data-event-type='join-whoop'
                />
              </div>
            )}

            <div className={classes(styles.action, styles.topLevelNavigation)}>
              <button
                className={classes(
                  styles.label,
                  showingSignInMenu && styles.blurred,
                  styles.nonNavigationBorderItems,
                )}
                aria-label={t('aria.search')}
                onClick={() => showOnlyOrToggle(setShowSearch)}
                data-event='Clicked Navigation'
                data-event-type='search'
                data-event-opened={showingSearch}
              >
                <Icon className={styles.icon} name='search' />{' '}
                <span>{t('search')}</span>
              </button>
            </div>

            <div className={classes(styles.action, styles.topLevelNavigation)}>
              <button
                id='sign-in-label'
                aria-label={t('aria.signIn')}
                className={classes(
                  styles.label,
                  showingSearch && styles.blurred,
                  styles.nonNavigationBorderItems,
                )}
                onClick={showSignInOrUserPanel}
                data-event='Clicked Navigation'
                data-event-type='user-menu'
                data-event-opened={showingSignInMenu}
              >
                {user ? (
                  <>
                    <img
                      className={styles.avatar}
                      src={user.avatarUrl}
                      alt={`${user.fullName}'s avatar`}
                    />
                    <span>
                      {isLoggedIn(user) ? user.firstName : t('signIn')}
                    </span>
                  </>
                ) : (
                  <>
                    <Icon className={styles.icon} name='my_account' />
                    <span>{t('signIn')}</span>
                  </>
                )}
              </button>
              {isLoggedIn(user) ? (
                <UserMenu
                  show={showingSignInMenu}
                  onLogoutClicked={handleLogoutClick}
                />
              ) : (
                <>
                  <SignInHint
                    show={showingSignInHint}
                    onMouseEnter={cancelSignInTimeout}
                    onMouseLeave={startSignInTimeout}
                    onSignInClick={showSignInOrUserPanel}
                  />
                  <SignInMenu show={showingSignInMenu} />
                </>
              )}
            </div>

            <div className={classes(styles.action, styles.topLevelNavigation)}>
              <button
                id='cart-label'
                className={classes(
                  styles.label,
                  (showingSearch || showingSignInMenu) && styles.blurred,
                  styles.nonNavigationBorderItems,
                )}
                aria-label={t('aria.toggleCart')}
                onClick={dispatchToggleCart}
                disabled={disableCart}
                data-event='Clicked Navigation'
                data-event-type='cart'
              >
                <Icon className={styles.icon} name='shopping_cart' />
                <span>{t('cart', { quantity: quantity })}</span>
              </button>
            </div>
          </div>
          {showingSearch && (
            <Search
              onReset={hideAllMenus}
              onResultClick={({ handle }) => goToProductPage(handle)}
            />
          )}
        </div>
      </div>
      {width <= 1640 && <SideNavV2 />}
    </>
  );
};
HeaderV2.propTypes = {
  transparent: PropTypes.bool,
};

export default HeaderV2;
