import React, { forwardRef } from 'react';
import styles from './modal.module.scss';
import { c } from '../../utils';
import { IconButton } from '../IconButton';
import { FocusBackground } from '../FocusBackground';
import { ModalThemes } from '../../types/Modal';

export interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  show: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClose: Function;
  disableAnimation?: boolean;
  disableClose?: boolean;
  slideAnimation?: boolean;
  theme?: ModalThemes;
}

export const Modal = forwardRef<any, ModalProps>(
  (
    {
      children,
      className,
      show,
      onClose,
      disableAnimation,
      disableClose = false,
      slideAnimation,
      theme,
      ...props
    }: ModalProps,
    ref,
  ) => {
    const callClose = () => {
      if (onClose && !disableClose) {
        onClose();
      }
    };

    // TODO: Might need to be `theme${capitalize(theme)}` here based on the typescriptification of the style names
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const themeStyle = `theme-${theme}`;
    return (
      <>
        <FocusBackground
          show={show}
          onClick={callClose}
          style={
            theme === ModalThemes.UNITE && show
              ? { background: 'var(--color-black-alpha-800)' }
              : {}
          }
        />
        <div
          id='modal'
          className={c(
            styles.modal,
            show && styles.show,
            disableAnimation && styles.disableAnimation,
            theme !== ModalThemes.DEFAULT && themeStyle,
            slideAnimation && 'type-slide',
            className,
          )}
          ref={ref}
          {...props}
        >
          <div className={styles.topBar}>
            {!disableClose && (
              <IconButton
                className={styles.closeButton}
                name='cross'
                onClick={callClose}
                variant='link'
              />
            )}
          </div>

          {children}
        </div>
      </>
    );
  },
);
Modal.displayName = 'Modal';
export default Modal;
