const SMARTY_BASE_URL =
  'https://us-autocomplete-pro.api.smartystreets.com/lookup';

export const searchAddresses = (apiKey: string, searchText: string) => {
  const requestUrl = `${SMARTY_BASE_URL}?key=${apiKey}&search=${searchText}`;
  return fetch(requestUrl)
    .then((res) => res.json())
    .catch((e) => {
      console.error(e);
      return [];
    });
};

export const searchAddressesWithSelection = async (
  apiKey: string,
  searchText: string,
  selectedAddress: string,
) => {
  const requestUrl = `${SMARTY_BASE_URL}?key=${apiKey}&search=${searchText}&selected=${selectedAddress}`;
  return fetch(requestUrl)
    .then((res) => res.json())
    .catch((e) => {
      console.error(e);
      return [];
    });
};
