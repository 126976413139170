import React, { useEffect } from 'react';
import s from './product-card.module.scss';
import { ProductHeader } from '../ProductHeader';
import { ProductItem, ProductNode } from '../../../types/Products';
import { SwatchOptionSelect } from '../../deprecated/SwatchOptionSelect';
import { Slideshow } from '../../Slideshow';
import { Button } from '../../Button';
import { c } from '../../../utils';
import { Badge, BadgeColors } from '../../Badge';
import {
  getFeaturedMediaFromSelection,
  getMediaFromSelection,
  getProductInfoFromSelection,
  useFlattenedProductOptions,
} from '../../../utils/productUtils';
import {
  ButtonSizes,
  ButtonThemes,
  ButtonVariants,
} from '../../../types/Buttons';
import { ProductMedia } from '../ProductMedia';
import { AnalyticsFunction } from '../../../types';

export interface BaseProductCardProps
  extends React.HTMLAttributes<HTMLDivElement> {
  name?: string; // unique name for this card for managing inputs & swatch selections
  price: string;
  originalPrice?: string;
  withWhoopProPrice?: string;
  isProDiscount?: boolean;
  isWhoopProAction?: boolean;
  subTitle?: string;
  onAnalyticsEvent?: AnalyticsFunction;
  onAction?: () => any;
  actionLabel?: string;
  onSecondaryAction?: () => any;
  secondaryActionLabel?: string;
  bannerLabel?: string;
  bannerColor?: BadgeColors;
  badgeLabel?: string;
  badgeColor?: BadgeColors;
  content?: React.ReactNode;
  hideSizing?: boolean;
  hoverBannerLabel?: string;
  imageMessage?: string;
  reviewStars?: React.ReactNode;
}

export interface ProductCardProps
  extends Omit<BaseProductCardProps, 'onChange'> {
  node: ProductNode;
  value?: ProductItem;
  onChange?: (selection: ProductItem) => any;
  singleImage?: boolean;
}

export function ProductCard({
  name,
  node,
  value,
  onChange,
  onAnalyticsEvent,
  onClick,
  isProDiscount,
  isWhoopProAction,
  withWhoopProPrice,
  originalPrice,
  subTitle,
  price,
  actionLabel,
  onAction,
  secondaryActionLabel,
  onSecondaryAction,
  bannerLabel,
  bannerColor,
  badgeLabel,
  badgeColor,
  className,
  children,
  content,
  hideSizing,
  singleImage,
  hoverBannerLabel,
  imageMessage,
  reviewStars,
  ...props
}: ProductCardProps) {
  const { handle, title } = node?.product_info || {};
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const cardKey = name ? `${name}-${handle}` : handle;
  const [selection, sizeSelectProps, inseamSelectProps, colorSelectProps] =
    useFlattenedProductOptions(
      node,
      value,
      (option, type, item) => {
        onAnalyticsEvent &&
          onAnalyticsEvent('Product Card Option Selected', {
            product: handle,
            card_name: name,
            sku: item?.sku,
            option: option,
            option_type: type,
            item: item,
          });
      },
      hideSizing,
    );
  // const showDivider =
  //   colorSelectProps ||
  //   sizeSelectProps ||
  //   inseamSelectProps ||
  //   actionLabel ||
  //   content;
  const media = getMediaFromSelection(selection, node);
  const singleMedia =
    node?.product_info?.colors?.length && node?.product_info?.colors?.length > 1
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        media[0]
      : getFeaturedMediaFromSelection(selection, node);
  const childProductInfo = getProductInfoFromSelection(selection, node);

  useEffect(() => {
    onChange && onChange(selection);
  }, [selection]);

  const onCardClick: React.MouseEventHandler = (
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    // if we only have one image just allow the whole card to be selected
    if (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      e.target?.tagName?.toLowerCase() !== 'button' &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      e.target?.tagName?.toLowerCase() !== 'label'
    ) {
      onClick && onClick(e);
    }
  };

  return (
    <div
      className={c(s.productCard, !!onClick && s.clickable, className)}
      onClick={onCardClick}
      {...props}
    >
      <div className={c(s.productImage)}>
        {badgeLabel && (
          <Badge className={s.badge} color={badgeColor} label={badgeLabel} />
        )}
        {children ||
          (singleImage ? (
            singleMedia && <ProductMedia {...singleMedia} />
          ) : (
            <Slideshow
              className={s.slideshow}
              prevNextButtons={true}
              pageDots={false}
              wrapAround={false}
              parentSizing={true}
              onChange={(imageIndex) => {
                onAnalyticsEvent &&
                  onAnalyticsEvent('Product Card Viewed Image', {
                    product: handle,
                    card_name: name,
                    sku: selection?.sku,
                    image_index: imageIndex,
                  });
              }}
              style={{ height: '100%' }}
            >
              {media?.map((item) => (
                <ProductMedia {...item} key={item.id} />
              ))}
            </Slideshow>
          ))}
        {imageMessage && (
          <Badge className={s.overlayBadge} label={imageMessage} />
        )}

        {(hoverBannerLabel || bannerLabel) && (
          <div className={s.bannerContainer}>
            {hoverBannerLabel && (
              <Badge className={s.hoverBanner} color={BadgeColors.TRANSPARENT}>
                {hoverBannerLabel}
              </Badge>
            )}
            {bannerLabel && (
              <Badge color={bannerColor || BadgeColors.BLACK}>
                {bannerLabel}
              </Badge>
            )}
          </div>
        )}
      </div>

      <div className={s.productBody}>
        <ProductHeader
          className={s.productHeader}
          title={childProductInfo?.title || title}
          price={price}
          size='medium'
          withWhoopProPrice={withWhoopProPrice}
          isProDiscount={isProDiscount}
          originalPrice={originalPrice}
          subTitle={subTitle}
          reviewStars={reviewStars}
        />
        <div className={c(s.productDetails)}>
          <div className={s.colorSwatch}>
            {colorSelectProps && (
              <SwatchOptionSelect
                name={`${cardKey}-color`}
                size='small'
                hideTooltips={true}
                {...colorSelectProps}
              />
            )}
          </div>
          {content && <div className={s.content}>{content}</div>}
          <div className={s.sizeSwatches}>
            <div>
              {!hideSizing && sizeSelectProps && (
                <SwatchOptionSelect
                  name={`${cardKey}-size`}
                  size='small'
                  hideTooltips={false}
                  {...sizeSelectProps}
                />
              )}
              <br />
              {!hideSizing && inseamSelectProps && (
                <SwatchOptionSelect
                  name={`${cardKey}-inseam`}
                  size='small'
                  hideTooltips={false}
                  {...inseamSelectProps}
                />
              )}
            </div>

            {(actionLabel || secondaryActionLabel) && (
              <div className={s.actionContainer}>
                {secondaryActionLabel && (
                  <a className={s.sizeGuide} onClick={onSecondaryAction}>
                    {secondaryActionLabel}
                  </a>
                )}
                {actionLabel && (
                  <Button
                    variant={ButtonVariants.NORMAL}
                    size={ButtonSizes.SMALL}
                    theme={ButtonThemes.DEFAULT}
                    label={actionLabel}
                    className={c(
                      isWhoopProAction && s.wpExclusive,
                      s.addToCart,
                    )}
                    onClick={onAction}
                    disabled={
                      selection?.quantity === undefined ||
                      selection?.quantity <= 0
                    }
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
