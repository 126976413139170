import React from 'react';
import { BaseProductCardProps, ProductCard } from '../ProductCard';
import styles from './whoop-your-way-card.module.scss';
import wywBbanner from '../../../assets/wyw_card/wyw_banner.png';
import { c } from '../../../utils';
import { ProductInfo } from '../../../index';

export interface WhoopYourWayCardProps
  extends Omit<BaseProductCardProps, 'onChange'> {
  title: string;
}

export function WhoopYourWayCard({
  title,
  className,
  ...props
}: WhoopYourWayCardProps) {
  return (
    <ProductCard
      className={c(styles.whoopYourWayCard, className)}
      node={{ product_info: { title } as ProductInfo, children: [] }}
      {...props}
    >
      <div className={styles.imageContainer}>
        <img src={wywBbanner} />
        <img src={wywBbanner} />
      </div>
    </ProductCard>
  );
}
