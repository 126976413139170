import React, { useEffect, useRef } from 'react';
import styles from './carousel.module.scss';
import 'flickity/css/flickity.css';
import { c } from '../../../utils';
import Flickity from 'react-flickity-component';
import { BasicComponentProps } from '../../../types';

export type CarouselProps = {
  onChange?: (index: number) => void;
} & BasicComponentProps;

type FlickityElement = Flickity & {
  element: HTMLElement;
};

export const Carousel = ({
  children,
  className,
  onChange,
  ...props
}: CarouselProps): JSX.Element => {
  const $flickity = useRef<FlickityElement>(null);

  useEffect(() => {
    $flickity?.current?.on('change', (index: number) => {
      onChange && onChange(index);
    });
  }, [$flickity]);

  return (
    <div className={c(styles.slideshow, className)} {...props}>
      <Flickity
        flickityRef={(c) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: Flickity library does not support a generic ref type for this
          ($flickity.current = c)
        }
        options={{
          adaptiveHeight: false,
          lazyLoad: true,
          dragThreshold: 8,
          initialIndex: 0,
          imagesLoaded: true,
          accessibility: true,
          cellAlign: 'center',
          pageDots: true,
          prevNextButtons: true,
          wrapAround: true,
          groupCells: 1,
          contain: true,
        }}
      >
        {children}
      </Flickity>
    </div>
  );
};

export default Carousel;
