import React from 'react';
import { BasicComponentProps } from './index';

export type RadioInputOption = {
  disabled?: boolean;
  label?: string;
  sublabel?: string;
  style?: React.CSSProperties;
  value: string | number;
  crossOut?: boolean;
  price?: string | number;
};

export enum RadioToggleThemes {
  DEFAULT = 'default',
  SQUARE = 'square',
  DARK = 'dark',
}

export type RadioInputProps = {
  name: string;
  onChange?: (...value: any[]) => void;
  onHover?: (...value: any[]) => void;
  options: RadioInputOption[];
  size?: 'small' | 'medium' | 'large';
  useExternalState?: boolean;
  allowNone?: boolean;
  value: string | number | undefined;
  theme?: RadioToggleThemes;
  isShippingMethodRadioInput?: boolean;
} & BasicComponentProps;
