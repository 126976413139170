/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import styles from './collection-page.module.scss';
import Seo from '../../components/Seo';
import { ShopifyCollection } from '../../types/generated';
import ProductGrid from '../../components/ProductGrid';
import { classes } from '../../utils';
import { noop } from 'lodash';
import { trackFilteredCollection } from '../../utils/analytics';
import { ProLogo } from '@whoop/web-components';
import {
  useCollectionFilters,
  FILTER_BY_URL_PARAM,
  getRelevantFilterGroups,
} from './filters';
import useSort, { useCollectionSort } from '../../hooks/useSort';
import { useToggleProModal } from '../../redux/action-hooks';
import { isWhoopProExclusive } from '../../utils/products';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import ProductCardWrapper from '../../components/ProductCardWrapper/ProductCardWrapper';
import { useAllProducts } from '../../hooks/useAllProducts';
import { useQueryParam } from '../../utils/queryParamUtils';
import { useUser, useFreeRewardAvailable, useIsPro } from '../../redux/hooks';

export interface ShopifyCollectionWithOrdinal extends ShopifyCollection {
  productsList: string[];
}

type CollectionPageProps = {
  pageContext: {
    collection: ShopifyCollectionWithOrdinal;
  };
};

export default function CollectionPage({
  pageContext,
}: CollectionPageProps): JSX.Element {
  const { t } = useTranslation('collection');
  const SORT_OPTIONS = useCollectionSort();
  const FILTERS = useCollectionFilters();
  const [collection] = useState(pageContext.collection);
  const dispatchToggleModal = useToggleProModal('collections-page');
  const isPro = useIsPro();

  const [enabledFilterMap, setEnabledFilterMap] = useState<{
    [id: string]: boolean;
  }>({});

  const isFilterEnabled = (filterKey: string) =>
    !!(FILTERS[filterKey] && enabledFilterMap[filterKey]);

  const getEnabledFilters = () => {
    return Object.keys(enabledFilterMap).filter(isFilterEnabled);
  };

  const enabledFilters = getEnabledFilters();
  // To avoid sending multiple tracking events on page load, keep the tracked
  // filters in the component state to only output a tracking event when the
  // filters change.
  const [trackedFilters, setTrackedFilters] = useState<string[]>();

  useEffect(() => {
    setTrackedFilters(enabledFilters);
  }, []);

  const [sortKey, sortQueryParams, setSortKey] = useSort('');

  const [filterQueryParams, setFilterQueryParams] = useQueryParam(
    FILTER_BY_URL_PARAM,
    '',
  );

  useEffect(() => {
    if (filterQueryParams !== '') {
      const map = filterQueryParams
        .split(',')
        .reduce((acc: { [key: string]: boolean }, filter) => {
          acc[filter] = true;
          return acc;
        }, {});
      setEnabledFilterMap(map);
    }
    setSortKey(sortQueryParams === '' ? 'featured' : sortQueryParams);
  }, []);

  useEffect(() => {
    setTrackedFilters(enabledFilters);
    setFilterQueryParams(enabledFilters.join(','));
  }, [enabledFilterMap]);

  const toggleFilter = (filterKey: string) => {
    setEnabledFilterMap({
      ...enabledFilterMap,
      [filterKey]: !enabledFilterMap[filterKey],
    });
  };

  const freeRewardAvailable = useFreeRewardAvailable();
  let wywFilter: string | undefined;

  if (freeRewardAvailable && collection.handle === 'whoop-pro') {
    wywFilter = 'free-item';
  }

  let fullProductList = useAllProducts(collection.productsList, true);
  const relevantFilters = getRelevantFilterGroups(fullProductList!);
  fullProductList?.sort(SORT_OPTIONS[sortKey]?.sortFn);

  // If all of the products in the collection are exclusive / early-access items,
  // display the EXCLUSIVE badge
  const isExclusive =
    fullProductList!.length > 0 &&
    fullProductList!.length ===
      fullProductList!.filter((p) => isWhoopProExclusive(p.tags)).length;

  const needsWPMaterial = collection.handle?.includes('whoop-pro-');

  fullProductList = fullProductList!.filter(
    (product) =>
      enabledFilters.length === 0 ||
      enabledFilters.reduce(
        (acc: boolean, filterKey: string) =>
          acc || product.tags?.indexOf(FILTERS[filterKey].tag) !== -1,
        false,
      ),
  );

  const [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => setIsFirstRender(false), []);

  useEffect(() => {
    if (trackedFilters && trackedFilters.length > 0) {
      trackFilteredCollection(
        collection.handle,
        trackedFilters,
        fullProductList!.length,
      );
    }
  }, [trackedFilters]);

  return (
    <>
      <div className={styles.collectionPage}>
        <Seo
          title={collection.title}
          keywords={[collection.title, 'WHOOP']}
          type='collection'
          imageSrc={collection.image?.originalSrc}
        />

        <section>
          <header>
            {collection.image?.originalSrc ? (
              <div
                className={styles.heroContainer}
                style={{
                  backgroundImage: `url('${collection.image.originalSrc}')`,
                }}
              >
                <div className={styles.heroDetails}>
                  {isExclusive && (
                    <div className={classes(styles.tagExclusive)}>
                      {t('wpExclusive')}
                    </div>
                  )}
                  <h1>{collection.title}</h1>
                </div>
              </div>
            ) : (
              <>
                {!needsWPMaterial && collection.handle !== 'whoop-pro' && (
                  <div className={styles.title}>
                    {isExclusive && (
                      <div className={classes(styles.tagExclusive)}>
                        {t('wpExclusive')}
                      </div>
                    )}
                    <h1>{collection.title}</h1>
                  </div>
                )}
              </>
            )}
          </header>
          {collection.descriptionHtml && (
            <div className={styles.collectionDescription}>
              {needsWPMaterial && <ProLogo />}
              <div
                dangerouslySetInnerHTML={{
                  __html: collection.descriptionHtml,
                }}
              />
              {needsWPMaterial && isPro && (
                <span className={styles.proLink} onClick={dispatchToggleModal}>
                  {t('wpLearnPerks')}
                </span>
              )}
            </div>
          )}
          {collection.handle === 'whoop-pro' && (
            <div className={styles.collectionDescription}>
              <ProLogo />
              <h2 className={styles.proHeader}>{t('wpFreeEligible')}</h2>
              <p>
                {t('wpFreeProductOptions')}
                <br />
                {isPro && (
                  <span
                    className={styles.proLink}
                    onClick={dispatchToggleModal}
                  >
                    {t('wpLearnPerks')}
                  </span>
                )}
              </p>
            </div>
          )}
          <ProductGrid>
            {!isFirstRender &&
              fullProductList &&
              fullProductList.map((product) => (
                <ProductCardWrapper
                  item={product}
                  key={product.handle}
                  wywFilter={wywFilter}
                  userLocation='Collections'
                />
              ))}
          </ProductGrid>
          <div className={styles.collectionControls}>
            <div>
              <h4>{t('sort')}</h4>
              <div className={styles.panel} aria-label={t('sort')}>
                {Object.keys(SORT_OPTIONS).map((sortOptionKey) => (
                  <div
                    key={sortOptionKey}
                    className={classes(
                      styles.selectable,
                      sortKey === sortOptionKey && styles.selected,
                    )}
                    onClick={(e) => {
                      setSortKey(sortOptionKey);
                      e.preventDefault();
                    }}
                    data-event='Clicked Sort Button'
                    data-event-sort_by={sortOptionKey}
                  >
                    <input
                      type='radio'
                      name='sort'
                      id={sortOptionKey}
                      checked={sortKey === sortOptionKey}
                      onChange={noop}
                      aria-label={SORT_OPTIONS[sortOptionKey].label}
                    />
                    <label htmlFor={sortOptionKey}>
                      {SORT_OPTIONS[sortOptionKey].label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h4>
                {t('filter')}{' '}
                {enabledFilters.length !== 0 && `(${enabledFilters.length})`}
              </h4>
              <div
                className={classes(styles.panel, styles.extraTall)}
                aria-label={t('filter')}
              >
                <div>
                  {relevantFilters &&
                    relevantFilters?.map((group) => (
                      <div key={group.label} className={styles.filterGroup}>
                        <h4>{group.label}</h4>
                        {group.children.map((filterKey) => (
                          <div
                            key={FILTERS[filterKey].label}
                            className={classes(
                              styles.selectable,
                              isFilterEnabled(filterKey) && styles.selected,
                            )}
                            onClick={() => toggleFilter(filterKey)}
                            data-event='Clicked Filter Button'
                            data-event-filter_by={filterKey}
                            data-event-is_checked={isFilterEnabled(filterKey)}
                          >
                            <input
                              type='checkbox'
                              name={group.label}
                              id={FILTERS[filterKey].label}
                              checked={isFilterEnabled(filterKey)}
                              onChange={noop}
                              aria-label={FILTERS[filterKey].label}
                            />
                            <label
                              htmlFor={FILTERS[filterKey].label}
                              onClick={(e) => e.preventDefault()}
                            >
                              {FILTERS[filterKey].label}
                            </label>
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={styles.sockSpacer}></div>
      </div>
    </>
  );
}

export const translationsQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
