/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import './src/styles/global.scss';
import React from 'react';
import { Provider } from 'react-redux';
import store from './src/redux/create-store';
import { WrapRootElementBrowserArgs } from 'gatsby';
import { trackClientEntry } from './src/utils/analytics';
import { WebComponentsContext } from './src/utils/WebComponentsContext';
import { getAccessToken } from '@whoop/web-auth-client';

export const wrapRootElement = ({
  element,
}: WrapRootElementBrowserArgs): JSX.Element => {
  return (
    <WebComponentsContext.Provider
      value={{ language: process.env.SHOP_LANGUAGE || 'en' }}
    >
      <Provider store={store}>{element}</Provider>
    </WebComponentsContext.Provider>
  );
};

export const onClientEntry = async function () {
  if (typeof window !== 'undefined') {
    window.ENV = process.env.ENV?.toLowerCase() || 'dev';
  }

  trackClientEntry();
  try {
    await getAccessToken(); // initializes authentication
  } catch (e) {
    console.error(e);
  }
};

type RouterProps = {
  location: {
    pathname: string;
  };
};
// If we need complicated behavior see:
// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll
export const shouldUpdateScroll = ({
  routerProps,
  prevRouterProps,
}: {
  routerProps: RouterProps;
  prevRouterProps: RouterProps;
}) => {
  // want to make sure we only scroll up if the path changes, not if a query param changes
  const firstPath = routerProps?.location?.pathname || '';
  const prevPath = prevRouterProps?.location?.pathname || '';
  return firstPath && firstPath !== prevPath;
};
