import React from 'react';
import { RadioSwatches } from '../../RadioSelectors/RadioSwatches/RadioSwatches';
import { BasicComponentProps } from '../../../types';

export type SwatchOption = {
  value: string;
  label: string;
  background?: string;
  disabled?: boolean;
  crossOut?: boolean;
};

export type SwatchOptionSelectProps = {
  name: string;
  options?: SwatchOption[] | null;
  onChange?: (value: string) => void;
  onHover?: (value: string) => void;
  value?: string | null;
  size?: 'small' | 'medium' | 'large';
  hideTooltips?: boolean;
  useExternalState?: boolean;
} & BasicComponentProps;

/**
 * @deprecated Use RadioSwatches component instead
 */
export const SwatchOptionSelect = ({
  name,
  options,
  onHover,
  onChange,
  value,
  size,
  hideTooltips,
  className,
  useExternalState,
  ...props
}: SwatchOptionSelectProps): JSX.Element | null => {
  if (!options || !options.length) return null;

  const radioOptions = options.map((option) => {
    return {
      crossOut: option.crossOut,
      disabled: option.disabled,
      label: option.label,
      style: option.background
        ? { background: option.background, backgroundSize: 'cover' }
        : undefined,
      value: option.value,
    };
  });

  return (
    <RadioSwatches
      className={className}
      name={name}
      onChange={onChange}
      onHover={onHover}
      showToolTips={!hideTooltips}
      size={size}
      options={radioOptions}
      useExternalState={useExternalState}
      value={value || undefined}
      {...props}
    />
  );
};
