import React, { useState } from 'react';
import styles from './checkout.module.scss';
import { classes } from '../../../utils';
import { getIcon, getIconClasses } from '../../../utils/checkoutSectionUtils';
import { Loading } from '../../Loading/Loading';
import { LoadingSizeType } from '../../../types/Loading';

export interface CheckoutSectionProps
  extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  isCompleted?: boolean;
  isLoading?: boolean;
  isOpen?: boolean;
  disabled?: boolean;
  handleEditClick?: () => void;
  className?: string;
}

export default function CheckoutSection({
  title,
  isCompleted = false,
  isLoading = false,
  isOpen = false,
  disabled = false,
  handleEditClick,
  className,
  children,
}: CheckoutSectionProps) {
  const [hovering, setIsHovering] = useState(false);

  const showLoadingIndicator = isLoading && isOpen;

  return (
    <div
      className={classes(className, styles.checkoutSection)}
      data-testid='checkout-section'
    >
      <div className={styles.container}>
        {showLoadingIndicator && (
          <div
            className={styles.loadingOverlay}
            data-testid='checkout-section-loading'
          >
            <Loading size={LoadingSizeType.LARGE} />
          </div>
        )}
        <button
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          className={classes(
            styles.toggle,
            'whoop-typography',
            !disabled && styles.clickable,
            className,
          )}
          onClick={handleEditClick}
          disabled={disabled || isLoading}
        >
          <div className={styles.titleContainer}>
            <h2 className={classes(styles.title)}>{title}</h2>
            <div
              data-testid='Icon'
              className={getIconClasses({ isOpen, isCompleted })}
            >
              {getIcon({ isOpen, isCompleted, hovering, disabled })}
            </div>
          </div>
        </button>
        <div
          data-testid='Collapsible'
          className={classes(styles.collapsible, isOpen ? styles.open : '')}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
