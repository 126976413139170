import React from 'react';
import PropTypes from 'prop-types';
import { RadioToggle } from '../../RadioSelectors/RadioToggle';

/**
 * @deprecated Use RadioToggle component instead
 */
export const ToggleButton = ({
  size = 'medium',
  value,
  options,
  onChange,
  className,
  useExternalState,
  ...props
}) => {
  return (
    <RadioToggle
      className={className}
      onChange={onChange}
      options={options}
      useExternalState={useExternalState}
      size={size}
      value={value}
      {...props}
    />
  );
};

ToggleButton.propTypes = {
  /**
   * Toggle size
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * input toggle value
   */
  value: PropTypes.string,
  /**
   * input options array
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  /**
   * change callback includes current value in params
   */
  onChange: PropTypes.func,
  /**
   * Optional flag to either use it's internal state or use the state provided in value
   */
  useExternalState: PropTypes.bool,
};
