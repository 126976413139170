import React, { ReactChild, ReactElement } from 'react';
import styles from './cardComponent.module.scss';

export interface CardComponentProps extends CommonProps, ToggleProps {}

export interface CommonProps {
  title: string;
  children: ReactChild;
  className?: string;
  showToggle?: boolean;
  footer?: ReactChild;
}

export interface ToggleProps {
  showToggle?: boolean;
  toggleComp?: ReactElement;
}

export const MxpCardComponent = (props: CardComponentProps) => {
  const {
    title,
    children,
    className = '',
    showToggle = false,
    toggleComp,
    footer,
  } = props;
  return (
    <div className={`${styles.cardComponent} ${className}`}>
      <div className={styles.cardComponentHeader}>
        {title}
        {showToggle && toggleComp}
      </div>
      <div>{children}</div>
      {footer && <div>{footer}</div>}
    </div>
  );
};
