import React, { useEffect, useMemo, useState } from 'react';
import { FormInputType } from '../../../types/FormInputType';
import { Button } from '../../Button';
import {
  ButtonSizes,
  ButtonThemes,
  ButtonVariants,
} from '../../../types/Buttons';
import { Form } from '../../Form';
import { Input } from '../../Input';
import { importTranslations, useTranslation } from '../../../utils/i18n';
import styles from './createAccountForm.module.scss';
import { validateEmail } from '../../../utils/validation';

importTranslations('create-account', require.context('./languages/', true));

export interface UserDetails {
  email: string;
  password: string;
}

export interface CreateAccountFormProps
  extends React.HTMLAttributes<HTMLDivElement> {
  onSubmit: (user: any) => void;
  isEmailValid?: boolean;
  suggestedEmail?: string;
  resetEmailValidation?: () => void;
  userDetails?: UserDetails;
  formError?: string;
}

export function CreateAccountForm({
  onSubmit,
  isEmailValid,
  suggestedEmail,
  resetEmailValidation,
  userDetails,
  formError,
  className,
  ...props
}: CreateAccountFormProps) {
  const { t } = useTranslation('create-account');
  const [email, _setEmail] = useState<string | undefined>(userDetails?.email);
  const [emailError, setEmailError] = useState<string>();
  const [emailSuggestion, setEmailSuggestion] = useState<string>();

  const [password, _setPassword] = useState<string | undefined>(
    userDetails?.password,
  );
  const [passwordError, setPasswordError] = useState<string>();

  const [confirmPassword, _setConfirmPassword] = useState<string>();
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>();

  const [user, setUser] = useState<UserDetails | undefined>(userDetails);

  useEffect(() => {
    if (suggestedEmail) {
      setEmailSuggestion(t('suggestedEmail', { suggestedEmail }));
    } else if (isEmailValid !== undefined && !isEmailValid) {
      setEmailError(t('validEmail'));
    }
  }, [isEmailValid, suggestedEmail]);

  const isSubmitDisabled = useMemo(
    () =>
      !email ||
      !password ||
      !confirmPassword ||
      !!passwordError ||
      !!confirmPasswordError ||
      !!emailError ||
      (isEmailValid !== undefined && !isEmailValid),
    [
      email,
      password,
      confirmPassword,
      passwordError,
      confirmPasswordError,
      emailError,
      isEmailValid,
    ],
  );

  const setEmail = (text: string | undefined) => {
    if (!isEmailValid || !suggestedEmail) {
      resetEmailValidation && resetEmailValidation();
    }
    setEmailError(undefined);
    setEmailSuggestion(undefined);
    _setEmail(text);
  };

  const validateEmailCheck = () => {
    if (!email || email.length === 0) {
      setEmailError(t('validEmail'));
      return;
    }
    if (!validateEmail(email)) {
      setEmailError(t('validEmail'));
      return;
    }
    setEmailError(undefined);
  };

  const setPassword = (text: string) => {
    setPasswordError(undefined);
    _setPassword(text);
  };

  const setConfirmPassword = (text: string) => {
    setConfirmPasswordError(undefined);
    _setConfirmPassword(text);
  };

  const checkPasswordEquality = () => {
    if (password && confirmPassword && confirmPassword !== password) {
      setConfirmPasswordError(t('passwordMatchError'));
      return;
    }
    setConfirmPasswordError(undefined);
  };

  const validatePassword = () => {
    // password required check
    if (!password || password.length === 0) {
      setPasswordError(t('validPassword'));
      return;
    }
    // password length check
    if (password.length < 6) {
      setPasswordError(t('passwordLengthError'));
      return;
    }
    checkPasswordEquality();
  };

  const handleOnClick = () => {
    if (email && password) {
      const userDetails = {
        email: email,
        password: password,
      };
      setUser(userDetails);
    } else {
      setUser(undefined);
    }
  };

  const acceptEmailSuggestion = () => {
    setEmail(suggestedEmail);
    resetEmailValidation && resetEmailValidation();
  };

  return (
    <Form onSubmit={() => onSubmit(user)} {...props} className={className}>
      <Input
        type={FormInputType.TEXT}
        name='email'
        placeholder={t('yourEmail')}
        required={true}
        value={email || ''}
        onChange={setEmail}
        subText={emailSuggestion || emailError}
        subTextAction={emailSuggestion ? acceptEmailSuggestion : undefined}
        error={!!emailSuggestion || !!emailError}
        onBlur={validateEmailCheck}
        className={styles.inputs}
        autoComplete='email'
      />
      <Input
        type={FormInputType.PASSWORD}
        name='password'
        placeholder={t('password')}
        required={true}
        value={password || ''}
        onChange={setPassword}
        subText={passwordError}
        error={!!passwordError}
        onBlur={validatePassword}
        className={styles.inputs}
        autoComplete='new-password'
      />
      <Input
        type={FormInputType.PASSWORD}
        name='confirmpassword'
        placeholder={t('confirmPassword')}
        required={true}
        value={confirmPassword || ''}
        onChange={setConfirmPassword}
        subText={confirmPasswordError}
        error={!!confirmPasswordError}
        onBlur={checkPasswordEquality}
        className={styles.inputs}
        autoComplete='new-password'
      />
      {formError && (
        <span
          className={styles.formError}
          data-testid='create-account-form-error'
        >
          {formError}
        </span>
      )}
      <Button
        type='submit'
        className={styles.nextButton}
        label={t('next')}
        variant={ButtonVariants.PRIMARY}
        size={ButtonSizes.LARGE}
        theme={ButtonThemes.JOIN_FLOW}
        disabled={isSubmitDisabled}
        onClick={handleOnClick}
      />
    </Form>
  );
}
