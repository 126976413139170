import React from 'react';
import { DropdownOption, DropdownThemes } from '../../../types/Dropdown';
import styles from './dropdownSelect.module.scss';
import { classes } from '../../../utils';

export type DropdownSelectProps = {
  options: DropdownOption[];
  onOptionSelected: (option: string) => void;
  selectedOption?: string;
  label?: string;
  theme?: DropdownThemes;
  round?: boolean;
  autoComplete?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const DropdownSelect = ({
  options,
  onOptionSelected,
  selectedOption,
  label,
  className,
  theme = DropdownThemes.DEFAULT,
  round = true,
  autoComplete,
  ...props
}: DropdownSelectProps) => {
  return (
    <div
      className={classes(
        styles.dropdown,
        'whoop-typography',
        theme !== DropdownThemes.DEFAULT && `theme-${theme}`,
        className,
      )}
      {...props}
    >
      <select
        className={classes(
          styles.dropdownButton,
          round ? styles.round : styles.squared,
        )}
        autoComplete={autoComplete}
        onChange={(e) => onOptionSelected(e.target.value)}
        value={selectedOption ?? ''}
      >
        {label && (
          <option value={''} data-testid={'label-option'} disabled>
            {label}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
