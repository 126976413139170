import React from 'react';
import { BasicComponentProps } from '../../types';
import styles from './auto-complete-input.module.scss';
import { classes } from '../../utils';

export type AutoCompleteResultItemProps = {
  children: JSX.Element;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClick: Function;
} & BasicComponentProps;

export const AutoCompleteResultItem = ({
  children,
  onClick,
  className,
}: AutoCompleteResultItemProps) => {
  return (
    <div
      className={classes(className, styles.resultItemContainer)}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
