import React, { useState, useRef } from 'react';
import { Input } from '../Input';
import { BasicComponentProps } from '../../types';
import { AutoCompleteResultsContainer } from './AutoCompleteResultsContainer';
import { FormInputType } from '../../types/FormInputType';
import styles from './auto-complete-input.module.scss';

export interface Search {
  (text: string): Promise<AutoCompleteResult[]>;
}

export interface OnSelect {
  (result: AutoCompleteResult): Promise<AutoCompleteResultUpdate>;
}

export interface AutoCompleteResultUpdate {
  text: string;
  results: AutoCompleteResult[];
}

export interface AutoCompleteResult {
  element: JSX.Element;
  result: any;
  key: string;
}

export type AutoCompleteInputProps = {
  name: string;
  placeholder: string;
  defaultValue?: string;
  disabled?: boolean;
  search: Search;
  onSelect: OnSelect;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange?: Function;
} & BasicComponentProps;

export const AutoCompleteInput = ({
  name,
  search,
  onSelect,
  onChange: onChangeProp,
  ...props
}: AutoCompleteInputProps) => {
  const [value, setValue] = useState('');
  const [searchResults, setSearchResults] = useState<AutoCompleteResult[]>([]);
  const [hasFocus, setHasFocus] = useState(false);
  const [selected, setSelected] = useState(false);

  const inputRef = useRef(null);

  const onChange = (text: string) => {
    if (text === value) {
      return;
    }
    if (onChangeProp) {
      onChangeProp(text);
    }
    setSelected(false);
    setValue(text);
    search(text).then(setSearchResults);
  };

  const clearSearchResults = () => {
    setSearchResults([]);
  };

  const _onSelect: OnSelect = async (result: AutoCompleteResult) => {
    const update = await onSelect(result);
    setValue(update.text);
    setSearchResults(update.results);
    if (!update.results.length) {
      setSelected(true);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    inputRef.current.focus();
    return update;
  };

  const onBlur = () => {
    if (!hasFocus) {
      clearSearchResults();
    }
  };

  const onMouseEnter = () => {
    setHasFocus(true);
  };

  const onMouseLeave = () => {
    setHasFocus(false);
    if (document.activeElement !== inputRef.current) {
      clearSearchResults();
    }
  };

  const onFocus = () => {
    if (!searchResults?.length && !selected) {
      search(value).then(setSearchResults);
    }
  };

  return (
    <div className={styles.inputContainer}>
      <Input
        type={FormInputType.TEXT}
        useExternalState={true}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        autoComplete='nottoday'
        ref={inputRef}
        {...props}
      />
      {searchResults?.length > 0 && (
        <AutoCompleteResultsContainer
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onSelect={_onSelect}
          results={searchResults}
        />
      )}
    </div>
  );
};
