import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { c } from '../../../utils';
import { RadioInputProps, RadioToggleThemes } from '../../../types/RadioInputs';

import styles from './radio-toggle.module.scss';

export const RadioToggle = ({
  className,
  name,
  onChange,
  options,
  size,
  useExternalState,
  value,
  allowNone = false,
  theme = RadioToggleThemes.DEFAULT,
  ...props
}: RadioInputProps): JSX.Element | null => {
  const [selectedValue, setSelectedValue] = useState(value);

  // set value when parent updates value
  useEffect(() => {
    if (value != null) {
      setSelectedValue(value);
    } else if (!allowNone) {
      setSelectedValue(options?.[0]?.value);
    }
  }, [value]);

  if (!options.length) return null;

  const changeValue = (value: string | number) => {
    if (value === selectedValue) return;
    onChange && onChange(value);
    setSelectedValue(value);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    changeValue(value);
  };

  const actualValue = useExternalState ? value : selectedValue;

  return (
    <div
      aria-labelledby={name}
      className={c(
        styles.toggleButton,
        styles[`${size || 'medium'}`],
        theme !== RadioToggleThemes.DEFAULT && `theme-${theme}`,
        className,
      )}
      role='radiogroup'
      {...props}
    >
      {options?.map(({ label, value }, index) => {
        return (
          <Fragment key={`${name}-${value}`}>
            <input
              type='radio'
              id={`radio-toggle-${name}-${index}`}
              name={name}
              value={value}
              checked={value === actualValue}
              onChange={handleOnChange}
              aria-label={label}
            />
            <label htmlFor={`radio-toggle-${name}-${index}`}>{label}</label>
          </Fragment>
        );
      })}
    </div>
  );
};
