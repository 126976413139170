import React from 'react';
import PropTypes from 'prop-types';
import {
  AutoCompleteInput,
  AutoCompleteResult,
  AutoCompleteResultUpdate,
  Search,
} from '../AutoCompleteInput';
import {
  searchAddresses,
  searchAddressesWithSelection,
} from './address-lookup-service';

export type AddressAutoCompleteProps = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSelect: Function;
  className?: string;
  smartyApiKey: string;
};

export type AddressSuggestion = {
  // eslint-disable-next-line camelcase
  street_line: string;
  secondary?: string;
  entries: number;
  city: string;
  state: string;
  zipcode: string;
};

export type AddressSuggestions = {
  suggestions: AddressSuggestion[];
};

const formatSuggestion = (suggestion: AddressSuggestion) => {
  let formattedValue = '';
  formattedValue += suggestion.street_line;
  if (suggestion.secondary) {
    formattedValue += ` ${suggestion.secondary}`;
  }
  if (suggestion.entries > 1) {
    formattedValue += ` (${suggestion.entries} more entries)`;
  }
  return (
    formattedValue +
    ` ${suggestion.city}, ${suggestion.state}, ${suggestion.zipcode}`
  );
};

const formatResults = (result: AddressSuggestions): AutoCompleteResult[] => {
  const suggestions = result.suggestions;
  if (!suggestions) return [];
  return suggestions.map((a) => {
    const formattedSuggestion = formatSuggestion(a);
    return {
      element: <>{formattedSuggestion}</>,
      result: a,
      key: formattedSuggestion,
    };
  });
};

/*
  Note this component is dependent on Smarty: https://www.smarty.com/products/us-address-autocomplete
  As of 9/21/2022 we are in a trial period with Smarty
  please reach out to Patrick Cavanaugh if you have a need for this component
*/
export const AddressAutoComplete = ({
  onSelect,
  smartyApiKey,
  ...props
}: AddressAutoCompleteProps) => {
  const _onSelect = async ({
    result,
  }: {
    result: AddressSuggestion;
  }): Promise<AutoCompleteResultUpdate> => {
    if (result.entries > 1) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const selectedAddress = `${result.street_line} ${result.secondary} (${result.entries}) ${result.city} ${result.state} ${result.zipcode}`;
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const searchText = `${result.street_line} ${result.secondary}`;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const detailedResult = await searchAddressesWithSelection(
        smartyApiKey,
        searchText,
        selectedAddress,
      );
      return {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        text: `${result.street_line} ${result.secondary}`,
        results: formatResults(detailedResult),
      };
    }
    onSelect(result);
    return {
      text: `${result.street_line}`,
      results: [],
    };
  };
  const search: Search = async (searchText) => {
    if (!searchText) {
      return [];
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const result = await searchAddresses(smartyApiKey, searchText);
    return formatResults(result);
  };

  return (
    <>
      <AutoCompleteInput
        search={search}
        name='addressAutoComplete'
        placeholder='Address'
        onSelect={_onSelect}
        {...props}
      />
    </>
  );
};

AddressAutoComplete.propTypes = {
  onSelect: PropTypes.func.isRequired,
  smartyApiKey: PropTypes.string.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
};
