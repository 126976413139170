import { MembershipStatus } from '../types/MembershipStatus';
import { Statuses } from '../types/Statuses';
import { WhoopProStatus } from '../types/WhoopProStatus';

export const getSubscriptionColor = (
  membershipStatus: MembershipStatus,
): Statuses => {
  switch (membershipStatus) {
    case MembershipStatus.ACTIVE:
      return Statuses.SUCCESS;
    case MembershipStatus.TRIALING:
      return Statuses.INFO;
    case MembershipStatus.INVALID:
    case MembershipStatus.CANCELED:
    case MembershipStatus.PAST_DUE:
      return Statuses.ERROR;
    default:
      return Statuses.NEUTRAL;
  }
};

export const getWhoopProColor = (whoopProStatus: WhoopProStatus): Statuses => {
  switch (whoopProStatus) {
    case WhoopProStatus.ACTIVE:
      return Statuses.SUCCESS;
    case WhoopProStatus.EXPIRING:
      return Statuses.WARN;
    case WhoopProStatus.INELIGIBLE:
      return Statuses.ERROR;
    default:
      return Statuses.NEUTRAL;
  }
};
