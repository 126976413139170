import React from 'react';
import {
  useController,
  useFormContext,
  Validate,
  FieldValues,
} from 'react-hook-form';
import { NumberInput } from '../NumberInput';
import { BasicComponentProps } from '../../types';

export type FormNumberInputProps = {
  name: string;
  min: {
    value: number;
    message: string;
  };
  max?: {
    value: number;
    message: string;
  };
  defaultValue?: number;
  required?: {
    value: boolean;
    message: string;
  };
  validate?: Validate<FieldValues, any>;
  pattern?: {
    value: RegExp;
    message: string;
  };
  stepSize?: number;
  allowDecimals?: boolean;
  disabled?: boolean;
} & BasicComponentProps;

export const FormNumberInput = ({
  name,
  required,
  validate,
  pattern,
  min,
  max,
  defaultValue,
  ...props
}: FormNumberInputProps) => {
  const { control } = useFormContext();

  const {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    field: { onChange, name: fieldName, value, ref },
  } = useController({
    name,
    control,
    rules: { required, min, max, validate, pattern },
    defaultValue: defaultValue || min,
  });

  return (
    <NumberInput
      useExternalState
      name={fieldName}
      value={value as number}
      onChange={onChange}
      min={min && min.value}
      max={max && max.value}
      inputRef={ref}
      {...props}
    />
  );
};
