import React from 'react';
import PropTypes from 'prop-types';
import WhoopLogo from '../../assets/whoop-logo.svg';
import WhoopLogoTrademark from '../../assets/whoop-logo-trademark.svg';
import WhoopLogoW from '../../assets/whoop-logo-w.svg';
import WhoopLogoCircle from '../../assets/whoop-logo-circle.svg';

export const Logo = ({ variant = 'normal', ...props }) => {
  if (variant === 'normal') {
    return <WhoopLogo {...props} />;
  } else if (variant === 'circle') {
    return <WhoopLogoCircle {...props} />;
  } else if (variant === 'trademark') {
    return <WhoopLogoTrademark {...props} />;
  }
  return <WhoopLogoW {...props} />;
};

Logo.propTypes = {
  /**
   * Logo style
   */
  variant: PropTypes.oneOf(['normal', 'trademark', 'square', 'circle']),
};
