/* eslint-disable react/jsx-no-bind */

import React, { useEffect, useMemo, useState } from 'react';
import { importTranslations, useTranslation } from '../../../utils/i18n';
import styles from './engraving.module.scss';
import { Input } from '../../Input';
import { FormInputType } from '../../../types/FormInputType';
import {
  completeEngraving,
  ENGRAVING_ACTIVITY_ICONS,
  WHOOP_ID_ENGRAVING_ACTIVITY_ICONS,
  EngravingData,
  EngravingIconField,
  EngravingInfo,
  EngravingTextField,
  EngravingType,
  getEngravingFields,
  getLabelFromIconName,
  getTextEngravingError,
} from './engravingUtils';
import { classes } from '../../../utils';
import { Icon } from '../../Icon';
import { RadioToggle } from '../../RadioSelectors/RadioToggle';
import { MediaItem, ProductMedia } from '../../../index';
import {
  defaultTransform,
  engravingImageToTransform,
  getFieldHeight,
  PREVIEW_GAP,
  sumFieldHeights,
  transformToCss,
} from './engravingPreviewUtils';
import CaretDownIcon from '../../../icons/Navigation/ic_caret_down.svg';

importTranslations('engraving', require.context('./languages/', true));

/**
 * Icon Selector - Input field for selection icons
 * Only purpose is to output a string via onChange that is a valid icon name.
 */
export interface IconSelectorProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  type: EngravingType;
  value: string;
  onChange: (value: string) => void;
}
function IconSelector({
  type,
  value,
  onChange,
  className,
  ...props
}: IconSelectorProps) {
  const { t } = useTranslation('engraving');
  const [selectedIcon, setSelectedIcon] = useState<string>();
  const [showSelector, setShowSelector] = useState(true);
  const text = selectedIcon
    ? t('icon', { label: getLabelFromIconName(selectedIcon) })
    : t('select-icon');
  const selectIcon = (icon: string) => {
    setSelectedIcon(icon);
    setShowSelector(false);
    onChange && onChange(icon);
  };
  useEffect(() => setSelectedIcon(value), [value]);

  return (
    <>
      <div className={classes(styles.iconSelector, className)} {...props}>
        <div
          className={classes(
            styles.selectorText,
            !!selectedIcon && styles.selected,
          )}
          onClick={() => !!selectedIcon && setShowSelector(!showSelector)}
        >
          {text}
          <CaretDownIcon />
        </div>

        {showSelector &&
          type === 'gen4_strap_icon' &&
          ENGRAVING_ACTIVITY_ICONS.map((icon) => (
            <Icon
              key={icon}
              name={icon}
              className={classes(icon === selectedIcon && styles.selected)}
              onClick={() => selectIcon(icon)}
            />
          ))}
        {showSelector &&
          type === 'gen4_id_icon' &&
          WHOOP_ID_ENGRAVING_ACTIVITY_ICONS.map((icon) => (
            <Icon
              key={icon}
              name={icon}
              className={classes(icon === selectedIcon && styles.selected)}
              onClick={() => selectIcon(icon)}
            />
          ))}
      </div>
      {!showSelector && selectedIcon && (
        <Icon className={styles.iconPreview} name={selectedIcon} />
      )}
    </>
  );
}

/**
 * Engraving Form - Form (collection of inputs) based on an EngravingType.
 *
 * Given an engraving type will output a complete EngravingData.
 * Complete means that all values are included even if they are empty.
 * This is necessary since our system cannot handle partial engraving data.
 */
export interface EngravingFormProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  type: EngravingType;
  value?: EngravingData;
  onChange?: (value: EngravingData | undefined) => void;
}
export function EngravingForm({
  type,
  value,
  onChange,
  className,
  ...props
}: EngravingFormProps) {
  const { t } = useTranslation('engraving');
  const fields = useMemo(() => getEngravingFields(type, t), [type, t]);
  const [data, setData] = useState(value);
  const updateText = (field: EngravingTextField, newValue: string) => {
    const error = getTextEngravingError(
      newValue,
      field.scoreType,
      field.engravingType,
      t,
    );
    const newData = completeEngraving(
      type,
      {
        ...data,
        [field.name]: { value: newValue?.toUpperCase(), error },
      },
      fields,
    );
    setData(newData);
    onChange && onChange(newData);
  };

  const updateIcon = (field: EngravingIconField, newValue: string) => {
    const newData = completeEngraving(
      type,
      {
        ...data,
        [field.name]: { value: newValue },
      },
      fields,
    );
    setData(newData);
    onChange && onChange(newData);
  };

  useEffect(() => setData(value), [value]);

  return (
    <div className={classes(styles.engravingForm, className)} {...props}>
      {fields.map((field) => {
        if (field.type === 'text') {
          return (
            <Input
              useExternalState={true}
              key={`${type}-${field.name}`}
              placeholder={field.label}
              type={FormInputType.TEXT}
              value={data?.[field.name]?.value || ''}
              error={!!data?.[field.name]?.error}
              subText={data?.[field.name]?.error}
              onChange={updateText.bind(null, field)}
              autoComplete='off'
            />
          );
        } else if (field.type === 'icon') {
          return (
            <IconSelector
              type={type}
              key={`${type}-${field.name}`}
              value={data?.[field.name]?.value || ''}
              onChange={updateIcon.bind(null, field)}
            />
          );
        } else {
          return undefined;
        }
      })}
    </div>
  );
}

/**
 * Engraving Selector - Selector based on a list of engraving types.
 * Given a list of engraving types will only output a single engraving.
 * Can optionally be given a global error to display
 */
export interface EngravingSelectorProps {
  value?: EngravingInfo;
  onChange?: (value: EngravingInfo) => void;
  engravingTypes: EngravingType[];
  error?: string;
}

export function EngravingSelector({
  value,
  onChange,
  engravingTypes,
  error,
}: EngravingSelectorProps) {
  const { t } = useTranslation('engraving');
  const [type, setType] = useState(value?.type || engravingTypes[0]);
  const [engravings, setEngravings] = useState<
    Record<EngravingType, EngravingData>
  >(
    (value ? { [value.type]: value.data } : {}) as Record<
      EngravingType,
      EngravingData
    >,
  );

  useEffect(() => {
    onChange && onChange({ type: type, data: engravings[type] });
  }, [engravings, type]);

  useEffect(() => {
    if (value) {
      setType(value.type);
      setEngravings({ ...engravings, [value.type]: value.data });
    }
  }, [value]);

  return (
    <div className={styles.engravingSelector}>
      <h2>{t('engraving-heading')}</h2>

      {engravingTypes?.length > 1 && (
        <RadioToggle
          className={styles.toggle}
          name='engraving-type'
          value={type}
          onChange={setType}
          options={engravingTypes.map((type) => ({
            value: type,
            label: t(`type.${type}`),
          }))}
        />
      )}
      <EngravingForm
        type={type}
        value={engravings[type]}
        onChange={(data) => setEngravings({ ...engravings, [type]: data })}
        className={styles.engraving}
      />
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
}

/**
 * This can be used in replacement for product media.
 * It takes in a MediaItem and EngravingInfo and renders an overlay of EngravingInfo on top of the MediaItem.
 */
export interface EngravingPreviewProps
  extends React.HTMLAttributes<HTMLDivElement> {
  engraving: EngravingInfo | undefined;
  media: MediaItem;
}
export function EngravingPreview({
  engraving,
  media,
  ...props
}: EngravingPreviewProps) {
  const fields = useMemo(
    () => engraving?.type && getEngravingFields(engraving?.type, (t) => t),
    [engraving?.type],
  );
  const transform = useMemo(
    () => engravingImageToTransform(media?.url) || defaultTransform(),
    [media?.url],
  );
  const totalHeight = fields
    ? sumFieldHeights(fields) + Math.max(fields.length - 1, 0) * PREVIEW_GAP
    : 0;

  return (
    <div className={styles.previewContainer} {...props}>
      <ProductMedia {...media} />
      <svg
        className={styles.preview}
        style={{ transform: transformToCss(transform) }}
        width='100%'
        height='100%'
        viewBox='0 0 100 100'
        preserveAspectRatio='xMinYMid meet'
      >
        {fields?.map((field, i, arr) => {
          const fieldsSoFar = arr.slice(0, i + 1);
          const heightSoFar =
            sumFieldHeights(fieldsSoFar) +
            Math.max(fieldsSoFar.length - 1, 0) * PREVIEW_GAP;
          if (engraving?.data?.[field.name]?.error) {
            return undefined;
          }
          if (field.type === 'icon') {
            const icon = engraving?.data?.[field.name]?.value;
            return (
              icon && (
                <Icon
                  className={styles.icon}
                  name={icon}
                  key={`${engraving?.type}-${field.name}`}
                  width={30}
                  height={30}
                  x={50 - 15}
                  y={50 - 30 - totalHeight / 2 + heightSoFar}
                />
              )
            );
          }
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          const textKey = `${engraving?.type}-${field.name}`;
          return (
            <text
              key={textKey}
              fontSize={getFieldHeight(field)}
              x={50}
              y={50 - totalHeight / 2 + heightSoFar}
            >
              {engraving?.data?.[field.name]?.value}
            </text>
          );
        })}
      </svg>
    </div>
  );
}
