import React from 'react';
import { c } from '../../utils';
import styles from './layered-image.module.scss';
import { BasicComponentProps } from '../../types';

export type LayeredImageProps = BasicComponentProps;

/**
 * Component to layer multiple images on top of each other, can be used to
 * create a composite image like WHOOP Your Way.
 *
 * @param children children must be img components
 */
export function LayeredImage({
  children,
  className,
  ...props
}: LayeredImageProps): JSX.Element {
  return (
    <div className={c(styles.layeredImage, className)} {...props}>
      {children}
    </div>
  );
}
