import React, { useEffect } from 'react';
import { ProductItem, ProductNode } from '../../types/Products';
import { BasicComponentProps } from '../../types';
import styles from './mini-product-selector.module.scss';
import { SwatchOptionSelect } from '../deprecated/SwatchOptionSelect';
import { importTranslations, useTranslation } from '../../utils/i18n';
import {
  getMediaFromSelection,
  useFlattenedProductOptions,
} from '../../utils/productUtils';
import { Badge, BadgeColors } from '../Badge';
importTranslations(
  'miniProductSelector',
  require.context('./languages/', true),
);

export type MiniProductSelectorProps = {
  node?: ProductNode;
  value?: ProductItem;
  onChange?: (item: ProductItem) => any;
  productIndex?: number;
  onClickFullDetails?: (
    selection: ProductItem | undefined,
    node: ProductNode | undefined,
  ) => void;
} & BasicComponentProps;

export function MiniProductSelector({
  node,
  value,
  onChange,
  productIndex,
  onClickFullDetails,
}: MiniProductSelectorProps) {
  const { t } = useTranslation('miniProductSelector');

  const [selection, sizeSelectProps, inseamSelectProps, colorSelectProps] =
    useFlattenedProductOptions(node, value);

  useEffect(() => {
    onChange && onChange(selection);
  }, [selection]);

  const selectionImages = getMediaFromSelection(selection, node);
  let imageSrc = null;
  if (selectionImages && selectionImages.length > 0) {
    imageSrc = selectionImages[0].url;
  }

  const badgeIsNeeded =
    selection?.new === true ||
    selection?.pro_exclusive === true ||
    selection?.quantity === 0;

  const renderBadge = () => {
    if (selection?.coming_soon) {
      return (
        <Badge className={styles.miniProductBadge} label={t('comingSoon')} />
      );
    }
    if (selection?.quantity === 0) {
      return (
        <Badge
          className={styles.miniProductBadge}
          label={t('outOfStock')}
          color={BadgeColors.GRAY}
        />
      );
    }
    if (selection?.pro_exclusive) {
      return (
        <Badge
          className={styles.miniProductBadge}
          label={t('whoopProExclusive')}
          color={BadgeColors.PRO}
        />
      );
    }
    if (selection?.on_sale) {
      return <Badge className={styles.miniProductBadge} label={t('onSale')} />;
    }
    if (selection?.new) {
      return <Badge className={styles.miniProductBadge} label={t('new')} />;
    }
  };

  return (
    <div className={styles.productCard}>
      <h3 className={styles.miniDescription}>
        {`${(productIndex || 0) + 1}. ${node ? node.product_info.title : ''}`}
      </h3>
      <div className={styles.itemInfo}>
        <div className={styles.thumb}>
          {imageSrc && (
            <img src={imageSrc} alt={node ? node.product_info.title : ''} />
          )}
        </div>
        <div className={styles.info}>
          <div className={styles.variants}>
            {colorSelectProps && (
              <>
                <div>
                  <SwatchOptionSelect
                    name={`${productIndex || 0}-color`}
                    size={'small'}
                    {...colorSelectProps}
                  />
                </div>
              </>
            )}
            <h3>
              <span className={styles.selectedOption}>{selection?.title}</span>
              {badgeIsNeeded && renderBadge()}
            </h3>
            <div className={styles.selectors}>
              {inseamSelectProps && (
                <>
                  <div>
                    <SwatchOptionSelect
                      name={`${productIndex || 0}-inseam`}
                      size={'small'}
                      {...inseamSelectProps}
                    />
                  </div>
                </>
              )}
              {inseamSelectProps && sizeSelectProps && (
                <div className={styles.vertBar} />
              )}
              {sizeSelectProps && (
                <>
                  <div>
                    <SwatchOptionSelect
                      name={`${productIndex || 0}-size`}
                      size={'small'}
                      {...sizeSelectProps}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={styles.productLink}>
            {onClickFullDetails && (
              <a
                onClick={() => onClickFullDetails(selection, node)}
                rel='noopener noreferrer'
                aria-label={node?.product_info.title}
                className='no-color'
              >
                <div className={styles.productLinkText}>
                  {t('seeFullProductDetails')}
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
