import {
  MediaItem,
  ProductInfo,
  ProductItem,
  ProductNode,
  ProductOption,
} from '../types/Products';
import { useEffect, useMemo, useState } from 'react';
import { SwatchOption } from '../components/deprecated/SwatchOptionSelect';
import { Optional } from '../types';
import {
  ENGRAVING_TYPES,
  EngravingType,
} from '../components/Growth/Engraving/engravingUtils';
import { noop } from 'lodash';

export const SIZE_ORDER = [
  'xxxs',
  '3xs',
  'xxs',
  '2xs',
  'xs',
  's',
  'm',
  'l',
  'xl',
  'xxl',
  '2xl',
  'xxxl',
  '3xl',
];

type OptionType = 'color' | 'size' | 'inseam';
const OPTION_TYPES: OptionType[] = ['color', 'size', 'inseam'];

export type Gender = 'unisex' | 'womens' | 'mens';
const GENDERS: Gender[] = ['unisex', 'womens', 'mens'];

export type ProductOptionSelectProps = {
  value: string;
  onChange: (handle: string) => any;
  options: SwatchOption[];
};

export type MultiProductOptionProps = {
  label?: string;
  subLabel?: string;
} & ProductOptionSelectProps;

/**
 * Returns a memoized sorted list of sizes
 * @param sizes list of ProductOptions
 */
const useSortedSizes = (sizes: ProductOption[]) =>
  useMemo(
    () =>
      sizes?.sort(
        (o1, o2) =>
          SIZE_ORDER.indexOf(o1.handle.toLowerCase()) -
          SIZE_ORDER.indexOf(o2.handle.toLowerCase()),
      ),
    [sizes],
  );

/**
 * Returns a flattened list of ProductItem's from a node
 * @param node a ProductNode
 */
export const flattenNodeItems = (
  node: ProductNode | undefined,
): ProductItem[] =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  node?.product_info?.items
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: flat is supported
    ?.concat(node?.children?.map(flattenNodeItems).flat());

// Memoized flat node items
const useFlattenNodeItems = (node: ProductNode | undefined): ProductItem[] =>
  useMemo(() => flattenNodeItems(node), [node]);

const isFalsyOrEmpty = (value: any) =>
  value instanceof Array ? value.length <= 0 : !value;

// recursive helper only intended to be used by getFromSelection()
const getFromSelectionRecurse = <T>(
  selection: Optional<ProductItem>,
  node: Optional<ProductNode>,
  getFn: (productInfo: ProductInfo | undefined) => Optional<T>,
  fallback?: Optional<T>,
): Optional<T> => {
  const isItemInThisNode = !!node?.product_info?.items?.find(
    ({ sku }) => selection?.sku && selection?.sku === sku,
  );
  const fromThis = getFn(node?.product_info);
  const t = isFalsyOrEmpty(fromThis) ? fallback : fromThis;
  const fromChildren = node?.children
    ?.map((n) => getFromSelectionRecurse(selection, n, getFn, t))
    ?.find((t) => !!t);
  return isItemInThisNode ? t : fromChildren;
};

/**
 * Searches for a product info value for a given ProductItem selection.
 * Search happens in a waterfall style, meaning if there is no value available
 *  for a given node, it will fallback to it's parent.
 *
 * The methods below use this selection logic
 *
 * @param selection ProductItem from within this node
 * @param node ProductNode to search
 * @param getFn Function to get the actual info
 */
const getFromSelection = <T>(
  selection: Optional<ProductItem>,
  node: Optional<ProductNode>,
  getFn: (productInfo: ProductInfo | undefined) => Optional<T>,
): Optional<T> => {
  if (!node) return;
  const t = getFromSelectionRecurse(selection, node, getFn);
  if (!t) {
    return getFn(node?.product_info); // default to parent
  }
  return t;
};

/**
 * Searches for a product description for a given ProductItem selection.
 * Search happens in a waterfall style, meaning if there is no value available
 *  for a given node, it will fallback to it's parent.
 *
 * @param selection selected ProductItem from within this node
 * @param node node to search
 */
export const getDescriptionFromSelection = (
  selection: Optional<ProductItem>,
  node: Optional<ProductNode>,
): Optional<string> =>
  getFromSelection(selection, node, (info) => info?.description);

/**
 * Searches for a product featured media for a given ProductItem selection.
 * Search happens in a waterfall style, meaning if there is no value available
 *  for a given node, it will fallback to it's parent.
 *
 * @param selection selected ProductItem from within this node
 * @param node node to search
 */
export const getFeaturedMediaFromSelection = (
  selection: Optional<ProductItem>,
  node: Optional<ProductNode>,
): Optional<MediaItem> =>
  getFromSelection(selection, node, (info) => info?.featured_media);

/**
 * Searches for the ProductInfo for a given ProductItem selection.
 * Search happens in a waterfall style, meaning if there is no value available
 *  for a given node, it will fallback to it's parent.
 *
 * @param selection selected ProductItem from within this node
 * @param node node to search
 */
export const getProductInfoFromSelection = (
  selection: Optional<ProductItem>,
  node: Optional<ProductNode>,
): Optional<ProductInfo> => getFromSelection(selection, node, (info) => info);

/**
 *
 * @param selection
 * @param node
 */
export const getProductPagesFromSelection = (
  selection?: ProductItem,
  node?: ProductNode,
): Optional<string[]> =>
  getFromSelection(selection, node, (info) => info?.product_pages);

/**
 * Searches for product media for a given ProductItem selection.
 * Search happens in a waterfall style, meaning if there is no value available
 *  for a given node, it will fallback to it's parent.
 *
 * @param selection selected ProductItem from within this node
 * @param node node to search
 */
export const getMediaFromSelection = (
  selection: ProductItem | undefined,
  node?: ProductNode,
): Optional<MediaItem[]> => {
  if (selection?.media?.length && selection?.media?.length > 0) {
    return selection.media;
  }
  return getFromSelection(selection, node, (info) => info?.media);
};

/**
 * Searches for product gender for a given ProductItem selection.
 * Search happens in a waterfall style, meaning if there is no value available
 *  for a given node, it will fallback to it's parent.
 *
 * @param selection selected ProductItem from within this node
 * @param node node to search
 */
export const getGenderFromSelection = (
  selection: ProductItem,
  node: ProductNode,
): Optional<Gender> => {
  return getFromSelection(selection, node, (info) =>
    GENDERS.find((g) => !!info?.[g]),
  );
};

/**
 * Searches for product sizing guide for a given ProductItem selection.
 * Search happens in a waterfall style, meaning if there is no value available
 *  for a given node, it will fallback to it's parent.
 *
 * @param selection selected ProductItem from within this node
 * @param node node to search
 */
export const getSizingGuideFromSelection = (
  selection: ProductItem,
  node: ProductNode,
): Optional<string> => {
  return getFromSelection(selection, node, (info) => info?.sizing_guide);
};

/**
 * Searches for product engraving types for a given ProductItem selection.
 * Search happens in a waterfall style, meaning if there is no value available
 *  for a given node, it will fallback to it's parent.
 *
 * @param selection selected ProductItem from within this node
 * @param node node to search
 */
export const getEngravingTypesFromSelection = (
  selection: ProductItem | undefined,
  node: ProductNode,
): Optional<EngravingType[]> => {
  if (!selection) {
    return;
  }

  return getFromSelection(selection, node, (info) => {
    const arr = ENGRAVING_TYPES.filter((t) => info?.[`engraving_${t}`]);
    return arr?.length > 0 ? arr : undefined;
  });
};

/**
 * Returns a list of props to use in a <SwatchSelector /> component
 * to handle selection logic
 *
 * @param type OptionType
 * @param options list of ProductOptions
 * @param selection from useProductSelectionState hook
 * @param updateSelection from useProductSelectionState hook
 * @param findSelection from useProductSelectionState hook
 * @param showIfOnlyOne will show swatches even if there is only one option (used for product hierarchy)
 * @param optionName in the case that this is a multi product it is possible that two multi product categories
 *     share the same option name. If this is present the selection will not just look at the product based on
 *     the handle, but will also include the option name.
 * @param quantityGroup used for grouping quantities by option. For example, if we want to cross out
 *     a particular color only if all of the sizes are OOS
 */
const createProductOptionSelectProps = (
  type: OptionType,
  options: ProductOption[],
  selection: ProductItem,
  updateSelection: (handle: string, type: string) => void,
  findSelection: (handle: string, type: string) => ProductItem | undefined,
  showIfOnlyOne?: boolean,
  optionName?: string,
  quantityGroup?: ProductItem[],
): ProductOptionSelectProps | undefined => {
  if (options?.length > 0 && (options?.length > 1 || showIfOnlyOne)) {
    return {
      value: selection?.[type]?.handle || '',
      onChange: (value) => updateSelection(value, type),
      options: options?.map(({ label, handle, swatch }) => {
        const selectionFound = findSelection(handle, type);
        const quantity = !quantityGroup
          ? selectionFound?.quantity
          : quantityGroup
              .filter((item) => item[type]?.handle === handle) // all items for this option
              .reduce((max, item) => Math.max(max, item.quantity || 0), 0);
        return {
          value: handle,
          label,
          background: swatch,
          disabled: !selectionFound,
          crossOut: quantity === undefined || quantity <= 0,
        };
      }),
    };
  }
};

/**
 * Returns the currently selected ProductItem and some utility functions to
 * update the currently selected item from a node
 *
 * @param node a ProductNode
 * @param value option ProductItem to override selection with
 * @param onOptionSelected optional callback for analytics or side effects
 */
const useProductSelectionState = (
  node: ProductNode | undefined,
  value: Optional<ProductItem>,
  onOptionSelected?: (
    option: string,
    type: string,
    item: ProductItem | undefined,
  ) => any,
): [
  selection: ProductItem,
  updateSelection: (handle: string, type: string) => void,
  findSelection: (handle: string, type: string) => ProductItem | undefined,
] => {
  const items = useFlattenNodeItems(node);
  const [selection, setSelection] = useState<ProductItem>(value || items?.[0]);
  // update state if external value changes
  useEffect(() => {
    if (value) {
      setSelection(value);
    }
  }, [value]);

  const findSelection = (handle: string, type: string) => {
    return items.find((product) =>
      OPTION_TYPES.reduce(
        (acc: boolean, typeToCheck) =>
          acc &&
          (type === typeToCheck
            ? product?.[typeToCheck]?.handle === handle
            : product?.[typeToCheck]?.handle ===
              selection?.[typeToCheck]?.handle),
        true,
      ),
    );
  };

  const updateSelection = (handle: string, type: string) => {
    const foundItem = findSelection(handle, type);
    onOptionSelected && onOptionSelected(handle, type, foundItem);
    if (foundItem) {
      setSelection(foundItem);
    }
  };

  return [selection, updateSelection, findSelection];
};

/**
 * Returns the currently selected ProductItem and Props to be used by a swatch selector
 * for all colors in a node
 * @param node a ProductNode
 * @param value optional value to override with
 * @param onOptionSelected optional callback for analytics or side effects
 */
export const useFlattenedProductOptions = (
  node: ProductNode | undefined,
  value: Optional<ProductItem>,
  onOptionSelected?: (
    option: string,
    type: string,
    item: ProductItem | undefined,
  ) => any,
  colorBasedCrossOut?: boolean,
): [
  selection: ProductItem,
  sizeSelectProps?: ProductOptionSelectProps,
  inseamSelectProps?: ProductOptionSelectProps,
  colorSelectProps?: ProductOptionSelectProps,
] => {
  const flatItems = useFlattenNodeItems(node);
  const [selection, updateSelection, findSelection] = useProductSelectionState(
    node,
    value,
    onOptionSelected,
  );
  const { colors, sizes, inseams } = node?.product_info || {};
  const filteredColors = colors ? colors.filter((c) => !!c) : [];
  const sortedSizes = useSortedSizes(sizes ? sizes.filter((s) => !!s) : []);
  const filteredInseams = inseams ? inseams.filter((i) => !!i) : [];

  const colorSelectProps = createProductOptionSelectProps(
    'color',
    filteredColors,
    selection,
    updateSelection,
    findSelection,
    false,
    undefined,
    colorBasedCrossOut ? flatItems : undefined,
  );
  const sizeSelectProps = createProductOptionSelectProps(
    'size',
    sortedSizes,
    selection,
    updateSelection,
    findSelection,
  );
  const inseamSelectProps = createProductOptionSelectProps(
    'inseam',
    filteredInseams,
    selection,
    updateSelection,
    findSelection,
  );

  return [selection, sizeSelectProps, inseamSelectProps, colorSelectProps];
};

/**
 * Returns the currently selected ProductItem and Props to be used by a swatch selector
 * splitting the colors if necessary
 * @param node a ProductNode
 * @param value optional value to override with
 * @param onOptionSelected optional callback for analytics or side effects
 */
export const useMultiProductOptions = (
  node: ProductNode | undefined,
  value: Optional<ProductItem>,
  onOptionSelected?: (
    option: string,
    type: string,
    item: ProductItem | undefined,
  ) => any,
): [
  selection: ProductItem,
  sizeSelectProps: ProductOptionSelectProps | undefined,
  inseamSelectProps: ProductOptionSelectProps | undefined,
  ...colorSelectProps: MultiProductOptionProps[]
] => {
  const [selection, updateSelection, findSelection] = useProductSelectionState(
    node,
    value,
    onOptionSelected,
  );
  const { colors, sizes, inseams } = node?.product_info || {};
  const sortedSizes = useSortedSizes(sizes ? sizes.filter((s) => !!s) : []);
  const filteredInseams = inseams ? inseams.filter((i) => !!i) : [];
  const sizeSelectProps = createProductOptionSelectProps(
    'size',
    sortedSizes,
    selection,
    updateSelection,
    findSelection,
  );
  const inseamSelectProps = createProductOptionSelectProps(
    'inseam',
    filteredInseams,
    selection,
    updateSelection,
    findSelection,
  );

  // if any children have a category label this is a multi-product
  const isMultiProduct = node?.children?.find(
    (n) => !!n.product_info?.category_label,
  );
  let multiProducts: MultiProductOptionProps[];

  if (isMultiProduct) {
    multiProducts =
      node?.children
        ?.sort((c1, c2) =>
          (c1?.product_info?.category_label || '').localeCompare(
            c2?.product_info?.category_label || '',
          ),
        )
        ?.sort((c1) => (c1?.product_info?.handle === 'remy' ? -1 : 0)) // BLB override so Remy is first
        ?.map((node) => ({
          label: node?.product_info?.category_label,
          ...createProductOptionSelectProps(
            'color',
            node?.product_info?.colors || [],
            selection,
            updateSelection,
            findSelection,
            true,
          ),
        }))
        ?.map((props) => ({
          ...props,
          value: props.value ? props.value : '',
          onChange: props.onChange ? props.onChange : noop,
          options: props.options ? props.options : [],
        })) || [];
  } else {
    const filteredColors = colors ? colors.filter((c) => !!c) : [];
    const productOptionSelectProps = createProductOptionSelectProps(
      'color',
      filteredColors,
      selection,
      updateSelection,
      findSelection,
    );
    if (productOptionSelectProps) {
      multiProducts = [
        {
          ...productOptionSelectProps,
        },
      ];
    } else {
      multiProducts = [];
    }
  }

  return [
    selection,
    sizeSelectProps,
    inseamSelectProps,
    ...multiProducts.filter((o) => !!Object.keys(o).length),
  ];
};
