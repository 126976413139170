import React, { useEffect, useState } from 'react';
import {
  Modal,
  PackDetails,
  PulsingLogo,
  ProductItem,
  ProductNode,
} from '@whoop/web-components';
import { trackWhoopAnalyticsEvent } from '../../utils/analytics';
import { useUpsellModal, useUser, useIsPro } from '../../redux/hooks';
import { formatPrice, useDiscountMessage } from '../../utils/priceUtils';
import { PATH_PREFIX } from '../../utils/regions';
import { classes, safeOpen } from '../../utils';
import styles from '../../templates/ProductPage/product-page.module.scss';
import JoinFlowCta from '../JoinFlowCta/JoinFlowCta';
import BuyBox from '../BuyBox/BuyBox';
import ProductHighlights from '../ProductHighlights/ProductHighlights';
import {
  useGetStaticRootHandle,
  useStaticProductNodes,
} from '../../hooks/staticProductHooks';
import {
  findFirstProductItem,
  isProductItemExclusive,
  ProductItemKey,
  useProductAccordions,
} from '../../utils/productUtils';
import { useShopifyProductData } from '../../utils/shopifyProductUtils';
import { usePurchasablePack } from '../../utils/purchasableUtils';
import { useToggleUpsellModal } from '../../redux/action-hooks';
import { Optional } from '@whoop/web-components/dist/types';

const getPackItemBySku = (
  node: ProductNode,
  skus: Optional<string>[],
): Optional<ProductItem> => {
  let packItem;
  if (skus.length === 0) {
    return undefined;
  }
  if (
    node?.children?.length === 0 &&
    node?.product_info &&
    node?.product_info.items
  ) {
    for (let i = 0; node.product_info.items.length > i; i++) {
      const item = node.product_info.items[i];
      if (skus.indexOf(item.sku) > -1) {
        packItem = item;
        skus.splice(skus.indexOf(item.sku), 1);
        break;
      }
    }
  } else {
    for (let i = 0; node.children.length > i; i++) {
      const child = node.children[i];
      const ret: Optional<ProductItem> = getPackItemBySku(child, skus);
      if (ret) {
        packItem = ret;
        break;
      }
    }
  }
  return packItem;
};

export default function UpsellModal() {
  const upsellProps = useUpsellModal();
  const isVisible = upsellProps?.isVisisble as boolean;
  const node = upsellProps?.node;
  const priceTag = upsellProps?.priceTag;
  const cartSkus = upsellProps?.cartSkus;
  const toggleUpsellModal = useToggleUpsellModal();
  const isPro = useIsPro();
  const packNodes = useStaticProductNodes(
    node?.product_info?.pack_details?.pack_items || [],
  );

  const [packItems, setPackItems] = useState<Optional<ProductItem>[]>([]);
  const purchasable = usePurchasablePack(node, packItems);
  useEffect(() => {
    if (cartSkus && cartSkus?.length !== 0) {
      const newState: Optional<ProductItem>[] = [];
      const allSkus = [...cartSkus];
      packNodes?.forEach((n, index) => {
        let packItem = getPackItemBySku(n, allSkus);
        if (!packItem) {
          packItem = findFirstProductItem(n);
        }
        newState[index] = packItem;
      });
      setPackItems(newState);
    }
  }, [node, cartSkus]);

  const accordions = useProductAccordions(purchasable?.item, node);
  const { variant, tags, earlyAccess } = useShopifyProductData(
    purchasable?.item?.sku,
  );
  const discountMessage = useDiscountMessage(variant, tags);
  const getStaticRootHandle = useGetStaticRootHandle();
  const user = useUser();
  const isExclusive = isProductItemExclusive(
    purchasable,
    ProductItemKey.pro_exclusive,
  );

  const onClickFullDetails = (item?: ProductItem, node?: ProductNode) => {
    const handle = getStaticRootHandle(node?.product_info?.handle || '');
    const sku = item?.sku;
    if (handle && sku) {
      safeOpen(`/${PATH_PREFIX}/products/${handle}/?sku=${sku}`, '_blank');
      trackWhoopAnalyticsEvent('Clicked full product details', {
        to_product: node?.product_info?.handle,
        to_sku: item?.sku,
      });
    }
  };

  return (
    <Modal show={isVisible} onClose={toggleUpsellModal}>
      <section
        className={classes(
          styles.productPage,
          'whoop-ui',
          styles.productPageModal,
        )}
      >
        {node && priceTag && (
          <PackDetails
            title={node?.product_info?.title || ''}
            price={priceTag?.price}
            originalPrice={priceTag?.originalPrice}
            isProDiscount={priceTag?.isProDiscount}
            withWhoopProPrice={
              isPro && priceTag.whoopProPrice
                ? formatPrice(priceTag.whoopProPrice)
                : undefined
            }
            node={node}
            value={packItems}
            onChange={setPackItems}
            onAnalyticsEvent={trackWhoopAnalyticsEvent}
            className={styles.productDetails}
            accordions={accordions}
            data-test-id='product-info'
            packNodes={packNodes}
            subTitle={discountMessage}
            onClickFullDetails={onClickFullDetails}
            footer={<JoinFlowCta />}
          >
            <BuyBox
              purchasable={purchasable}
              includeBuyItNow={false}
              withItemSwap={true}
            />
            <ProductHighlights
              tags={tags || []}
              userStrap={user?.strap}
              userAvatar={user?.avatarUrl}
              isExclusive={isExclusive}
              earlyAccess={earlyAccess}
            />
          </PackDetails>
        )}
        {!node && (
          <div className='loading-placeholder'>
            <PulsingLogo color='dark' size='large' />
          </div>
        )}
      </section>
    </Modal>
  );
}
