import React from 'react';
import PropTypes from 'prop-types';
import styles from './focus-background.module.scss';
import { c } from '../../utils';

/**
 * Primary UI component for user interaction
 */
export const FocusBackground = ({
  className,
  style,
  show,
  zIndex,
  ...props
}) => {
  return (
    <div
      className={c(className, styles.focusBackground, show && styles.show)}
      style={{ zIndex, ...style }}
      {...props}
    />
  );
};

FocusBackground.propTypes = {
  show: PropTypes.bool,
  zIndex: PropTypes.number,
};
