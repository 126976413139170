import React, { Fragment } from 'react';
import '../../index.module.scss';
import styles from './subtotal.module.scss';
import { c } from '../../utils';
import { Price } from '../Price';
import { BasicComponentProps } from '../../types';
import { SubtotalThemes } from '../../types/SubtotalThemes';

const defaultTheme = SubtotalThemes.DEFAULT;

export type SubtotalLine = {
  label: string;
  price: string;
  originalPrice?: string;
  isProDiscount?: boolean;
  calculatedAtCheckout?: boolean;
} & BasicComponentProps;

export type SubtotalProps = {
  lines: SubtotalLine[];
  theme?: SubtotalThemes;
} & BasicComponentProps;

export const Subtotal = ({
  lines,
  className,
  theme = defaultTheme,
  ...props
}: SubtotalProps) => (
  <div className={c(styles.subtotal, `theme-${theme}`, className)} {...props}>
    {lines?.map((line: SubtotalLine, index) => {
      const {
        label,
        calculatedAtCheckout,
        className: lineClassName,
        ...rest
      } = line;

      return (
        <Fragment key={label}>
          <div className={styles.line} aria-label={label}>
            <span
              className={c(
                styles.label,
                index === lines.length - 1 ? styles.total : undefined,
              )}
            >
              {label}
            </span>
            {calculatedAtCheckout ? (
              <span className={styles.atCheckout}>
                {SubtotalThemes.JOIN_FLOW ? '---' : 'Calculated at checkout'}
              </span>
            ) : (
              <Price
                className={c(
                  styles.price,
                  index === lines.length - 1 ? styles.total : undefined,
                  lineClassName,
                )}
                {...rest}
              />
            )}
          </div>
          {theme === SubtotalThemes.JOIN_FLOW && index !== lines.length - 1 && (
            <hr />
          )}
        </Fragment>
      );
    })}
  </div>
);
