import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import '../../index.module.scss';
import styles from './refer-a-friend.module.scss';
import { c } from '../../utils';
import SignUpIcon from '../../icons/Referral/ic_sign_up.color.svg';
import ReferAFriendIcon from '../../icons/Referral/ic_refer_a_friend.svg';
import SucceedRedIcon from '../../icons/Referral/ic_succeed_red.color.svg';
import SucceedGrayIcon from '../../icons/Referral/ic_succeed_gray.color.svg';
import { Button } from '../Button';
import { Form } from '../Form';
import { FormInput } from '../FormInput';
import { FormInputType } from '../../types/FormInputType';
import { ButtonSizes, ButtonThemes, ButtonVariants } from '../../types/Buttons';
import { Trans } from 'react-i18next';
import { useTranslation, importTranslations } from '../../utils/i18n';
import { emailRegex } from '../../utils/validation';
importTranslations('referAFriend', require.context('./languages/', true));

export const ReferAFriend = ({
  referralMessage,
  onShareClick = () => {},
  showEmailInput = false,
  onEmailSubmit = () => {},
  isCoachAccount = false,
  showRewardedMessage = false,
  className,
  ...props
}) => {
  const { t } = useTranslation('referAFriend');
  const [isDisabled, setIsDisabled] = useState(false);
  const [succeed, setSucceed] = useState(false);
  const [showShareLink, setShowShareLink] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);
  const [showManualToolTip, setShowManualToolTip] = useState(false);

  const isNotEnglish = i18next.language !== 'en';

  useEffect(() => {
    setIsDisabled(isCoachAccount);
  }, [isCoachAccount]);

  const hideToolTips = () => {
    setTimeout(() => {
      setShowToolTip(false);
      setShowManualToolTip(false);
    }, 3000);
  };

  const getReferLink = () => {
    const copyText = document.getElementById('referLink');

    navigator.clipboard
      .writeText(copyText.value)
      .then(() => {
        copyText.select();
        setShowToolTip(true);
        hideToolTips();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);

        copyText.select();
        setShowManualToolTip(true);
        hideToolTips();
      });
  };

  const succeedStep = () => {
    setSucceed(true);
    setIsDisabled(true);
    setShowShareLink(true);
    onShareClick();
  };

  const tooltip = (e) => {
    e.preventDefault();
    succeedStep();

    setTimeout(() => {
      getReferLink();
    }, 300);
  };

  const nativePhoneShare = (e) => {
    e.preventDefault();
    const data = {
      title: t('whoopReferral'),
      text: referralMessage,
    };

    succeedStep();

    navigator.share(data).catch((err) => {
      // eslint-disable-next-line no-console
      console.log('Error: ' + err);
    });
  };

  return (
    <div className={c(styles.referAFriend, className)} {...props}>
      <svg className={c(styles.stepTracker)}>
        <foreignObject
          width='80'
          height={isNotEnglish ? 35 : 28}
          x='0'
          y={isNotEnglish ? 0 : 10}
        >
          {t('signUp')}
        </foreignObject>
        <SignUpIcon x='25' y='38' width='30' height='30' />
        {succeed ? (
          <rect width='103' height='3' x='53' y='53' fill='rgb(255,0,38)' />
        ) : (
          <rect width='95' height='3' x='53' y='53' fill='rgb(255,0,38)' />
        )}
        <foreignObject
          width='150'
          height={isNotEnglish ? 45 : 40}
          x='95'
          y={isNotEnglish ? -5 : 10}
        >
          {t('referAFriend')}
        </foreignObject>
        {succeed ? (
          <g>
            <ReferAFriendIcon
              className={c(styles.referralIcon)}
              x='154'
              y='38'
              width='30'
              height='30'
            />
            <rect width='90' height='3' x='182' y='53' fill='rgb(255,0,38)' />
          </g>
        ) : (
          <g>
            <ReferAFriendIcon
              name='refer_a_friend'
              className={c(styles.referralIcon)}
              x='145'
              y='30'
              width='50'
              height='50'
            />
            <rect
              width='83'
              height='3'
              x='195'
              y='53'
              fill='rgb(235,235,235)'
            />
          </g>
        )}
        <foreignObject
          width='80'
          height={isNotEnglish ? 35 : 28}
          x='250'
          y={isNotEnglish ? 0 : 10}
        >
          {t('succeed')}
        </foreignObject>
        {succeed ? (
          <SucceedRedIcon x='264' y='30' width='50' height='50' />
        ) : (
          <SucceedGrayIcon x='275' y='38' width='30' height='30' />
        )}
      </svg>
      <div className={c(styles.title)}>
        {showRewardedMessage ? (
          <Trans t={t} i18nKey='referralRewardTitle'>
            Give 1 Month,
            <br />
            Get Rewarded
          </Trans>
        ) : (
          <Trans t={t} i18nKey='referralTitle'>
            Give 1 Month,
            <br />
            Get 1 Month Free
          </Trans>
        )}
      </div>
      <div className={c(styles.message)}>
        {showRewardedMessage ? (
          <Trans t={t} i18nKey='referralRewardedMessage'>
            Share your link to give your friends 1 month of membership free,{' '}
            <strong>get credit</strong> when they join
          </Trans>
        ) : (
          <Trans t={t} i18nKey='referralMessage'>
            Share your link to give your friends 1 month of membership free,{' '}
            <strong>get 1 month free</strong> when they join
          </Trans>
        )}
      </div>
      {showShareLink && (
        <div className={c(styles.link)}>
          <textarea
            id='referLink'
            rows={3}
            cols={40}
            defaultValue={referralMessage}
            readOnly
          />
        </div>
      )}
      {isCoachAccount && (
        <div data-test-id='coach-account-callout' className={c(styles.coach)}>
          {t('coachAccountsIneligible')}
        </div>
      )}
      {showEmailInput && (
        <Form
          onSubmit={({ referAFriendEmail }) => onEmailSubmit(referAFriendEmail)}
        >
          <FormInput
            className={c(styles.emailInput)}
            type={FormInputType.TEXT}
            name='referAFriendEmail'
            aria-label={t('email')}
            label={t('email')}
            placeholder={t('yourEmail')}
            required={{ value: true, message: t('emailRequired') }}
            pattern={{ value: emailRegex, message: t('enterValidEmail') }}
          />
          <Button
            type='submit'
            label={t('inviteFriends')}
            aria-label={t('inviteFriends')}
            variant={ButtonVariants.PRIMARY}
            size={ButtonSizes.MEDIUM}
            theme={ButtonThemes.DEFAULT}
          />
        </Form>
      )}
      {!showEmailInput &&
        (navigator.share ? (
          <Button
            className={c(styles.btnShare)}
            disabled={isDisabled}
            onClick={nativePhoneShare}
            variant={ButtonVariants.PRIMARY}
            size={ButtonSizes.MEDIUM}
            theme={ButtonThemes.DEFAULT}
            aria-label={t('share')}
          >
            {t('share')}
          </Button>
        ) : (
          <div className={c(styles.tooltip)}>
            <Button
              className={c(styles.btnShare)}
              disabled={isDisabled}
              onClick={tooltip}
              variant={ButtonVariants.PRIMARY}
              size={ButtonSizes.MEDIUM}
              theme={ButtonThemes.DEFAULT}
              aria-label={t('share')}
            >
              {t('share')}
            </Button>
            {showToolTip && (
              <span className={c(styles.tooltipText)}>{t('linkCopied')}</span>
            )}
            {showManualToolTip && (
              <span className={c(styles.tooltipManual)}>
                {t('copyAndShare')}
              </span>
            )}
          </div>
        ))}
    </div>
  );
};

ReferAFriend.propTypes = {
  /**
   * The message to show when sending the referral
   */
  referralMessage: PropTypes.string.isRequired,
  /**
   * A callback function invoked when the share button is clicked & the referral link is shared successfully, used for tracking
   */
  onShareClick: PropTypes.func,
  /**
   * If true, an email input will be shown for unauthenticated users to share their referral code
   */
  showEmailInput: PropTypes.bool,
  /**
   * A function invoked when submitting the inputted email
   */
  onEmailSubmit: PropTypes.func,
  /**
   * Whether the user has a coaches account. If true, RAF sharing is disabled
   */
  isCoachAccount: PropTypes.bool,
  /**
   * Show "rewarded" message for elite, affiliate, and family accounts
   */
  showRewardedMessage: PropTypes.bool,
  /**
   * An optional class name for styling
   */
  className: PropTypes.string,
};
