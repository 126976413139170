export enum MembershipAddOnType {
  WHOOP_RESTORE = 'whoop_restore',
}

export const ReadableMembershipAddOnType = {
  [MembershipAddOnType.WHOOP_RESTORE]: 'WHOOP Restore',
};

export enum ConsumerSubscriptionStatus {
  ACTIVE = 'active', // Subscription is currently active and being paid for
  CANCELED = 'canceled', // Subscription is no longer active
  EXPIRING = 'expiring', // Subscription is scheduled to cancel and no more payments are required
  INACTIVE = 'inactive', // Subscription is currently inactive and has never been activated for user
  PAST_DUE = 'past_due', // The most recent payment attempt for subscription failed and is being retried
  WHOOP_PENDING = 'whoop-pending', // Subscription has not yet started but will be started once member onboards
}

export const ReadableConsumerSubscriptionStatus = {
  [ConsumerSubscriptionStatus.ACTIVE]: 'Active',
  [ConsumerSubscriptionStatus.CANCELED]: 'Canceled',
  [ConsumerSubscriptionStatus.EXPIRING]: 'Expiring',
  [ConsumerSubscriptionStatus.INACTIVE]: 'Inactive',
  [ConsumerSubscriptionStatus.PAST_DUE]: 'Past Due',
  [ConsumerSubscriptionStatus.WHOOP_PENDING]: 'Pending',
};
