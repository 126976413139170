import React from 'react';
import styles from './loginPage.module.scss';
import { Button } from '../Button';
import { ButtonSizes, ButtonThemes, ButtonVariants } from '../../types/Buttons';
import { Logo } from '../Logo';
import { classes } from '../../utils';
import { Snowflakes } from '../Snowflakes';

export interface LoginPageProps {
  loginClick: () => void;
  siteName?: string;
  headerText?: string;
  errorMessage?: string;
  className?: string;
  errorHelpLink?: string;
  showSeasonalEmojis?: boolean;
}

/**
 *
 * @param {LoginPageProps} props
 * @param {() => void} props.loginClick
 * @param {string} props.siteName
 * @param {string} props.headerText
 * @param {JSX.Element} props.errorMessage
 * @param {JSX.Element} props.className
 * @returns {JSX.Element}
 */

export const LoginPage = (props: LoginPageProps): JSX.Element => {
  const {
    loginClick,
    siteName,
    headerText,
    errorMessage,
    errorHelpLink,
    className,
    showSeasonalEmojis,
  } = props;
  const SLACK_CHANNEL = 'https://whoopstrap.slack.com/archives/CK9M1R3PB';

  return (
    <div className={classes(className, styles.container)}>
      <Snowflakes level='light' show={showSeasonalEmojis || false} />
      <div className={styles.header}>
        <Logo variant='circle' className={styles.logo} />
        <p className={styles.headerText}>{headerText || 'WHOOP'}</p>
      </div>
      <div className={styles.main}>
        <div className={styles.centerContainer}>
          <h1>Welcome to {siteName || 'WHOOP'}</h1>
          <p className={styles.secondaryText}>
            Once you get access from your manager, sign in here.
            <br />
            <a
              target='_blank'
              rel='noreferrer'
              href={errorHelpLink || SLACK_CHANNEL}
              className={styles.inlineText}
            >
              Or request access.
            </a>
          </p>
          <Button
            variant={ButtonVariants.BLUE}
            size={ButtonSizes.MEDIUM}
            theme={ButtonThemes.ENTERPRISE}
            onClick={loginClick}
            className={styles.button}
          >
            Sign in
          </Button>
          <div className={styles.errorContainer}>
            {errorMessage && (
              <p className={styles.errorText}>
                {errorMessage}.{' '}
                <a
                  target='_blank'
                  rel='noreferrer'
                  href={errorHelpLink || SLACK_CHANNEL}
                  className={`${styles.inlineText} ${styles.errorHelp}`}
                >
                  Request Access
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
