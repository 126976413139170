import { graphql, useStaticQuery } from 'gatsby';
import { ShopifyProduct } from '../types/generated';
import { useMemo } from 'react';

const WhoopYourWayPriceRangeQuery = graphql`
  query ProductPageData {
    shopifyProduct(handle: { eq: "whoop-your-way-kit" }) {
      title
      priceRangeV2 {
        minVariantPrice {
          amount
          currencyCode
        }
      }
    }
  }
`;

export interface WhoopYourWayPriceRange {
  min?: number;
  max?: number;
}
function useWhoopYourWayPriceRange(): WhoopYourWayPriceRange {
  const { shopifyProduct } = useStaticQuery<{
    shopifyProduct: ShopifyProduct;
  }>(WhoopYourWayPriceRangeQuery);
  const min = shopifyProduct.priceRangeV2?.minVariantPrice?.amount;
  const max = shopifyProduct.priceRangeV2?.minVariantPrice?.amount;

  return useMemo(
    () => ({
      min: min ? parseFloat(String(min)) : undefined,
      max: max ? parseFloat(String(max)) : undefined,
    }),
    [min, max],
  );
}
export default useWhoopYourWayPriceRange;
