import React, { useMemo } from 'react';
import '../../index.module.scss';
import styles from './snowflakes.module.scss';
import { BasicComponentProps } from '../../types';

export type SnowflakeProps = {
  level: 'light' | 'medium' | 'heavy';
  show: boolean;
} & BasicComponentProps;

const seasons = {
  winter: {
    start: new Date('1969-12-01').getMonth(),
    end: new Date('1969-03-01').getMonth(),
  },
  spring: {
    start: new Date('1969-03-01').getMonth(),
    end: new Date('1969-06-01').getMonth(),
  },
  summer: {
    start: new Date('1969-06-01').getMonth(),
    end: new Date('1969-09-01').getMonth(),
  },
  fall: {
    start: new Date('1969-09-01').getMonth(),
    end: new Date('1969-12-01').getMonth(),
  },
};

export const Snowflakes = ({
  level = 'medium',
  show = false,
  ...props
}: SnowflakeProps) => {
  const getSeason = () => {
    const today = new Date().getMonth();
    if (today >= seasons.winter.start && today <= seasons.winter.end) {
      return 'winter';
    } else if (today >= seasons.spring.start && today <= seasons.spring.end) {
      return 'spring';
    } else if (today >= seasons.summer.start && today <= seasons.summer.end) {
      return 'summer';
    }
    return 'fall';
  };

  const getEmojis = () => {
    switch (getSeason()) {
      case 'winter':
        return ['❅', '❆'];
      case 'spring':
        return ['🌸', '🌼'];
      case 'summer':
        return ['🌞', '🌻'];
      case 'fall':
        return ['🍂', '🍁'];
      default:
        return ['❅', '❆'];
    }
  };

  const arr = useMemo(() => {
    switch (level) {
      case 'light':
        return [1, 2, 3, 4];
      case 'medium':
        return [1, 2, 3, 4, 5, 6];
      case 'heavy':
      default:
        return [1, 2, 3, 4, 5, 6, 7, 8, 9];
    }
  }, [level]);

  if (show) {
    const [one, two] = getEmojis();
    return (
      <div className={styles.snowflake} aria-hidden='true' {...props}>
        {arr.map((i) => (
          <>
            <div
              className={styles.snowflake}
              style={{ fontSize: `${i * 2 + 10}px` }}
            >
              {one}
            </div>
            <div
              className={styles.snowflake}
              style={{ fontSize: `${i * 2 + 10}px` }}
            >
              {two}
            </div>
          </>
        ))}
      </div>
    );
  }

  return null;
};
