import React from 'react';
import PropTypes from 'prop-types';
import WhoopProLogo from '../../assets/whoop-pro-logo.color.svg';
import WhoopProLogoSlim from '../../assets/whoop-pro-logo-slim.color.svg';

export const ProLogo = ({ variant = 'square', ...props }) => {
  if (variant === 'slim') {
    return <WhoopProLogoSlim height={30} width={30} {...props} />;
  }
  return <WhoopProLogo height={30} width={30} {...props} />;
};

ProLogo.propTypes = {
  /**
   * Logo style
   */
  variant: PropTypes.oneOf(['square', 'slim']),
};
