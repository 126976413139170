import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './gift-message-modal.module.scss';
import { Button } from '../../Button';
import { DatePicker } from '../../DatePicker';
import { Input } from '../../Input';
import { Modal } from '../../Modal/modal';
import { OptionSelector } from '../../deprecated/OptionSelector';
import { TextArea } from '../../TextArea';
import { c, getTodayAndYearFromToday } from '../../../utils';
import { validateEmail, hasProfanity } from '../../../utils/validation';
import { useTranslation, importTranslations } from '../../../utils/i18n';
import GiftIcon from '../../../icons/Growth/ic_gift.svg';
import { ButtonVariants } from '../../../types/Buttons';
importTranslations('giftMessageModal', require.context('./languages/', true));

const NO_FORM_ERROR = { code: 0, message: '' };

export const GiftMessageModal = ({
  gifterEmail,
  gifterNameOnFile,
  giftReceiptUrl,
  onClose,
  onSubmit,
  receiptView = false,
  region = 'domestic',
  savedGiftMessage,
  shippingDelay,
  show,
  ...props
}) => {
  const { t } = useTranslation('giftMessageModal');
  const { minDate, maxDate } = getTodayAndYearFromToday(1);

  const [sendToGiftee, setSendToGiftee] = useState(true);
  const [gifterName, setGifterName] = useState('');
  const [gifteeName, setGifteeName] = useState('');
  const [gifteeEmail, setGifteeEmail] = useState('');
  const [giftMessage, setGiftMessage] = useState('');
  const [sendNow, setSendNow] = useState(true);
  const [deliveryDate, setDeliveryDate] = useState(minDate);
  const [error, setError] = useState(NO_FORM_ERROR);

  useEffect(() => {
    if (show) {
      if (savedGiftMessage) {
        setGifterName(savedGiftMessage.gifter_name);
        setGifteeName(savedGiftMessage.giftee_name);
        setGifteeEmail(savedGiftMessage.giftee_email);
        setGiftMessage(
          savedGiftMessage.gift_message || t('defaultGiftMessage'),
        );
        setDeliveryDate(
          savedGiftMessage.delivery_time
            ? savedGiftMessage.delivery_time
            : minDate,
        );
        setSendToGiftee(Boolean(savedGiftMessage.giftee_email));
        setSendNow(!savedGiftMessage.delivery_time);
      } else if (gifterNameOnFile) {
        setGifterName(gifterNameOnFile);
      }
    }
  }, [gifterNameOnFile, savedGiftMessage, show]);

  useEffect(() => {
    validateForm();
  }, [giftMessage, gifteeEmail, gifteeName, gifterName, sendToGiftee]);

  const isSubmitDisabled = () => {
    return (
      !!error.message ||
      !gifterName ||
      !gifteeName ||
      (sendToGiftee && !gifteeEmail) ||
      (!sendNow && !deliveryDate)
    );
  };

  const getCartReadyGiftMessage = () => {
    const formattedMessage = {
      gifter_name: gifterName,
      giftee_name: gifteeName,
      gift_message: giftMessage ? giftMessage.trim() : t('defaultGiftMessage'),
    };
    if (!sendToGiftee) {
      formattedMessage.delivery_time = null;
    } else {
      formattedMessage.delivery_time = sendNow ? null : deliveryDate;
      formattedMessage.giftee_email = gifteeEmail;
    }
    return formattedMessage;
  };

  const closeModal = () => {
    onClose && onClose();
  };

  const submit = (event) => {
    event && event.preventDefault && event.preventDefault();
    if (!isSubmitDisabled()) {
      const cartReadyGiftMessage = getCartReadyGiftMessage();
      onSubmit && onSubmit(cartReadyGiftMessage);
    }
  };

  const recipientOptions = [t('emailToRecipient'), t('emailToMe')];

  const handleRecipientSelect = (value) => {
    setSendToGiftee(value === 0);
    if (value !== 0) {
      setSendNow(true);
    }
  };

  const deliveryOptions = [t('sendImmediately'), t('sendOnSpecificDate')];

  const handleDeliverySelect = (value) => {
    setSendNow(value === 0);
  };

  const getDeliverySubtext = () => {
    if (gifterEmail) {
      if (receiptView) {
        return t('sentToYouImmediately', { email: gifterEmail });
      }

      return t('sentToYouAfterPurchase', { email: gifterEmail });
    }

    return t('sentToEmailProvided');
  };

  const validateForm = () => {
    let formError = NO_FORM_ERROR;
    if (gifteeName && hasProfanity(gifteeName)) {
      formError = { code: 1, message: t('noProfanityRecipient') };
    } else if (sendToGiftee && gifteeEmail && !validateEmail(gifteeEmail)) {
      formError = { code: 2, message: t('enterValidEmail') };
    } else if (gifterName && hasProfanity(gifterName)) {
      formError = { code: 3, message: t('noProfanityGifter') };
    } else if (giftMessage && hasProfanity(giftMessage)) {
      formError = { code: 4, message: t('noProfanityGiftMessage') };
    } else if (giftMessage && giftMessage.length > 200) {
      formError = { code: 5, message: t('giftMessageCharLimit') };
    }
    setError(formError);
  };

  return (
    <Modal show={show} onClose={closeModal} disableAnimation>
      <form
        className={c(styles.giftMessageModal, 'whoop-ui')}
        autoComplete='off'
        onSubmit={submit}
      >
        <div className={styles.headerSection}>
          {!receiptView && (
            <div className={styles.iconContainer}>
              <GiftIcon />
            </div>
          )}
          <h1>{t('addPersonalTouch')}</h1>
          <div className={styles.subheader}>
            {t('sendGiftReceiptDetails')}
            {receiptView && (
              <>
                {' '}
                <a
                  href={giftReceiptUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {t('viewLink')}
                </a>
              </>
            )}
          </div>
        </div>

        <div className={styles.formSection}>
          <OptionSelector
            name='recipient'
            options={recipientOptions}
            onChange={handleRecipientSelect}
            value={sendToGiftee ? 0 : 1}
          />

          {!sendToGiftee && (
            <div
              className={c(
                styles.deliverySubtext,
                styles.purchaserDeliverySubtext,
              )}
            >
              {getDeliverySubtext()}
            </div>
          )}

          <div className={styles.giftMessageFields}>
            <div className={styles.fieldGroup}>
              <h3>{t('to')}</h3>
              <div className={styles.fieldRow}>
                <Input
                  className={styles.singleInput}
                  placeholder={t('recipientName')}
                  value={gifteeName}
                  onChange={setGifteeName}
                  error={error.code === 1}
                  maxLength={100}
                />
                {sendToGiftee && (
                  <Input
                    className={styles.singleInput}
                    placeholder={t('recipientEmail')}
                    value={gifteeEmail}
                    onChange={setGifteeEmail}
                    error={error.code === 2}
                    maxLength={100}
                  />
                )}
              </div>
            </div>

            <div className={styles.fieldGroup}>
              <h3>{t('from')}</h3>
              <div className={styles.fieldRow}>
                <Input
                  className={styles.singleInput}
                  placeholder={t('yourName')}
                  value={gifterName}
                  onChange={setGifterName}
                  error={error.code === 3}
                  maxLength={100}
                />
              </div>
            </div>

            <div className={styles.fieldGroup}>
              <h3>{t('personalMessage')}</h3>
              <div className={c(styles.fieldRow, styles.giftMessageInput)}>
                <TextArea
                  className={styles.singleInput}
                  placeholder={t('defaultGiftMessage')}
                  value={giftMessage}
                  onChange={setGiftMessage}
                />
              </div>
            </div>
          </div>
        </div>

        {sendToGiftee && (
          <div className={styles.deliverySection}>
            <div className={styles.deliverySubtext}>
              {t('sendGiftReceiptSubtext')}
              {shippingDelay && (
                <span className={styles.shippingDisclaimer}>
                  {' '}
                  {shippingDelay}.
                </span>
              )}
            </div>

            <OptionSelector
              name='delivery'
              options={deliveryOptions}
              onChange={handleDeliverySelect}
              value={sendNow ? 0 : 1}
            />
            {!sendNow && (
              <DatePicker
                className={styles.deliveryDatePicker}
                minDate={minDate}
                maxDate={maxDate}
                region={region}
                value={deliveryDate}
                onChange={setDeliveryDate}
              />
            )}
          </div>
        )}

        {error.message && (
          <div className={styles.errorSection}>{error.message}</div>
        )}

        <div className={styles.buttonSection}>
          <div className={styles.confirmButton}>
            <Button
              variant={ButtonVariants.PRIMARY}
              label={t('saveAndContinue')}
              onClick={submit}
              disabled={isSubmitDisabled()}
            />
          </div>
          <div className={styles.cancelButton}>
            <Button variant='normal' label={t('cancel')} onClick={closeModal} />
          </div>
        </div>
      </form>
    </Modal>
  );
};

GiftMessageModal.propTypes = {
  gifterEmail: PropTypes.string,
  gifterNameOnFile: PropTypes.string,
  giftReceiptUrl: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  receiptView: PropTypes.bool,
  region: PropTypes.string,
  savedGiftMessage: PropTypes.object,
  shippingDelay: PropTypes.string,
  show: PropTypes.bool,
};
