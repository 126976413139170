export enum MembershipStatus {
  PAST_DUE = 'past_due',
  WHOOP_PENDING = 'whoop-pending',
  TRIALING = 'trialing',
  CANCELED = 'canceled',
  LEGACY = 'legacy',
  ACTIVE = 'active',
  INVALID = 'invalid',
  ELITE = 'elite',
}

export const ReadableMembershipStatus = {
  [MembershipStatus.PAST_DUE]: 'Past Due',
  [MembershipStatus.WHOOP_PENDING]: 'Whoop-Pending',
  [MembershipStatus.TRIALING]: 'Trialing',
  [MembershipStatus.CANCELED]: 'Canceled',
  [MembershipStatus.LEGACY]: 'Legacy',
  [MembershipStatus.ACTIVE]: 'Active',
  [MembershipStatus.INVALID]: 'Invalid',
  [MembershipStatus.ELITE]: 'Elite',
};
