import React from 'react';
import styles from './icon-button.module.scss';
import '../../index.module.scss';
import { Icon, iconList } from '../Icon';
import * as PropTypes from 'prop-types';
import { c } from '../../utils';
import { addRipple } from '../effects';

/**
 * IconButton component
 * pass in state and callback to change state/handle click
 */
export const IconButton = React.forwardRef(
  (
    {
      className,
      onClick,
      name = 'cross',
      size = 'medium',
      variant = 'normal',
      ...props
    },
    ref,
  ) => {
    const onClickHandler = (event) => {
      addRipple(event.currentTarget, event, styles.ripple);
      return onClick && onClick(event);
    };
    return (
      <button
        type='button'
        className={c(
          className,
          styles['icon-button'],
          styles[`icon-button-${size}`],
          styles[`icon-button-${variant}`],
        )}
        onClick={onClickHandler}
        ref={ref}
        {...props}
      >
        <Icon name={name} className={c(styles.icon, styles[`icon-${size}`])} />
      </button>
    );
  },
);

IconButton.propTypes = {
  /**
   * IconButton disabled
   */
  disabled: PropTypes.bool,
  /**
   * IconButton onClick
   */
  onClick: PropTypes.func,
  /**
   * IconButton size
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * IconButton name
   */
  name: PropTypes.oneOf(iconList),
  /**
   * IconButton variant
   */
  variant: PropTypes.oneOf(['normal', 'primary', 'secondary', 'link']),
};
IconButton.displayName = 'IconButton';
