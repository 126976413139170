import React from 'react';
import { importTranslations, useTranslation } from '../../../utils/i18n';
import { Col, Container, Row } from '../../layout';
import { classes } from '../../../utils';
import styles from './order-details.module.scss';
import { ShippingInfo } from '../../../types/ShippingInfo';

importTranslations('orderDetails', require.context('./languages/', true));

export interface OrderDetailsProps
  extends React.HTMLAttributes<HTMLDivElement> {
  shippingInfo: ShippingInfo;
  orderNumber: string;
  email: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function OrderDetails({
  shippingInfo,
  orderNumber,
  email,
  className,
  ...props
}: OrderDetailsProps) {
  const { t } = useTranslation('orderDetails');

  return (
    <div className={classes(className, styles.wrapper)} {...props}>
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <p className={styles.label}>{t('shippingTo')}</p>
            <p
              className={styles.value}
              data-testid={'addressLine1'}
            >{`${shippingInfo.first_name} ${shippingInfo.last_name}`}</p>
            <p className={styles.value} data-testid={'addressLine2'}>
              {[shippingInfo.line1, shippingInfo.line2, shippingInfo.line3]
                .filter((l) => l && l.length > 0)
                .join(', ')}
            </p>
            <p className={styles.value} data-testid={'addressLine3'}>
              {[
                shippingInfo.city,
                `${shippingInfo.province} ${shippingInfo.postal_code} ${shippingInfo.country}`,
              ]
                .filter((l) => l && l.length > 0)
                .join(', ')}
            </p>
          </Col>
          <Col xs={12} md={6}>
            <Col className={styles.insideCol} xs={12} md={12}>
              <p className={styles.label}>{t('orderNumber')}</p>
              <p className={styles.value} data-testid={'orderNumber'}>
                {orderNumber}
              </p>
            </Col>
            <Col className={styles.insideCol} xs={12} md={12}>
              <p className={styles.label}>{t('email')}</p>
              <p className={styles.value} data-testid={'email'}>
                {email}
              </p>
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
