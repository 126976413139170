/* global HTMLDivElement, HTMLElement, JSX, Partial */
import React, { useState } from 'react';
import { c } from '../../utils';
import styles from './accordion.module.scss';
import { AccordionThemes } from '../../types/Accordions';
import { Icon } from '../Icon';

export interface AccordionSection extends React.HTMLAttributes<HTMLElement> {
  title: string;
  content: React.ReactNode;
  useLeftIcon?: boolean;
}

export interface AccordionProps extends React.HTMLAttributes<HTMLDivElement> {
  sections: AccordionSection[];
  onSectionOpen?: (section: Partial<AccordionSection>) => void;
  onSectionClose?: (section: Partial<AccordionSection>) => void;
  theme?: AccordionThemes;
  hideBorders?: boolean;
}

/**
 * Component to display content inside of accordions
 */
export function Accordion({
  sections,
  onSectionOpen,
  onSectionClose,
  theme = AccordionThemes.DEFAULT,
  className,
  hideBorders = false,
  ...props
}: AccordionProps): JSX.Element {
  const [openSectionId, setOpenSectionId] = useState<string | number>();

  function isSectionOpen(pageId: string | number | undefined) {
    return openSectionId === pageId;
  }

  function toggleSection(
    id: string | undefined,
    title: string,
    content: React.ReactNode,
  ) {
    if (openSectionId === id) {
      setOpenSectionId(undefined);
      onSectionClose && onSectionClose({ id, title, content });
    } else {
      setOpenSectionId(id);
      onSectionOpen && onSectionOpen({ id, title, content });
    }
  }

  return (
    <div
      className={c(
        styles.accordion,
        theme !== AccordionThemes.DEFAULT && `theme-${theme}`,
        className,
      )}
      {...props}
    >
      <div className={styles.productTabs}>
        {sections?.map(
          ({ id, title, content, useLeftIcon = false, ...sectionProps }) => (
            <section
              className={c(
                styles.collapsible,
                !hideBorders && styles.tabBorders,
              )}
              key={id}
              {...sectionProps}
            >
              <span
                className={c(
                  styles.collapsibleButton,
                  useLeftIcon ? styles.rightContent : undefined,
                )}
                onClick={() => toggleSection(id, title, content)}
                data-testid='section-title'
              >
                {title}
                {theme === AccordionThemes.JOIN_FLOW ? (
                  <Icon
                    name='caret_down'
                    className={c(styles.arrowIcon)}
                    aria-expanded={isSectionOpen(id)}
                  />
                ) : (
                  <span
                    className={c(
                      styles.collapsiblePlus,
                      useLeftIcon ? styles.leftPlus : styles.rightPlus,
                    )}
                    aria-expanded={isSectionOpen(id)}
                  />
                )}
              </span>
              <div
                className={c(
                  styles.collapsibleInner,
                  isSectionOpen(id) && styles.expanded,
                )}
                data-testid='section-content'
              >
                <div className={styles.collapsibleContent}>{content}</div>
              </div>
            </section>
          ),
        )}
      </div>
    </div>
  );
}
