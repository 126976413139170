import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Cart from '~/components/Cart';
import { Footer, Snowflakes } from '@whoop/web-components';
import './index.scss';
import { classes } from '../utils';
import ProModal from '../components/ProModal';
import {
  APP_DOMAIN,
  APP_URL,
  BILLING_REGION,
  JOIN_URL,
  ORDER_STATUS_URL,
  SUPPORT_URL,
  WHOOP_URL,
  getSupportUrl,
} from '../utils/regions';
import { useIsOnForAll } from '../utils/feature-flags';
import { initIntercom } from '../utils/intercom/intercomUtils';
import { useInitialization } from '../utils/initialization';
import UpsellModal from '../components/UpsellModal/upsellModal';
import HeaderV2 from '../components/HeaderV2';
import Toast from '../components/Toast';
import { useShowIntercom } from '../utils/experimentUtils';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useQueryParam } from '../utils/queryParamUtils';
import { Button, ButtonVariants } from '../libs/web-components';

// prettier-ignore
const COMPANY_LINKS = [
  { key: 'ourMission', href: `${WHOOP_URL}/our-mission`, },
  { key: 'careers',    href: `${WHOOP_URL}/careers`, },
  { key: 'contact',    href: SUPPORT_URL, },
];
// prettier-ignore
const JOIN_US_LINKS = [
  { key: 'joinUs',      href: JOIN_URL, },
  { key: 'signIn',      href: `${APP_URL}/login`, },
  { key: 'orderStatus', href: `${ORDER_STATUS_URL}/orderlookup`, },
  { key: 'support',     href: SUPPORT_URL, },
  { key: 'refer',       href: `https://${APP_DOMAIN}/referafriend`, },
  { key: 'giftCards',   href: `https://${APP_DOMAIN}/gifts/extensions?region=${BILLING_REGION}`, },
];
// prettier-ignore
const POLICY_LINKS = [
  { key: 'tos',           href: `${WHOOP_URL}/termsofuse/`, },
  { key: 'privacyPolicy', href: `${WHOOP_URL}/privacy/`, },
  { key: 'refundPolicy',  href: `${getSupportUrl('RETURN_POLICY')}`, },
];

const REDIRECT_TOAST_TIMEOUT_MS = 7000;

const Layout = ({ children, pageContext, uri, ...rest }) => {
  const { t } = useTranslation('layout');
  const isCartPage = uri === '/cart';
  const [transparentHeader, setTransparentHeader] = useState();
  const letItSnow = useIsOnForAll('storefront-snowflakes');
  const showIntercom = useShowIntercom();

  useEffect(() => {
    if (showIntercom) {
      initIntercom();
    }
  }, [showIntercom]);

  useInitialization();

  // variables used for rendering must be set via useState:
  useEffect(() => {
    setTransparentHeader(uri === '/' || pageContext.transparentHeader);
  });

  const toLink = (key, href, group) => ({
    label: t(key),
    href,
    target: '_blank',
    'data-event': 'Clicked on Footer Link',
    'data-event-link_key': key,
    'data-event-group': group,
    'data-event-link': href,
  });

  // Special case to show a toast when redirected to a different billing region
  const [shouldShowRedirectToast, setShouldShowRedirectToast] = useState(false);
  const [redirectedToRegion] = useQueryParam('redirectedToRegion', '');

  useEffect(() => {
    let timerId = null;
    if (redirectedToRegion === 'true') {
      const queryParams = new URLSearchParams(window.location.search);
      // cleanup URL after the redirect
      queryParams.delete('redirectedToRegion');
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.replaceState({}, '', newUrl);

      setShouldShowRedirectToast(true);

      timerId = setTimeout(() => {
        setShouldShowRedirectToast(false);
      }, REDIRECT_TOAST_TIMEOUT_MS);
    }
    return () => clearTimeout(timerId);
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <>
          <HeaderV2 transparent={transparentHeader} disableCart={isCartPage} />
          <Cart />
          <ProModal />
          <UpsellModal />
          {shouldShowRedirectToast && (
            <Toast>
              <p>{t('redirectedToBillingRegion')}</p>
              <Button
                theme='join-flow'
                variant={ButtonVariants.PRIMARY}
                onClick={() => setShouldShowRedirectToast(false)}
              >
                {t('gotIt')}
              </Button>
            </Toast>
          )}
          {letItSnow && <Snowflakes level='medium' />}
          <div
            className={classes(
              'page-container',
              !transparentHeader && 'embed-header',
            )}
          >
            <div className={classes('page-content')}>{children}</div>
            <Footer
              linkGroups={[
                {
                  groupName: t('company'),
                  links: COMPANY_LINKS.map(({ key, href }) =>
                    toLink(key, href, 'Company'),
                  ),
                },
                {
                  groupName: t('joinUs'),
                  groupLink: '/',
                  links: JOIN_US_LINKS.map(({ key, href }) =>
                    toLink(key, href, 'Join Us'),
                  ),
                },
              ]}
              policyLinks={POLICY_LINKS.map(({ key, href }) =>
                toLink(key, href, 'Policy'),
              )}
            />
          </div>
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
