import React from 'react';
import '../../index.module.scss';
import styles from './price.module.scss';
import { ProLogo } from '../ProLogo';
import { c } from '../../utils';
import { BasicComponentProps } from '../../types';

export type PriceProps = {
  price: string;
  originalPrice?: string;
  isProDiscount?: boolean;
} & BasicComponentProps;

export const Price = ({
  price,
  originalPrice,
  isProDiscount,
  className,
  ariaLabel,
  ...props
}: PriceProps) => (
  <span
    className={c(className, styles.priceContainer)}
    aria-label={ariaLabel || 'Price'}
    {...props}
  >
    {originalPrice && (
      <span className={styles.originalPrice}>{originalPrice}</span>
    )}
    {originalPrice && isProDiscount && (
      <ProLogo variant='square' className={styles.proLogo} />
    )}
    <span className={className}>{price}</span>
  </span>
);
