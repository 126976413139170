export const BICEP = {
  bicep: {
    's/m': { in: [10, 11], cm: [25, 28] },
    'l/xl': { in: [11, 16], cm: [28, 41.5] },
  },
};
export const ARM_CHART = {
  upperArm: {
    xs: { in: 10.2, cm: 26 },
    s: { in: 11, cm: 28 },
    m: { in: 12.2, cm: 31 },
    l: { in: 13, cm: 33 },
    xl: { in: 13.7, cm: 35 },
    xxl: { in: 15, cm: 38 },
  },
  lowerArm: {
    xs: { in: 8, cm: 20 },
    s: { in: 9, cm: 23 },
    m: { in: 10, cm: 25 },
    l: { in: 10.5, cm: 27 },
    xl: { in: 11, cm: 29 },
    xxl: { in: 12.2, cm: 31 },
  },
  elbowLength: {
    xs: { in: 6, cm: 15.5 },
    s: { in: 6, cm: 15.5 },
    m: { in: 6, cm: 15.5 },
    l: { in: 7, cm: 17.5 },
    xl: { in: 7, cm: 17.5 },
    xxl: { in: 7, cm: 17.5 },
  },
};
export const MENS_TOP_CHART = {
  chest: {
    s: { in: [34, 36], cm: [86.5, 91.5] },
    m: { in: [37, 39], cm: [94, 99] },
    l: { in: [40, 43], cm: [101.5, 109] },
    xl: { in: [44, 47], cm: [112, 119.5] },
    xxl: { in: [48, 51], cm: [122, 129.5] },
  },
  bicep: {
    s: { in: [11.7, 12.5], cm: [30, 32] },
    m: { in: [12.7, 13.5], cm: [32.5, 34] },
    l: { in: [13.5, 14], cm: [34, 35.5] },
    xl: { in: [14, 15], cm: [35.5, 38] },
    xxl: { in: [15, 16], cm: [38, 40.5] },
  },
};
export const MENS_SHORTS_CHART = {
  waist: {
    s: { in: [29, 30.5], cm: [73.5, 77.5] },
    m: { in: [31, 33], cm: [78.5, 83] },
    l: { in: [34, 36], cm: [83, 91.5] },
    xl: { in: [37, 40], cm: [92, 101.5] },
    xxl: { in: [41, 44], cm: [102, 112] },
  },
  hip: {
    s: { in: [34, 36.5], cm: [86.5, 92.5] },
    m: { in: [37, 39], cm: [94, 99] },
    l: { in: [40, 42], cm: [101.5, 106.5] },
    xl: { in: [43, 45], cm: [109, 114] },
    xxl: { in: [46, 48], cm: [117, 122] },
  },
  thigh: {
    s: { in: [20.7, 22.2], cm: [52.5, 56.5] },
    m: { in: [22.5, 24], cm: [57, 61] },
    l: { in: [24.2, 25.7], cm: [61.5, 65.5] },
    xl: { in: [26, 27.5], cm: [66, 70] },
    xxl: { in: [27.7, 29.2], cm: [70.5, 74] },
  },
};
export const MENS_BOXERS_CHART = {
  waist: {
    s: { in: [28, 29.5], cm: [71, 75] },
    m: { in: [30, 32], cm: [76, 81.3] },
    l: { in: [33, 35], cm: [84, 89] },
    xl: { in: [36, 39], cm: [91.5, 99] },
    xxl: { in: [40, 42], cm: [101.5, 106.5] },
  },
  hip: {
    s: { in: [33.5, 35.5], cm: [84, 90] },
    m: { in: [36, 38], cm: [91.5, 96.5] },
    l: { in: [38.5, 40.5], cm: [99, 104] },
    xl: { in: [41, 43], cm: [106.5, 111] },
    xxl: { in: [43.5, 46], cm: [114, 117] },
  },
  thigh: {
    s: { in: [20.2, 21.7], cm: [51.5, 55.5] },
    m: { in: [22, 23.5], cm: [56, 59.5] },
    l: { in: [23.7, 25.2], cm: [60, 64] },
    xl: { in: [25.5, 27], cm: [65, 68.5] },
    xxl: { in: [27.2, 28.2], cm: [69, 72] },
  },
};
export const WOMENS_LEGGINGS_CHART = {
  waist: {
    xs: { in: [24, 26], cm: [61, 66] },
    s: { in: [26.5, 28.5], cm: [67, 72.5] },
    m: { in: [29, 31], cm: [73.5, 78.5] },
    l: { in: [31.5, 33.5], cm: [80, 85] },
    xl: { in: [34, 36], cm: [86.5, 91.5] },
  },
  hip: {
    xs: { in: [33.5, 35.5], cm: [85, 90] },
    s: { in: [36, 38], cm: [91.5, 96.5] },
    m: { in: [38.5, 40.5], cm: [98, 102] },
    l: { in: [41, 43], cm: [104, 109] },
    xl: { in: [43.5, 46], cm: [110.5, 117] },
  },
  thigh: {
    xs: { in: [20.7, 21.2], cm: [52.5, 54] },
    s: { in: [21.5, 22], cm: [54.5, 56] },
    m: { in: [22.2, 23.2], cm: [56.5, 59] },
    l: { in: [23.5, 24.5], cm: [59.5, 62] },
    xl: { in: [24.7, 26], cm: [63, 66] },
  },
};
export const WOMENS_BRA_CHART = {
  bust: {
    xs: { in: [31, 35], cm: [79, 89] },
    s: { in: [35, 37], cm: [89, 94] },
    m: { in: [37, 39], cm: [94, 99] },
    l: { in: [39, 41], cm: [99, 104] },
    xl: { in: [41, 43], cm: [104, 109] },
  },
  underBust: {
    xs: { in: [30, 32], cm: [65, 70] },
    s: { in: [32, 34], cm: [70, 75] },
    m: { in: [34, 36], cm: [75, 80] },
    l: { in: [36, 38], cm: [80, 85] },
    xl: { in: [38, 40], cm: [85, 90] },
  },
  cup: {
    xs: { in: ['A', 'C'], cm: ['A', 'C'] },
    s: { in: ['A', 'C'], cm: ['A', 'C'] },
    m: { in: ['A', 'C'], cm: ['A', 'C'] },
    l: { in: ['A', 'C'], cm: ['A', 'C'] },
    xl: { in: ['A', 'C'], cm: ['A', 'C'] },
  },
  waist: {
    xs: { in: [26.5], cm: [61, 66] },
    s: { in: [26.5, 28.5], cm: [67, 72.5] },
    m: { in: [29, 31], cm: [73.5, 78.5] },
    l: { in: [31.5, 33.5], cm: [80, 85] },
    xl: { in: [34, 36], cm: [86.5, 91.5] },
  },
};
export const JOGGERS = {
  waist: {
    xs: { in: [26.5, 28.5], cm: [67, 72] },
    s: { in: [29, 30.5], cm: [73, 78] },
    m: { in: [31, 33.5], cm: [79, 85] },
    l: { in: [34, 36], cm: [86, 91.5] },
    xl: { in: [36.5, 39], cm: [92, 99] },
    xxl: { in: [39.5, 42.5], cm: [100, 108] },
    xxxl: { in: [43, 45], cm: [109, 114] },
  },
  thigh: {
    xs: { in: [21.5, 22], cm: [54.5, 56] },
    s: { in: [22.2, 23], cm: [56.5, 58.5] },
    m: { in: [23.2, 24.2], cm: [59, 61.5] },
    l: { in: [24.5, 25.5], cm: [62, 64.5] },
    xl: { in: [26, 26.7], cm: [66, 68] },
    xxl: { in: [27, 28], cm: [68.5, 71] },
    xxxl: { in: [28.5, 29.5], cm: [72, 74] },
  },
  inseam: {
    xs: { in: [30.5, 32.5], cm: [77.5, 82.5] },
    s: { in: [30.5, 32.5], cm: [77.5, 82.5] },
    m: { in: [30.5, 32.5], cm: [77.5, 82.5] },
    l: { in: [30.5, 32.5], cm: [77.5, 82.5] },
    xl: { in: [30.5, 32.5], cm: [77.5, 82.5] },
    xxl: { in: [30.5, 32.5], cm: [77.5, 82.5] },
    xxxl: { in: [30.5, 32.5], cm: [77.5, 82.5] },
  },
};
export const UNISEX_TEE = {
  'chest-bust': {
    xs: { in: [32.5, 34.5], cm: [83, 88] },
    s: { in: [35, 37], cm: [89, 94] },
    m: { in: [37.5, 39.5], cm: [95, 100] },
    l: { in: [40, 43], cm: [101, 109] },
    xl: { in: [43.5, 46.5], cm: [110, 118] },
    xxl: { in: [47, 50], cm: [119, 127] },
    xxxl: { in: [50.5, 54], cm: [128, 137] },
  },
  bicep: {
    xs: { in: [10.5, 11.2], cm: [26.5, 28.5] },
    s: { in: [11.5, 12.2], cm: [29, 31] },
    m: { in: [12.5, 13.2], cm: [31.5, 33.5] },
    l: { in: [13.5, 14], cm: [34, 35.5] },
    xl: { in: [14.2, 15], cm: [36, 38] },
    xxl: { in: [15.2, 16], cm: [38.5, 40.5] },
    xxxl: { in: [16.2, 17], cm: [41, 43] },
  },
  waist: {
    xs: { in: [26.5, 28.5], cm: [67, 72] },
    s: { in: [29, 30.5], cm: [73, 78] },
    m: { in: [31, 33.5], cm: [79, 85] },
    l: { in: [34, 36], cm: [86, 91.5] },
    xl: { in: [36.5, 39], cm: [92, 99] },
    xxl: { in: [39.5, 42.5], cm: [100, 108] },
    xxxl: { in: [43, 45], cm: [109, 114] },
  },
};
export const UNISEX_TOP = {
  'chest-bust': {
    xs: { in: [32.5, 34.5], cm: [83, 88] },
    s: { in: [35, 37], cm: [89, 94] },
    m: { in: [37.5, 39.5], cm: [95, 100] },
    l: { in: [40, 43], cm: [101, 109] },
    xl: { in: [43.5, 46.5], cm: [110, 118] },
    xxl: { in: [47, 50], cm: [119, 127] },
    xxxl: { in: [50.5, 54], cm: [128, 137] },
  },
  bicep: {
    xs: { in: [10.5, 11.2], cm: [26.5, 28.5] },
    s: { in: [11.5, 12.2], cm: [29, 31] },
    m: { in: [12.5, 13.2], cm: [31.5, 33.5] },
    l: { in: [13.5, 14], cm: [34, 35.5] },
    xl: { in: [14.2, 15], cm: [36, 38] },
    xxl: { in: [15.2, 16], cm: [38.5, 40.5] },
    xxxl: { in: [16.2, 17], cm: [41, 43] },
  },
  waist: {
    xs: { in: [26.5, 28.5], cm: [67, 72] },
    s: { in: [29, 30.5], cm: [73, 78] },
    m: { in: [31, 33.5], cm: [79, 85] },
    l: { in: [34, 36], cm: [86, 91.5] },
    xl: { in: [36.5, 39], cm: [92, 99] },
    xxl: { in: [39.5, 42.5], cm: [100, 108] },
    xxxl: { in: [43, 45], cm: [109, 114] },
  },
};
export const HYDROSLEEVE = {
  wrist: {
    s: { in: [0, 6], cm: [0, 16] },
    m: { in: [6, 7.5], cm: [16, 19] },
    l: { in: [7.5, Infinity], cm: [19, Infinity] },
  },
};

export const TYR_MENS = {
  waist: {
    'XXS (26)': { in: [26, 27.5], cm: [66, 70] },
    'XS (28)': { in: [28, 29.5], cm: [71, 75] },
    'S (30)': { in: [30, 31.5], cm: [76, 80] },
    'M (32)': { in: [32, 34], cm: [81, 86] },
    'L (34)': { in: [34.5, 36], cm: [88, 91] },
    'XL (36)': { in: [36.5, 38.5], cm: [93, 98] },
    'XXL (38)': { in: [39, 41], cm: [99, 104] },
    'XXXL (40)': { in: [41.5, 44], cm: [105, 112] },
  },
  hip: {
    'XXS (26)': { in: [32, 33], cm: [81, 84] },
    'XS (28)': { in: [33.5, 34.5], cm: [85, 88] },
    'S (30)': { in: [35, 36.5], cm: [89, 93] },
    'M (32)': { in: [37, 38.5], cm: [94, 98] },
    'L (34)': { in: [39, 40.5], cm: [99, 103] },
    'XL (36)': { in: [41, 43], cm: [104, 109] },
    'XXL (38)': { in: [43.5, 45.5], cm: [111, 116] },
    'XXXL (40)': { in: [46, 48], cm: [117, 122] },
  },
};

export const TYR_WOMENS = {
  chest: {
    'XXS (26)': { in: [28, 29.5], cm: [71, 75] },
    'XS (28)': { in: [30, 31], cm: [76, 79] },
    'S (30)': { in: [31.5, 32.5], cm: [80, 83] },
    'M (32)': { in: [33, 34], cm: [84, 86] },
    'L (34)': { in: [3, -35.5], cm: [88, 90] },
    'XL (36)': { in: [36, 37.5], cm: [91, 95] },
    'XXL (38)': { in: [38, 39.5], cm: [97, 100] },
    'XXXL (40)': { in: [40, 41.5], cm: [102, 105] },
  },
  waist: {
    'XXS (26)': { in: [23, 24.5], cm: [58, 62] },
    'XS (28)': { in: [25, 26], cm: [64, 66] },
    'S (30)': { in: [26.5, 27.5], cm: [67, 70] },
    'M (32)': { in: [28, 29], cm: [71, 74] },
    'L (34)': { in: [29.5, 30.5], cm: [75, 77] },
    'XL (36)': { in: [31, 32.5], cm: [79, 83] },
    'XXL (38)': { in: [33, 34.5], cm: [84, 88] },
    'XXXL (40)': { in: [35, 36.5], cm: [89, 93] },
  },
  hip: {
    'XXS (26)': { in: [29.5, 30.5], cm: [75, 77] },
    'XS (28)': { in: [31, 32.5], cm: [89, 83] },
    'S (30)': { in: [33, 34.5], cm: [84, 88] },
    'M (32)': { in: [35, 36.5], cm: [89, 93] },
    'L (34)': { in: [37, 38.5], cm: [94, 98] },
    'XL (36)': { in: [39, 40.5], cm: [99, 103] },
    'XXL (38)': { in: [41, 42], cm: [104, 107] },
    'XXXL (40)': { in: [42.5, 43.5], cm: [108, 110] },
  },
  torso: {
    'XXS (26)': { in: [54.5, 55.5], cm: [138, 141] },
    'XS (28)': { in: [56, 57], cm: [142, 145] },
    'S (30)': { in: [57.5, 58.5], cm: [146, 149] },
    'M (32)': { in: [59, 60], cm: [150, 152] },
    'L (34)': { in: [60.5, 61.5], cm: [154, 156] },
    'XL (36)': { in: [62, 63], cm: [157, 160] },
    'XXL (38)': { in: [63.5, 64.5], cm: [161, 164] },
    'XXXL (40)': { in: [65, 66.5], cm: [165, 169] },
  },
};

export const ASSOS_MENS_SHORTS = {
  waist: {
    XS: { in: [27.5, 29.1], cm: [70, 74] },
    S: { in: [29.2, 31.5], cm: [75, 80] },
    M: { in: [31.6, 33.8], cm: [81, 86] },
    L: { in: [33.9, 36.2], cm: [87, 92] },
    XL: { in: [36.3, 39], cm: [93, 99] },
    XXL: { in: [39.1, 41.7], cm: [100, 106] },
    XXXL: { in: [41.8, Infinity], cm: [107, Infinity] },
  },
  height: {
    XS: { in: [61.2, 66.9], cm: [155, 170] },
    S: { in: [65, 70], cm: [165, 178] },
    M: { in: [68.1, 74], cm: [173, 188] },
    L: { in: [70, 74.8], cm: [178, 190] },
    XL: { in: [71.3, 76.7], cm: [185, 195] },
    XXL: { in: [72.8, Infinity], cm: [185, Infinity] },
    XXXL: { in: [72.8, Infinity], cm: [185, Infinity] },
  },
  hip: {
    XS: { in: [35, 37.4], cm: [89, 95] },
    S: { in: [37.5, 39.4], cm: [96, 100] },
    M: { in: [39, 41.7], cm: [99, 106] },
    L: { in: [41.3, 43.3], cm: [105, 110] },
    XL: { in: [42.9, 45.5], cm: [109, 116] },
    XXL: { in: [45.3, 47.6], cm: [115, 121] },
    XXXL: { in: [47.2, Infinity], cm: [120, Infinity] },
  },
};

export const ASSOS_WOMENS_SHORTS = {
  hip: {
    XS: { in: [31.5, 33.8], cm: [80, 86] },
    S: { in: [33.9, 36.6], cm: [87, 93] },
    M: { in: [36.7, 39.4], cm: [94, 100] },
    L: { in: [39.5, 41.1], cm: [101, 107] },
    XL: { in: [41.2, 44.9], cm: [108, 114] },
    XXL: { in: [45, Infinity], cm: [115, Infinity] },
  },
  height: {
    XS: { in: [58.1, 63], cm: [145, 160] },
    S: { in: [61.2, 66.1], cm: [155, 168] },
    M: { in: [64.2, 70.1], cm: [163, 178] },
    L: { in: [66.1, 70.8], cm: [168, 180] },
    XL: { in: [67.3, 72.8], cm: [171, 185] },
    XXL: { in: [68.9, Infinity], cm: [175, Infinity] },
  },
};
