import React from 'react';
import { classes } from '../../utils';
import {
  LayoutProps,
  ViewPortSizeType,
  createProps,
  RowAttributes,
} from '../../utils/layoutUtils';
import styles from './layout.module.scss';
import { capitalize } from '../../../../utils';

export interface RowProps extends LayoutProps {
  reverse?: boolean;
  start?: ViewPortSizeType;
  center?: ViewPortSizeType;
  end?: ViewPortSizeType;
  top?: ViewPortSizeType;
  middle?: ViewPortSizeType;
  bottom?: ViewPortSizeType;
  around?: ViewPortSizeType;
  between?: ViewPortSizeType;
}

export function Row({ ...props }: RowProps) {
  const rowKeys = [
    'start',
    'center',
    'end',
    'top',
    'middle',
    'bottom',
    'around',
    'between',
  ];
  const modificators = [];

  for (let i = 0; i < rowKeys.length; i += 1) {
    const key = rowKeys[i];
    const value = props[key as RowAttributes];
    if (value && value !== true) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      modificators.push(styles[`${key}${capitalize(value)}`]);
    }
  }

  const classNames = classes(
    props.className,
    styles.row,
    props.reverse && styles.reverse,
    ...modificators,
  );

  return React.createElement('div', createProps(props, classNames));
}
