import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import '../../index.module.scss';
import styles from './number-input.module.scss';
import { addRipple } from '../effects';
import { c } from '../../utils';

/**
 * Number input with included + & - buttons
 */
export const NumberInput = ({
  min,
  max,
  name,
  allowDecimals,
  stepSize = 1,
  disabled,
  onChange = () => {},
  value,
  useExternalState,
  inputRef,
  className,
  ...props
}) => {
  // Clamps value based on props, returns 0 if value is null
  const clampValue = (value) => {
    if (value == null) {
      value = 0;
    }
    if (min != null) {
      value = Math.max(value, min);
    }
    if (max != null) {
      value = Math.min(value, max);
    }
    return value;
  };
  // Updates the value and clamps to min & max
  const updateValue = (newValue, ...args) => {
    // set to integer if that flag is enabled
    if (!allowDecimals && !Number.isInteger(newValue)) {
      newValue = parseInt(newValue);
    }
    // strip min & max
    newValue = clampValue(newValue);
    if (actualValue !== newValue) {
      // only update if there the new value is different
      // change div size
      setValue(newValue);
      return onChange && onChange(newValue, ...args);
    }
  };
  // Called when the user edits the textbox
  const onTextChangeHandler = (event) => {
    return updateValue(event.target.value, event);
  };
  // Called when the user clicks + OR -
  const onClickHandler = (event, multiplier) => {
    addRipple($container.current, event, styles.ripple);
    const newValue = actualValue + stepSize * multiplier;
    return updateValue(newValue, event);
  };
  // determines size based on digits
  const getSizeStyle = () => {
    if ($input.current && $input.current.value) {
      const digits = $input.current.value.length;
      if (digits > 3) {
        return { width: 0.65 * digits + 'em' };
      }
    }
  };

  // ---Initialize ---
  const [valueState, setValue] = useState(clampValue(value));
  const actualValue = useExternalState ? clampValue(value) : valueState;
  // set value when parent updates value
  useEffect(() => setValue(clampValue(value)), [value]);
  const $container = useRef(null);
  const $input = useRef(null);

  // --- Render ---
  return (
    <div
      className={c(styles.numberInput, className)}
      ref={$container}
      {...props}
    >
      <button
        type='button'
        disabled={actualValue === min || disabled}
        onClick={(event) => onClickHandler(event, -1)}
      >
        -
      </button>
      <input
        ref={inputRef || $input}
        name={name}
        type='number'
        pattern='[0-9]*'
        value={actualValue}
        style={getSizeStyle()}
        onChange={onTextChangeHandler}
        disabled={disabled}
      />
      <button
        type='button'
        disabled={actualValue === max || disabled}
        onClick={(event) => onClickHandler(event, 1)}
      >
        +
      </button>
    </div>
  );
};

NumberInput.propTypes = {
  /**
   * input name
   */
  name: PropTypes.string,
  /**
   * increment/decrement amount (default is 1)
   */
  stepSize: PropTypes.number,
  /**
   * min value
   */
  min: PropTypes.number,
  /**
   * max value
   */
  max: PropTypes.number,
  /**
   * whether only allow integers
   */
  allowDecimals: PropTypes.bool,
  /**
   * whether input is disabled
   */
  disabled: PropTypes.bool,
  /**
   * change callback includes current value in params
   */
  onChange: PropTypes.func,
  /**
   * Optional flag to either use it's internal state or use the state provided in value
   */
  useExternalState: PropTypes.bool,
  /**
   * Value of the text input (only used as initial value, or if used fully if useExternalState is true
   */
  value: PropTypes.number,
};
