import { ComponentType, lazy } from 'react';

type ImportFunction = () => Promise<{ default: ComponentType<any> }>;

const REFRESH_KEY = 'page-has-been-refreshed';
const FALSE = 'false';
const TRUE = 'true';
/**
 * This function should be used in conjunction with an index.html that is NEVER cached.
 * In index.html, you should add 2 meta tags <meta http-equiv="Pragma" content="no-cache" /> and <meta http-equiv="cache-control" content="no-cache, no-store, must-revalidate" />
 * The idea is to reload the page automatically when the app cannot find a specific js chunk.
 * All inspiration taken from: https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
 * @param componentImportFn
 */
export const LazyWithRetry = (componentImportFn: ImportFunction) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  lazy(async () => {
    if (!window) {
      return await componentImportFn();
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const pageHasBeenRefreshed = JSON.parse(
      window.localStorage.getItem(REFRESH_KEY) || FALSE,
    );
    try {
      const component = await componentImportFn();
      // reset to 'false' after a successful load
      window.localStorage.setItem(REFRESH_KEY, FALSE);
      return component;
    } catch (err) {
      if (pageHasBeenRefreshed === FALSE || pageHasBeenRefreshed === false) {
        window.localStorage.setItem(REFRESH_KEY, TRUE);

        // reload the window to get the latest index.html
        window.location.reload();
      } else {
        // re-throw error to fail fast if the reload didn't work
        throw err;
      }
    }
  });
