/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import styles from './link-button.module.scss';
import { c } from '../../utils';
import { Icon } from '../Icon';

export interface LinkButtonProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  label: string;
  icon?: string;
  iconFirst?: boolean;
}

export const LinkButton = React.forwardRef<HTMLButtonElement, LinkButtonProps>(
  (
    { label, icon, iconFirst, onClick, className, ...props },
    ref,
  ): JSX.Element => {
    return (
      <button
        type='button'
        className={c(styles.linkButton, className)}
        onClick={onClick}
        aria-label={props['aria-label'] || label}
        ref={ref}
        {...props}
      >
        {icon && iconFirst && <Icon name={icon} />}
        {label}
        {icon && !iconFirst && <Icon name={icon} />}
      </button>
    );
  },
);
LinkButton.displayName = 'LinkButton';
