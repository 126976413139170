import React, { useEffect } from 'react';
import { ProductItem, ProductNode } from '../../types/Products';
import { BasicComponentProps } from '../../types';
import styles from './multi-product.module.scss';
import { SwatchOptionSelect } from '../deprecated/SwatchOptionSelect';
import { Badge, BadgeColors } from '../Badge';
import { importTranslations, useTranslation } from '../../utils/i18n';
import { LinkButton } from '../LinkButton';
import { useMultiProductOptions } from '../../utils/productUtils';

importTranslations('multiProductSelect', require.context('./languages/', true));

export type MultiProductProps = {
  node?: ProductNode;
  value?: ProductItem;
  specialSale?: boolean;
  onChange?: (item: ProductItem) => any;
  onSizeGuideClick?: React.MouseEventHandler; // if present will show size-guide
  onOptionSelected?: (
    option: string,
    type: string,
    item: ProductItem | undefined,
  ) => any;
} & BasicComponentProps;

export function MultiProductSelect({
  node,
  value,
  specialSale,
  onChange,
  onSizeGuideClick,
  onOptionSelected,
}: MultiProductProps) {
  const { t } = useTranslation('multiProductSelect');
  const [selection, sizeSelectProps, inseamSelectProps, ...multiProductProps] =
    useMultiProductOptions(node, value, onOptionSelected);
  const showColors = !!multiProductProps.length;

  useEffect(() => {
    onChange && onChange(selection);
  }, [selection]);

  const badgeNode = (() => {
    if (specialSale) {
      return (
        <Badge
          className={styles.multiProductBadge}
          label={t('badge.specialSale')}
        />
      );
    }
    if (selection?.coming_soon) {
      return (
        <Badge
          className={styles.multiProductBadge}
          label={t('badge.comingSoon')}
        />
      );
    }
    if (selection?.quantity === 0) {
      return (
        <Badge
          className={styles.multiProductBadge}
          label={t('badge.outOfStock')}
          color={BadgeColors.GRAY}
        />
      );
    }
    if (selection?.pro_exclusive) {
      return (
        <Badge
          className={styles.multiProductBadge}
          label={t('badge.whoopProExclusive')}
          color={BadgeColors.PRO}
        />
      );
    }
    if (selection?.on_sale) {
      return (
        <Badge className={styles.multiProductBadge} label={t('badge.onSale')} />
      );
    }
    if (selection?.new) {
      return (
        <Badge className={styles.multiProductBadge} label={t('badge.new')} />
      );
    }
  })();

  let onlyBlankLeftNode;
  if (
    selection?.quantity &&
    selection?.quantity > 0 &&
    selection?.quantity < 10
  ) {
    onlyBlankLeftNode = (
      <span className={styles.fewProductsLeft}>
        {showColors ? ' - ' : ''}
        {t('onlyBlankLeft', { quantity: selection?.quantity })}
      </span>
    );
  }

  return (
    <div className={styles.multiProduct}>
      {(showColors ||
        selection?.color?.label ||
        onlyBlankLeftNode ||
        badgeNode) && (
        <h2>
          {showColors && t('color')}
          <span className={styles.selectedOptionOuter}>
            {selection?.color?.label}
            {onlyBlankLeftNode}
          </span>
          {badgeNode}
        </h2>
      )}
      {multiProductProps?.map(({ label, subLabel, ...props }, i) => (
        <div key={i}>
          {label && (
            <h4 className={styles.selectedOptionInner}>
              {label}
              {subLabel && <span>: {subLabel}</span>}
            </h4>
          )}
          <div>
            <SwatchOptionSelect name={`multi-product-color-${i}`} {...props} />
          </div>
        </div>
      ))}
      {sizeSelectProps && (
        <>
          <h2>
            {t('size')}
            <span className={styles.selectedOptionOuter}>
              {selection?.size?.label}
            </span>
          </h2>
          <div className={styles.sizeSwatchContainer}>
            <SwatchOptionSelect
              name={`size`}
              className={styles.sizeSwatch}
              {...sizeSelectProps}
            />
            {onSizeGuideClick && (
              <LinkButton
                className={styles.sizeGuide}
                label={t('sizeGuide')}
                icon='arrow_right'
                onClick={onSizeGuideClick}
              />
            )}
          </div>
        </>
      )}
      {inseamSelectProps && (
        <>
          <h2>
            {t('inseam')}
            <span className={styles.selectedOptionOuter}>
              {selection?.inseam?.label}
            </span>
          </h2>
          <div>
            <SwatchOptionSelect name={`inseam`} {...inseamSelectProps} />
          </div>
        </>
      )}
    </div>
  );
}
