import React, { useState } from 'react';
import styles from './tooltip.module.scss';
import { classes } from '../../utils';
import { TooltipDirection, TooltipThemes } from '../../types/Tooltip';

export interface TooltipProps extends React.HTMLAttributes<HTMLDivElement> {
  content: string | JSX.Element | React.ReactNode;
  direction?: TooltipDirection;
  delay?: number;
  tooltipClassName?: string;
  inline?: boolean;
  verticalOffset?: number;
  horizontalOffset?: number;
  theme?: TooltipThemes;
}

export const Tooltip = ({
  content,
  direction = TooltipDirection.TOP,
  delay = 400,
  children,
  className,
  tooltipClassName,
  inline = false,
  verticalOffset = 40,
  horizontalOffset = 28,
  theme = TooltipThemes.DEFAULT,
  ...props
}: TooltipProps): JSX.Element => {
  let timeout: ReturnType<typeof setTimeout>;
  const [isActive, setIsActive] = useState(false);

  const getTooltipPosition = () => {
    switch (direction) {
      case TooltipDirection.TOP:
        return { top: `-${verticalOffset}px` };
      case TooltipDirection.BOTTOM:
        return { bottom: `-${verticalOffset}px` };
      case TooltipDirection.LEFT:
        return { right: `calc(100% + ${horizontalOffset}px` };
      case TooltipDirection.RIGHT:
        return { left: `calc(100% + ${horizontalOffset}px` };
    }
  };

  const showTooltip = () => {
    timeout = setTimeout(() => {
      setIsActive(true);
    }, delay);
  };

  const hideTooltip = () => {
    clearInterval(timeout);
    setIsActive(false);
  };

  return (
    <div
      className={classes(
        styles.wrapper,
        inline && styles.inline,
        theme !== TooltipThemes.DEFAULT && `theme-${theme}`,
        className,
      )}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      data-testid='tooltip-wrapper'
      {...props}
    >
      {children}
      {isActive && (
        <div
          className={classes(
            styles.tooltip,
            styles[direction],
            tooltipClassName,
          )}
          style={getTooltipPosition()}
          data-testid='tooltip-content'
        >
          {content}
        </div>
      )}
    </div>
  );
};
