import React from 'react';
import styles from './cartItems.module.scss';
import { c } from '../../../utils';
import CartUpsell from './cartUpsell';
import { CartAlert } from '../CartAlert/CartAlert';
import QuantitySelector from './quantitySelector';
import { Price } from '../../Price';

export interface CartItemsProps extends React.HTMLAttributes<HTMLDivElement> {
  image: string;
  title: string;
  price: string;
  strikedPrice?: string;
  description: string[];
  showQuantity: boolean;
  alert?: string;
  alertAction?: () => void;
  upsell?: string;
  upsellAction?: () => void;
  dropdownOptions?: string[];
  dropdownAction?: () => void;
  selectedDropdownOption?: string;
  noBottomBorder?: boolean;
}

export function CartItems({
  className,
  image,
  title,
  price,
  strikedPrice,
  description,
  showQuantity,
  alert,
  alertAction,
  upsell,
  upsellAction,
  dropdownOptions,
  dropdownAction,
  selectedDropdownOption,
  noBottomBorder = false,
  ...props
}: CartItemsProps) {
  return (
    <div
      className={c(
        styles.cartItemWrapper,
        !noBottomBorder && styles.bottomBorder,
        className,
      )}
      {...props}
    >
      <div className={styles.cartItem}>
        <img className={styles.cartImage} src={image} alt={title} />
        <div className={styles.cartItemDescriptionWrapper}>
          <div className={styles.cartDescriptionRow}>
            <div className={styles.cartItemLabel}>{title}</div>
            {strikedPrice ? (
              <Price
                className={styles.cartItemPrice}
                price={price}
                originalPrice={strikedPrice}
              ></Price>
            ) : (
              <Price price={price} className={styles.cartItemPrice}></Price>
            )}
          </div>
          <div className={styles.cartDescriptionRow}>
            <div className={styles.subLabel}>
              {description &&
                description.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })}
            </div>
            <div className={c(styles.subLabel, styles.quantitySelector)}>
              Qty:{' '}
              {showQuantity && dropdownOptions && dropdownAction ? (
                <QuantitySelector
                  options={dropdownOptions}
                  onOptionSelected={dropdownAction}
                  selectedOption={selectedDropdownOption}
                  className={styles.dropdown}
                ></QuantitySelector>
              ) : (
                1
              )}
            </div>
          </div>
          {upsell && (
            <CartUpsell
              className={styles.upsell}
              message={upsell}
              onClick={upsellAction}
            ></CartUpsell>
          )}
        </div>
      </div>
      {alert && (
        <CartAlert
          className={styles.alert}
          message={alert}
          learnMoreAction={alertAction}
        ></CartAlert>
      )}
    </div>
  );
}

export default CartItems;
