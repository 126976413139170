import React from 'react';
import PropTypes from 'prop-types';
import '../../index.module.scss';
import { Button } from '../Button';

export const Survey = ({
  buttonProps,
  onChange,
  size = 'medium',
  ...props
}) => {
  return (
    <div {...props}>
      {buttonProps.map(({ onClick, ...option }, index) => {
        return (
          <Button
            variant='survey'
            size={size}
            key={`survey-button-${index}`}
            onClick={() => {
              onChange && onChange(option.value);
              onClick && onClick();
            }}
            {...option}
          />
        );
      })}
    </div>
  );
};

Survey.propTypes = {
  buttonProps: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  onChange: PropTypes.func,
};
