import React, { ChangeEvent, useEffect, useState } from 'react';
import { c } from '../../../utils';
import { RadioInputProps } from '../../../types/RadioInputs';

import styles from './radio-input.module.scss';

export const RadioInput = ({
  className,
  name,
  onChange,
  options,
  useExternalState,
  allowNone = false,
  value,
  isShippingMethodRadioInput = false,
  ...props
}: RadioInputProps): JSX.Element | null => {
  const [selectedValue, setSelectedValue] = useState<
    string | number | undefined
  >(value);

  // set value when parent updates value
  useEffect(() => {
    if (value != null) {
      setSelectedValue(value);
    } else if (!allowNone) {
      setSelectedValue(options?.[0]?.value);
    }
  }, [value]);

  if (!options.length) return null;

  const changeValue = (value: string | number) => {
    if (value === selectedValue) return;
    onChange && onChange(value);
    setSelectedValue(value);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    changeValue(value);
  };

  const preventFutureClickEvents = (
    event: React.MouseEvent<HTMLInputElement>,
  ) => {
    event.stopPropagation();
  };

  const actualValue = useExternalState ? value : selectedValue;

  return (
    <div
      aria-labelledby={name}
      className={c(styles.radioInput, className)}
      role='radiogroup'
      {...props}
    >
      {options?.map(({ disabled, label, sublabel, price, value }, index) => {
        return (
          <div
            className={styles.option}
            key={`${name}-${value}`}
            onClick={() => changeValue(value)}
          >
            <input
              type='radio'
              disabled={disabled}
              id={`radio-input-${name}-${index}`}
              name={name}
              value={value}
              checked={value === actualValue}
              onChange={handleOnChange}
              onClick={preventFutureClickEvents}
              aria-label={label}
            />
            {isShippingMethodRadioInput ? (
              <label
                className={styles.shippingMethodRadioInputLabelContainer}
                htmlFor={`radio-input-${name}-${index}`}
              >
                <>
                  <div className={styles.label}>
                    <div>{label}</div>
                    <div>{price}</div>
                  </div>
                  <div className={styles.sublabel}>{sublabel}</div>
                </>
              </label>
            ) : (
              <label
                className={styles.labelContainer}
                htmlFor={`radio-input-${name}-${index}`}
              >
                {label}
              </label>
            )}
          </div>
        );
      })}
    </div>
  );
};
