import React from 'react';
import { importTranslations, useTranslation } from '../../utils/i18n';
import { c } from '../../utils';
import styles from './loading.module.scss';
import { LoadingSizeType } from '../../types/Loading';
import whoopLoading from '../../assets/whoop-loading.gif';
import { capitalize } from '../../../../utils';

importTranslations('loading', require.context('./languages/', true));

export interface LoadingProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: LoadingSizeType;
}

export function Loading({
  size = LoadingSizeType.MEDIUM,
  className,
  ...props
}: LoadingProps) {
  const { t } = useTranslation('loading');

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const loadingLogoStyle: string | undefined =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    styles[`loadingLogo${capitalize(size)}`];
  return (
    <img
      className={c(styles.loadingLogo, loadingLogoStyle, className)}
      src={whoopLoading}
      alt={t('loading')}
      {...props}
    />
  );
}
